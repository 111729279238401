/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-02-25
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Grid, Typography, TextField, Slider, Input } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

// Own components
import ColorPicker from 'components/configurator/editor/colorPicker/ColorPicker';

// Data models
import { Props } from './propTypes';

// Utils
import { hexToRgba } from 'utils/ColorHelper';
import { FontStyle } from 'jaybox/dist/utils/FontStyling';

// Styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: 'white',
      height: '100%',
      width: '100%',
    },
    content: {},
    textField: {
      backgroundColor: 'white',
      width: '100%',
      boxSizing: 'border-box',
    },
    typo: {
      fontWeight: 500,
    },
    input: {
      width: '100%',
    },
  }),
);

const StyleEdit: React.FC<Props> = (props) => {
  const { stylingOptions, setStyleKey, selectedElementUUID, setFontStyle, style } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const updateFontStyle = <K extends keyof FontStyle>(
    value: FontStyle[K],
    field: K,
    fontData: FontStyle | null,
  ) => {
    if (fontData) {
      const data: FontStyle = {
        ...fontData,
      };
      data[field] = value;
      setFontStyle(data, selectedElementUUID);
    } else {
      const data: FontStyle = {
        color: '',
        size: 20,
      };
      data[field] = value;
      setFontStyle(data, selectedElementUUID);
    }
  };

  const getColorOptions = () => {
    return (
      <Grid item container xs={12} className={classes.content}>
        {stylingOptions.map((color) => {
          switch (color.type) {
            case 'color' || 'pixel':
              return (
                <Grid
                  item
                  container
                  xs={12}
                  key={`color-${color.key}`}
                  style={{ padding: '1rem 0 ' }}
                >
                  <Grid item xs={8}>
                    <Typography variant="body1" color="textPrimary" className={classes.typo}>
                      {t(`color.${color.key}`)}
                    </Typography>
                  </Grid>
                  <Grid container item xs={4} justify="flex-end">
                    {color.type === 'color' ? (
                      <ColorPicker
                        initialColor={hexToRgba(color.hexColor)}
                        colorChangeHandler={(_rgba, hex) => {
                          setStyleKey(color.key, hex);
                        }}
                      />
                    ) : (
                      <TextField
                        type="number"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                          setStyleKey(color.key, event.target.value + 'px');
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              );
            case 'fontColor':
              return (
                <Grid
                  item
                  container
                  xs={12}
                  key={`fontColor-${selectedElementUUID}`}
                  style={{ padding: '1rem 0 ' }}
                >
                  <Grid item xs={8}>
                    <Typography variant="body1" color="textPrimary" className={classes.typo}>
                      {t(`color.fontColor`)}
                    </Typography>
                  </Grid>
                  <Grid container item xs={4} justify="flex-end">
                    <ColorPicker
                      initialColor={
                        color.fontStyle
                          ? hexToRgba(color.fontStyle.color)
                          : hexToRgba(style.primaryColor)
                      }
                      colorChangeHandler={(_rgba, hex) => {
                        updateFontStyle(hex, 'color', color.fontStyle);
                      }}
                    />
                  </Grid>
                </Grid>
              );
            case 'fontSize':
              return (
                <Grid
                  item
                  container
                  xs={12}
                  key={`fontSize-${selectedElementUUID}`}
                  style={{ padding: '1rem 0 ' }}
                >
                  <Grid item xs={5}>
                    <Typography variant="body1" color="textPrimary" className={classes.typo}>
                      {t(`color.fontSize`)}
                    </Typography>
                  </Grid>
                  <Grid container item xs={7} justify="flex-end">
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs>
                        <Slider
                          value={color.fontStyle ? color.fontStyle.size : 20}
                          onChange={(event: any, newValue: number | number[]) => {
                            updateFontStyle(
                              !Array.isArray(newValue) ? newValue : 20,
                              'size',
                              color.fontStyle,
                            );
                          }}
                          aria-labelledby="input-slider"
                        />
                      </Grid>
                      <Grid item>
                        <Input
                          className={classes.input}
                          value={color.fontStyle ? color.fontStyle.size : 20}
                          margin="dense"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const value =
                              event.target.value === '' ? 20 : Number(event.target.value);
                            updateFontStyle(value, 'size', color.fontStyle);
                          }}
                          inputProps={{
                            step: 10,
                            min: 0,
                            max: 100,
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            default:
              return null;
          }
        })}
      </Grid>
    );
  };

  return (
    <Grid container alignItems="center" className={classes.container}>
      {getColorOptions()}
    </Grid>
  );
};

export default StyleEdit;
