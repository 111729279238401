/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-01-03
 * @author Tim Lange <tl@systl.de>
 */

export enum DraggableType {
  Option = 'Option',
  Slider = 'Slider',
  Text = 'Text',
  Number = 'Number',
  Email = 'Email',
  Checkbox = 'Checkbox',
  TextArea = 'TextArea',
  Radio = 'Radio',
  Label = 'Label',
}

export interface DragItem {
  type: DraggableType;
  label: string;
}
