/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-02-26
 * @author Tim Lange <tl@systl.de>
 */

export enum CurrentNavigation {
  EDIT = 'EDIT',
  DEFAULT = 'DEFAULT',
}
