/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-02-26
 * @author Tim Lange <tl@systl.de>
 */

// Models
import { CurrentNavigation } from '.';

export enum NavigationActionType {
  SET_CURRENT_NAVIGATION = '@@navigation/SET_CURRENT_NAVIGATION',
  SET_MOBILE_MODE_ACTION = '@@navigation/SET_MOBILE_MODE_ACTION',
}

export interface SetCurrentNavigationAction {
  navigation: CurrentNavigation;
  type: NavigationActionType.SET_CURRENT_NAVIGATION;
}

export interface SetMobileModeAction {
  mobileMode: boolean;
  type: NavigationActionType.SET_MOBILE_MODE_ACTION;
}

export type NavigationAction = SetCurrentNavigationAction | SetMobileModeAction;
