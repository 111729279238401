/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-02-13
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Grid } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Own components
import JayboxTextfield from 'components/common/text-field';

// Data models
import { Props } from './propTypes';

// Action Creator
import { RootState, useAppDispatch } from 'store';
import { setLocalization, setUnsavedChanges } from 'store/configuration/configurationSlice';

// Styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      width: '100%',
    },
    content: {
      padding: '1rem 1rem',
      width: '100%',
      backgroundColor: '#D1D1D1',
    },
  }),
);

const TitleForm: React.FC<Props> = (props) => {
  const { selectedStoreElement } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const localization = useSelector((root: RootState) => root.configuration.localization);

  const updateLocalization = (key: string, value: string) => {
    if (selectedStoreElement && selectedStoreElement['localization']) {
      dispatch(setUnsavedChanges({ changes: true }));
      dispatch(
        setLocalization({
          key: selectedStoreElement['localization'],
          localization: value,
          namespace: 'de',
        }),
      );
    }
  };

  const getInputField = () => {
    return (
      <Grid item xs={12}>
        <JayboxTextfield
          jayboxVariant="medium"
          label={t('toolbar.question')}
          id={selectedStoreElement.uuid}
          multiline
          rows="3"
          onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
            updateLocalization('localization', event.target.value);
          }}
          value={localization.de[selectedStoreElement['localization']] || ''}
          type="text"
          variant="outlined"
          fullWidth={true}
        />
      </Grid>
    );
  };

  return (
    <Grid container alignItems="center" className={classes.container}>
      {getInputField()}
    </Grid>
  );
};

export default TitleForm;
