/**
 * Payment reducer slice
 *
 * @copyright ©2020 Emden Consulting GmbH
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Stripe as StripeJs } from '@stripe/stripe-js';
import Stripe from 'stripe';
import moment from 'moment';

// Data models
import { RequestStatus } from 'models/common';
import { AppThunk } from 'store';
import { Jaybox } from 'models/boxes';

// Config
import { BACKEND_URL } from 'config/env';

// Action Creator
import { updateJaybox } from 'store/boxes/boxesSlice';

export type PaymentState = {
  cancelSubscriptionStatus: RequestStatus;
  customerId: string;
  stripe: StripeJs | null;
};

export type UpdateCustomerIdPayload = {
  customerId: string;
};
export type UpdateMethodsPayload = {
  methods: Array<Stripe.PaymentMethod>;
};

export type UpdatePricesPayload = {
  prices: Array<Stripe.Price>;
};

export type UpdateStripeInstancePayload = {
  stripe: StripeJs;
};

export const initialState: PaymentState = {
  cancelSubscriptionStatus: RequestStatus.IDLE,
  customerId: '',
  stripe: null,
};

const paymentSlice = createSlice({
  name: '@@payment',
  initialState,
  reducers: {
    cancelSubscriptionFailed(state) {
      state.cancelSubscriptionStatus = RequestStatus.ERROR;
    },
    cancelSubscriptionStarted(state) {
      state.cancelSubscriptionStatus = RequestStatus.LOADING;
    },
    cancelSubscriptionSucceeded(state) {
      state.cancelSubscriptionStatus = RequestStatus.SUCCESS;
    },
    resetCancelSubscription(state) {
      state.cancelSubscriptionStatus = RequestStatus.IDLE;
    },
    updateCustomerId(state, action: PayloadAction<UpdateCustomerIdPayload>) {
      state.customerId = action.payload.customerId;
    },
    updateStripeInstance(state, action: PayloadAction<UpdateStripeInstancePayload>) {
      state.stripe = action.payload.stripe;
    },
  },
});

export const {
  cancelSubscriptionFailed,
  cancelSubscriptionStarted,
  cancelSubscriptionSucceeded,
  resetCancelSubscription,
  updateCustomerId,
  updateStripeInstance,
} = paymentSlice.actions;

export default paymentSlice.reducer;

export const cancelSubscription = (jaybox: Jaybox): AppThunk => async (dispatch, getState) => {
  try {
    const customerId = getState().user.profile?.paymentDetails?.customerId;
    const managedCustomerId = getState().user.managedProfile?.paymentDetails?.customerId;

    if (
      ((customerId && customerId !== '') || (managedCustomerId && managedCustomerId !== '')) &&
      jaybox.license.subscriptionId
    ) {
      dispatch(cancelSubscriptionStarted());
      const response = await fetch(
        `${BACKEND_URL}/jayboxApp/payment/customers/${managedCustomerId || customerId}/${
          jaybox.license.subscriptionId
        }`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
      );
      if (!response.ok) {
        dispatch(cancelSubscriptionFailed());
        setTimeout(() => {
          dispatch(resetCancelSubscription());
        }, 2000);
        return;
      }
      const subscription = (await response.json()) as Stripe.Subscription;
      const subscriptionEnd = moment.unix(subscription.current_period_end);

      const updatedBox: Jaybox = {
        ...jaybox,
        activeUntil: subscriptionEnd,
        license: {
          ...jaybox.license,
          active: false,
        },
      };
      dispatch(updateJaybox({ box: updatedBox }));

      dispatch(cancelSubscriptionSucceeded());
      setTimeout(() => {
        dispatch(resetCancelSubscription());
      }, 2000);
    } else if (jaybox.license.subscriptionId === '') {
      dispatch(cancelSubscriptionStarted());
      const updatedBox: Jaybox = {
        ...jaybox,
        activeUntil: moment(),
        license: {
          ...jaybox.license,
          active: false,
        },
      };
      dispatch(updateJaybox({ box: updatedBox }));
      dispatch(cancelSubscriptionSucceeded());
      setTimeout(() => {
        dispatch(resetCancelSubscription());
      }, 2000);
    }
  } catch (err) {
    dispatch(cancelSubscriptionFailed());
  }
};
