/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-02-13
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { namespacedAction } from 'redux-subspace';

// Presentational component
import OptionForm from './OptionForm';

// Action creator
import { setSelectElement } from 'jaybox/dist/store/stepdata/actions';

// Data models
import { StateProps, DispatchProps } from './propTypes';
import { ApplicationState, ApplicationAction } from 'models/store';
import {
  StepElementOptionStateEntity,
  StepElementSelectStateEntity,
} from 'jaybox/dist/models/stepdata';

const mapStateToProps: MapStateToProps<StateProps, {}, ApplicationState> = (state) => ({
  currentStepUUID: state.jaybox.stepData.currentStepUUID,
  selectedElement: state.configuration.selectedElement,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = (
  dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>,
) => ({
  setSelectElement: (
    element: StepElementSelectStateEntity,
    option: StepElementOptionStateEntity | null,
    stepUUID: string,
  ) => {
    dispatch(namespacedAction('@@jaybox')(setSelectElement(element, option, stepUUID)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OptionForm);
