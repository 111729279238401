/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-07-13
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { RouterState, connectRouter } from 'connected-react-router';
import { namespaced } from 'redux-subspace';
import { History, LocationState } from 'history';

// Stores
import authSlice from 'store/auth/authSlice';
import navigationReducer from 'store/navigation/reducer';
import configurationSlice from 'store/configuration/configurationSlice';
import { rootReducer as JBRootReducer } from 'jaybox/dist/store';
import { ApplicationState as JBAppState } from 'jaybox/dist/models/store';
import boxesSlice from 'store/boxes/boxesSlice';
import userReducer from 'store/user/userSlice';
import loginReducer from 'store/login/reducer';
import configSlice from 'store/config/configSlice';
import permissionReducer from 'store/permission/reducer';
import paymentReducer from 'store/payment/paymentSlice';

export const createRootReducer = (history: History<any>) => {
  // Reducers object
  const rootReducer = combineReducers({
    auth: authSlice,
    jaybox: namespaced('@@jaybox')(JBRootReducer) as Reducer<JBAppState>,
    login: loginReducer,
    configuration: configurationSlice,
    config: configSlice,
    navigation: navigationReducer,
    boxes: boxesSlice,
    user: userReducer,
    payment: paymentReducer,
    permission: permissionReducer,
    router: (connectRouter(history) as any) as Reducer<RouterState<LocationState>>,
  });

  return rootReducer;
};

export default createRootReducer;
