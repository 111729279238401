/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-03-12
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies

// Data models

export interface LoginData {
  jwtToken: string | null;
}

export enum LoginError {
  NONE = 'none',
  INVALID_TOKEN = 'auth/invalid-token',
  USER_DISABLED = 'auth/user-disabled',
}
