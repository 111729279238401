/**
 * Storage action creators
 *
 * @copyright ©2019 Emden Consulting GmbH
 * @created 2019-12-11
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import * as firebase from 'firebase/app';
import 'firebase/storage';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { namespacedAction } from 'redux-subspace';

// Data models
import { CustomTheme } from 'jaybox/dist/models/Theme';
import { RequestStatus } from 'models/common';
import { initialState as configuratorInitialState } from '../configuration/configurationSlice';

// Action Creator
import { setPreviewUrl } from 'jaybox/dist/store/stepdata/actions';
import {
  setXML,
  setStyle,
  setLocalizationFile,
  setLocalization,
} from 'store/configuration/configurationSlice';

// Utils
import { createAppThunk } from 'utils/appActions';
import { makeRequest } from 'utils/requestHandler';
import { convertStateToXML } from 'utils/XMLParser';

const sliceName = '@@config';

export interface ConfigState {
  requestState: RequestStatus;
  uploadState: RequestStatus;
  showCurrentJaybox: boolean;
  configurationFilesLoaded: boolean;
}

export interface SetShowCurrentJayboxPayload {
  showCurrentJaybox: boolean;
}

export interface SetConfigurationLoadedPayload {
  configurationFilesLoaded: boolean;
}

export const initialState: ConfigState = {
  requestState: RequestStatus.IDLE,
  uploadState: RequestStatus.IDLE,
  showCurrentJaybox: false,
  configurationFilesLoaded: false,
};

export const saveConfiguration = createAppThunk<void, { boxId: string }>(
  sliceName + '/saveConfiguration',
  async ({ boxId }, { getState, rejectWithValue, dispatch }) => {
    try {
      const steps = getState().jaybox.stepData.steps;
      const meta = getState().jaybox.stepData.meta;
      const xml = convertStateToXML(steps, meta);
      const style = getState().configuration.style;
      const localization = getState().configuration.localization;

      await Promise.all([
        dispatch(uploadFile({ url: `/${boxId}/config.xml`, fileContent: xml })),
        dispatch(uploadFile({ url: `/${boxId}/styling.json`, fileContent: JSON.stringify(style) })),
        dispatch(
          uploadFile({
            url: `/${boxId}/localization.json`,
            fileContent: JSON.stringify(localization),
          }),
        ),
      ]);
    } catch (error) {
      console.log(error);
      return rejectWithValue({ errorMessage: error });
    }
  },
);

export const savePreview = createAppThunk<
  void,
  { boxId: string; stepUUID: string; base64: string }
>(
  sliceName + '/savePreview',
  async ({ boxId, stepUUID, base64 }, { getState, rejectWithValue, dispatch }) => {
    const url = `/${boxId}/${stepUUID}_preview.png`;
    try {
      const storage = firebase.storage();

      const storageRef = storage.ref();
      let userId = '';
      const workspaceId = getState().permission.currentWorkspaceId;
      if (workspaceId) {
        userId = workspaceId;
      } else {
        const currentUser = firebase.auth().currentUser;
        if (currentUser) {
          userId = currentUser.uid;
        }
      }
      if (userId) {
        const fileRef = storageRef.child(`${userId}${url}`);

        await fileRef.putString(base64, 'data_url');
        const downloadURL = await fileRef.getDownloadURL().catch((error) => {});
        dispatch(namespacedAction('@@jaybox')(setPreviewUrl(stepUUID, downloadURL)));

        dispatch(saveConfiguration({ boxId: boxId }));
      } else {
        return rejectWithValue({ errorMessage: 'User Id not found' });
      }
    } catch (error) {
      return rejectWithValue({ errorMessage: error });
    }
  },
);

export const loadConfiguration = createAppThunk<void, { userId: string; boxId: string }>(
  sliceName + '/loadConfiguration',
  async ({ userId, boxId }, { getState, rejectWithValue, dispatch }) => {
    try {
      // Get a reference to the storage service, which is used to create references in your storage bucket
      const storage = firebase.storage();

      const storageRef = storage.ref();

      const configURL = await storageRef
        .child(`/${userId}/${boxId}/config.xml`)
        .getDownloadURL()
        .catch((error) => {
          console.log(error);
        });
      if (configURL) {
        const xml = await makeRequest('GET', configURL);
        await dispatch(setXML({ xml: xml }));
      } else {
        await dispatch(setXML({ xml: configuratorInitialState.xml }));
      }

      const styleURL = await storageRef
        .child(`/${userId}/${boxId}/styling.json`)
        .getDownloadURL()
        .catch((error) => {
          console.log(error);
        });

      if (styleURL) {
        const styling = await makeRequest('GET', styleURL);
        const loadedStyle = JSON.parse(styling) as CustomTheme;
        await dispatch(setStyle({ theme: { ...configuratorInitialState.style, ...loadedStyle } }));
      } else {
        await dispatch(setStyle({ theme: configuratorInitialState.style }));
      }

      const localizationURL = await storageRef
        .child(`/${userId}/${boxId}/localization.json`)
        .getDownloadURL()
        .catch((error) => {
          console.log(error);
        });
      if (localizationURL !== undefined) {
        const language = await makeRequest('GET', localizationURL);
        await dispatch(setLocalizationFile({ localization: JSON.parse(language) }));
      } else {
        // we have to set a default language, because otherwise it is waiting for language file
        await dispatch(
          setLocalizationFile({ localization: configuratorInitialState.localization }),
        );
      }

      dispatch(setConfigFilesLoaded({ configurationFilesLoaded: true }));
    } catch (error) {
      console.log(error);
      return rejectWithValue({ errorMessage: error });
    }
  },
);

export const uploadFile = createAppThunk<void, { url: string; fileContent: string }>(
  sliceName + '/uploadFile',
  async ({ url, fileContent }, { getState, rejectWithValue, dispatch }) => {
    try {
      const storage = firebase.storage();

      const storageRef = storage.ref();
      let userId = '';
      const workspaceId = getState().permission.currentWorkspaceId;
      if (workspaceId) {
        userId = workspaceId;
      } else {
        const currentUser = firebase.auth().currentUser;
        if (currentUser) {
          userId = currentUser.uid;
        }
      }

      if (userId) {
        // Create a reference to file, e.g. 'uid/config.xml'
        const fileRef = storageRef.child(`${userId}${url}`);

        await fileRef.putString(fileContent);
      } else {
        return rejectWithValue({ errorMessage: 'User Id not found' });
      }
    } catch (error) {
      console.log(error);
      return rejectWithValue({ errorMessage: error });
    }
  },
);

export const cleanUp = createAppThunk(sliceName + '/cleanUp', async (_, { dispatch, getState }) => {
  try {
    await dispatch(init());
  } catch (err) {}
});

const configSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(savePreview.pending, (state, _) => {
      state.uploadState = RequestStatus.LOADING;
    });
    builder.addCase(savePreview.fulfilled, (state, _) => {
      state.uploadState = RequestStatus.IDLE;
    });
    builder.addCase(savePreview.rejected, (state, action) => {
      state.uploadState = RequestStatus.ERROR;
    });

    builder.addCase(loadConfiguration.pending, (state, _) => {
      state.requestState = RequestStatus.LOADING;
    });
    builder.addCase(loadConfiguration.fulfilled, (state, _) => {
      state.requestState = RequestStatus.IDLE;
    });
    builder.addCase(loadConfiguration.rejected, (state, action) => {
      state.requestState = RequestStatus.ERROR;
    });
  },
  initialState,
  name: sliceName,
  reducers: {
    init: () => initialState,
    setAvailableJayboxes(state, action: PayloadAction<SetShowCurrentJayboxPayload>) {
      state.showCurrentJaybox = action.payload.showCurrentJaybox;
    },
    setConfigFilesLoaded(state, action: PayloadAction<SetConfigurationLoadedPayload>) {
      state.configurationFilesLoaded = action.payload.configurationFilesLoaded;
    },
  },
});

export const { init, setAvailableJayboxes, setConfigFilesLoaded } = configSlice.actions;

export default configSlice.reducer;
