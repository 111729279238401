/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-04-16
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Grid } from '@material-ui/core';
import * as React from 'react';
import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import uuidv1 from 'uuid/v1';

// Presentational

// Own Components
import JayboxOverlay from 'components/common/overlay';
import JayboxTextField from 'components/common/text-field';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'models/store';
import { JayboxUser } from 'models/user';
import { Jaybox } from 'models/boxes';
import moment from 'moment';

// Loader
import LoadingSpinner from 'components/common/loading-spinner/LoadingSpinner';

// Constants
import { BOX_DETAIL } from 'config/routes';

// Action Creator
import { createNewJaybox, setCurrentJaybox } from 'store/boxes/boxesSlice';
import { RequestStatus } from 'jaybox/dist/models/common';

const CreateOverlay: FC = () => {
  const dispatch = useDispatch();
  const user = useSelector<ApplicationState, JayboxUser | null>((state) => state.user.profile);
  const boxSaveStatus = useSelector<ApplicationState, RequestStatus | null>(
    (state) => state.boxes.updateJayboxState,
  );
  const [newJayboxName, setNewJayboxName] = useState<string>('');
  const [newJaybox, setNewJaybox] = useState<Jaybox | null>(null);
  const history = useHistory();
  const { t } = useTranslation();

  React.useEffect(() => {
    if (boxSaveStatus === RequestStatus.IDLE && newJaybox) {
      dispatch(setCurrentJaybox({ jaybox: newJaybox }));
      history.push(BOX_DETAIL.replace(':id', newJaybox.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boxSaveStatus]);

  const handleSave = () => {
    if (newJayboxName === '') {
      return;
    }
    saveNewJaybox();
  };

  const onInput = (value: string) => {
    setNewJayboxName(value);
  };

  const saveNewJaybox = () => {
    const newUUID = uuidv1();
    const newJaybox: Jaybox = {
      id: newUUID,
      name: newJayboxName,
      rootPath: `${user ? user.uid : ''}/${newUUID}`,
      unbuildChanges: false,
      lastSaved: moment(),
      activeUntil: null,
      metaData: {
        customerEmail: '',
        customerId: '',
        customerTemplate: 'd-095d4fc6cae04762aa8e36d94e9ce4cb',
        layout: 'vertical',
      },
      buildProgress: { done: false, progress: 0, jobId: '', running: false },
      buildOnce: false,
      license: {
        active: false,
        assignedAt: moment(),
        createdAt: moment(),
        subscriptionId: '',
        updatedAt: moment(),
      },
    };

    dispatch(createNewJaybox({ box: newJaybox }));
    setNewJaybox(newJaybox);
  };

  return (
    <JayboxOverlay
      open={true}
      cancelAction={() => {
        history.goBack();
      }}
      confirmAction={handleSave}
      title={t('createOverlay.title')}
      subTitle={t('createOverlay.subTitle')}
      confirmButtonText={t('createOverlay.create')}
      confirmButtonDisabled={newJayboxName === '' || boxSaveStatus === RequestStatus.LOADING}
    >
      <Grid container>
        <Grid item xs={12}>
          {boxSaveStatus === RequestStatus.LOADING ? (
            <Grid item container xs={12} justify="center">
              <LoadingSpinner />
            </Grid>
          ) : (
            <JayboxTextField
              autoFocus
              required={true}
              error={false}
              onKeyPress={(ev: React.KeyboardEvent<HTMLInputElement>) => {
                if (ev.key === 'Enter') {
                  ev.preventDefault();
                  handleSave();
                }
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                onInput(event.target.value);
              }}
              value={newJayboxName}
              label={t('createOverlay.name')}
              type="text"
              variant="outlined"
            />
          )}
        </Grid>
      </Grid>
    </JayboxOverlay>
  );
};

export default CreateOverlay;
