/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-07-21
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Grid, InputLabel, Typography, Checkbox } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Own components
import JayboxTextfield from 'components/common/text-field';

// Data models
import { Props } from './propTypes';
import { StepElementLabelStateEntity } from 'jaybox/dist/models/stepdata';

// Action Creator
import { RootState, useAppDispatch } from 'store';
import { setLocalization, setUnsavedChanges } from 'store/configuration/configurationSlice';

// Styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      width: '100%',
    },
    content: {
      padding: '1rem 1rem',
      width: '100%',
      backgroundColor: '#D1D1D1',
    },
    itemWrapper: {
      '&:not(:first-child)': {
        paddingTop: theme.spacing(7),
      },
    },
    titleLabel: {
      color: '#424242',
      padding: '1rem 0',
      fontSize: '1.2rem',
    },
  }),
);

type AttributeOption = {
  key: keyof StepElementLabelStateEntity;
  localization: string;
  value: null | number | string | boolean;
  type: 'text' | 'boolean';
  multiline: boolean;
  helperText: string;
};

const SliderForm: React.FC<Props> = (props) => {
  const { selectedStoreElement, currentStepUUID, setLabelElement } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const localization = useSelector((root: RootState) => root.configuration.localization);

  const updateLabel = <K extends keyof StepElementLabelStateEntity>(
    key: K,
    value: StepElementLabelStateEntity[K],
  ) => {
    if (currentStepUUID) {
      const update = { ...selectedStoreElement };
      update[key] = value;
      dispatch(setUnsavedChanges({ changes: true }));
      setLabelElement(update, currentStepUUID);
    }
  };

  const updateLocalization = (
    key: 'bodyLocalization' | 'titleLocalization' | 'buttonLocalization',
    value: string,
  ) => {
    if (selectedStoreElement && selectedStoreElement[key]) {
      dispatch(setUnsavedChanges({ changes: true }));
      dispatch(
        setLocalization({
          key: selectedStoreElement['localization'],
          localization: value,
          namespace: 'de',
        }),
      );
    }
  };

  const getLabel = (attribute: AttributeOption) => {
    return (
      <Grid item xs={12}>
        <InputLabel htmlFor={attribute.key}>
          <Typography className={classes.titleLabel} color="textPrimary" variant="h6">
            {attribute.localization}
          </Typography>
        </InputLabel>
      </Grid>
    );
  };

  const getInputField = (attribute: AttributeOption) => {
    switch (attribute.type) {
      case 'boolean':
        return (
          <Grid container key={attribute.key}>
            {getLabel(attribute)}
            <Grid item xs={3}>
              <Checkbox
                style={{ padding: 0 }}
                checked={typeof attribute.value === 'boolean' ? attribute.value : false}
                onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                  updateLabel('disableOverlay', event.target.checked);
                }}
                value="required"
                color="primary"
              />
            </Grid>
            <Grid item container xs={9} alignItems="center">
              <Typography color="textPrimary" variant="body2">
                {attribute.helperText}
              </Typography>
            </Grid>
          </Grid>
        );
      case 'text':
        return (
          <Grid container key={attribute.key} className={classes.itemWrapper}>
            <Grid item xs={12}>
              <JayboxTextfield
                jayboxVariant="medium"
                label={attribute.localization}
                id={attribute.key}
                multiline={attribute.multiline}
                rows="3"
                onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                  if (
                    attribute.key === 'bodyLocalization' ||
                    attribute.key === 'titleLocalization' ||
                    attribute.key === 'buttonLocalization'
                  ) {
                    updateLocalization(attribute.key, event.target.value);
                  } else {
                    updateLabel(attribute.key, Boolean(event.target.value));
                  }
                }}
                value={attribute.value || ''}
                type={attribute.type}
                variant="outlined"
                fullWidth={true}
              />
            </Grid>
          </Grid>
        );

      default:
        return <div />;
    }
  };

  const attributes: AttributeOption[] = [
    {
      key: 'titleLocalization',
      localization: t('toolbar.titleLocalization'),
      value: localization.de[selectedStoreElement['titleLocalization']] || '',
      type: 'text',
      multiline: true,
      helperText: '',
    },
    {
      key: 'bodyLocalization',
      localization: t('toolbar.bodyLocalization'),
      value: localization.de[selectedStoreElement['bodyLocalization']] || '',
      type: 'text',
      multiline: true,
      helperText: '',
    },
    {
      key: 'buttonLocalization',
      localization: t('toolbar.buttonLocalization'),
      value: localization.de[selectedStoreElement['buttonLocalization']] || '',
      type: 'text',
      multiline: false,
      helperText: '',
    },
    {
      key: 'disableOverlay',
      localization: t('toolbar.disableOverlay'),
      value: selectedStoreElement.disableOverlay,
      type: 'boolean',
      multiline: false,
      helperText: t('toolbar.deactivateOverlayHelper'),
    },
  ];

  return (
    <Grid container alignItems="center" className={classes.container}>
      {attributes.map((attribute) => getInputField(attribute))}
    </Grid>
  );
};

export default SliderForm;
