/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-02-11
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import * as React from 'react';
import { Theme, Box } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

// Assets
import { ReactComponent as CreateIcon } from 'assets/img/jaybox_pencil.svg';

// Action Creator
import { setCurrentlySelectedElement } from 'store/configuration/configurationSlice';
import { useAppDispatch } from 'store';

// Data models
import { Props } from './propTypes';
import { CurrentNavigation } from 'models/navigation';

const useStyles = makeStyles<Theme, Props>((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
    },
    full: {
      width: '100%',
      height: '100%',
    },
    selected: {
      opacity: '0.8',
      //backgroundColor: 'lightgrey',
      border: `solid ${theme.palette.secondary.main} 2px`,
    },
    createIcon: {
      width: theme.spacing(6.5),
      height: theme.spacing(6.5),
      padding: theme.spacing(1.5),
      position: 'absolute',
      right: 0,
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.main,
    },
  }),
);

export const ClickArea: React.FC<Props> = (props) => {
  const { selectedElement, ownElement, setCurrentNavigation } = props;
  const classes = useStyles(props);
  const [selected, setSelected] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (selectedElement && selectedElement.primary.uuid === ownElement.primary.uuid) {
      setCurrentNavigation(CurrentNavigation.EDIT);
      setSelected(true);
    } else if (selectedElement) {
      // setCurrentNavigation(CurrentNavigation.DEFAULT);
      setSelected(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownElement.primary.uuid, selectedElement]);

  return (
    <Box
      className={clsx(classes.container, selected && classes.selected)}
      onClick={() => {
        dispatch(setCurrentlySelectedElement({ element: ownElement }));
      }}
    >
      {props.children}
      {selected ? <CreateIcon className={classes.createIcon} /> : null}
    </Box>
  );
};

export default ClickArea;
