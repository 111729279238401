/**
 * Common data models
 *
 * @copyright 2020 Emden Consulting GmbH
 * @created 2019-12-13
 * @author Tim Lange <tl@systl.de>
 */

import { FontStyle } from 'jaybox/dist/utils/FontStyling';

// Third-party dependencies

export enum RequestStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  ERROR = 'error',
  SUCCESS = 'success',
}

export interface RequestError {
  errorMessage: string;
}

export enum Language {
  EN = 'en',
  DE = 'de',
}

export interface StyleOption {
  key: string;
  hexColor: string;
  type: 'color' | 'pixel';
}

export interface FontStyleOption {
  type: 'fontColor' | 'fontSize';
  fontStyle: FontStyle | null;
}

export interface FooterEntry {
  id: string;
  label: string;
  target: string;
}

export interface NavEntry {
  path: string;
  name: string;
}
