/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-02-13
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Grid, Checkbox, InputLabel, Typography, Select, MenuItem } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Own components
import JayboxTextfield from 'components/common/text-field';

// Data models
import { Props } from './propTypes';
import {
  StepElementFormInputTextStateEntity,
  StepElementFormInputNumberStateEntity,
  StepElementFormInputEmailStateEntity,
  StepElementFormTextAreaStateEntity,
  StepElementFormInputCheckboxStateEntity,
  StepElementFormInputStateEntity,
  StepStateEntity,
} from 'jaybox/dist/models/stepdata';
import { HTMLType } from 'jaybox/dist/models/HTMLElements';

// Utils
import { getStepByUUID } from 'jaybox/dist/utils/ConditionalChecker';

// Action Creator
import { RootState, useAppDispatch } from 'store';
import { setLocalization, setUnsavedChanges } from 'store/configuration/configurationSlice';

// Styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      width: '100%',
    },
    content: {
      padding: '1rem 1rem',
      width: '100%',
      backgroundColor: '#D1D1D1',
    },
    itemWrapper: {
      '&:not(:first-child)': {
        paddingTop: theme.spacing(7),
      },
    },
    select: {
      width: '100%',
    },
    titleLabel: {
      color: '#424242',
      padding: '1rem 0',
    },
  }),
);

type AttributeOption = {
  key: 'localization' | 'type' | keyof StepElementFormInputStateEntity;
  localization: string;
  value: null | number | string | boolean;
  type: 'text' | 'number' | 'boolean' | 'select';
  multiline: boolean;
  helperText: string;
};

const FormInputForm: React.FC<Props> = (props) => {
  const {
    selectedStoreElement,
    currentStepUUID,
    selectedElement,
    setFormInputElement,
    steps,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = React.useState<StepStateEntity | null>(null);
  const dispatch = useAppDispatch();
  const localization = useSelector((root: RootState) => root.configuration.localization);

  React.useEffect(() => {
    const step = getStepByUUID(currentStepUUID, steps);
    setCurrentStep(step);
  }, [currentStepUUID, steps]);

  const updateForm = <K extends keyof StepElementFormInputStateEntity>(
    key: K,
    value:
      | StepElementFormInputTextStateEntity[K]
      | StepElementFormInputNumberStateEntity[K]
      | StepElementFormInputCheckboxStateEntity[K]
      | StepElementFormInputEmailStateEntity[K]
      | StepElementFormTextAreaStateEntity[K],
  ) => {
    if (
      selectedElement &&
      selectedElement.secondary &&
      selectedElement.secondary.type === HTMLType.FORM &&
      currentStepUUID
    ) {
      const update = { ...selectedStoreElement };

      if (update[key] !== undefined) {
        update[key] = value;
      }
      dispatch(setUnsavedChanges({ changes: true }));
      setFormInputElement(selectedElement.secondary, update, currentStepUUID);
    }
  };

  const updateLocalization = (value: string) => {
    if (selectedStoreElement && selectedStoreElement['localization']) {
      dispatch(setUnsavedChanges({ changes: true }));
      dispatch(
        setLocalization({
          key: selectedStoreElement['localization'],
          localization: value,
          namespace: 'de',
        }),
      );
    }
  };

  const updateObject = (type: HTMLType) => {
    if (
      type === HTMLType.TEXT ||
      type === HTMLType.TEXTAREA ||
      type === HTMLType.CHECKBOX ||
      type === HTMLType.NUMBER ||
      type === HTMLType.EMAIL
    ) {
      const update = { ...selectedStoreElement };

      if (
        selectedElement &&
        selectedElement.secondary &&
        selectedElement.secondary.type === HTMLType.FORM &&
        currentStepUUID
      ) {
        update['type'] = type;

        if (type === HTMLType.NUMBER) {
          update['value'] = null;
        } else if (type === HTMLType.CHECKBOX) {
          update['value'] = false;
        } else {
          update['value'] = '';
        }

        setFormInputElement(selectedElement.secondary, update, currentStepUUID);
      }
    }
  };

  const getAttributeComponent = (attribute: AttributeOption) => {
    switch (attribute.type) {
      case 'boolean':
        return (
          <Grid container>
            {getLabel(attribute)}
            <Grid item xs={3}>
              <Checkbox
                style={{ padding: 0 }}
                checked={typeof attribute.value === 'boolean' ? attribute.value : false}
                onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                  updateForm('required', event.target.checked);
                }}
                value="required"
                color="primary"
              />
            </Grid>
            <Grid item container xs={9} alignItems="center">
              <Typography color="textPrimary" variant="body2">
                {attribute.helperText}
              </Typography>
            </Grid>
          </Grid>
        );
      case 'select':
        return (
          <Grid container>
            {getLabel(attribute)}
            <Select
              id="type-select"
              value={attribute.value}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                updateObject(event.target.value as HTMLType);
              }}
              className={classes.select}
            >
              <MenuItem value={HTMLType.TEXT}>{t('toolbar.text')}</MenuItem>
              <MenuItem value={HTMLType.TEXTAREA}>{t('toolbar.textArea')}</MenuItem>
              <MenuItem value={HTMLType.CHECKBOX}>{t('toolbar.checkbox')}</MenuItem>
              <MenuItem value={HTMLType.EMAIL}>{t('toolbar.email')}</MenuItem>
              <MenuItem value={HTMLType.NUMBER}>{t('toolbar.number')}</MenuItem>
            </Select>
          </Grid>
        );
      case 'number':
      case 'text':
        return (
          <JayboxTextfield
            jayboxVariant="medium"
            label={attribute.localization}
            id={attribute.key}
            multiline={attribute.multiline}
            rows="3"
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              if (attribute.key === 'localization') {
                updateLocalization(event.target.value);
              } else {
                if (attribute.key !== 'type') {
                  updateForm(attribute.key, event.target.checked);
                }
              }
            }}
            value={attribute.value || ''}
            type={attribute.type}
            variant="outlined"
            fullWidth={true}
          />
        );

      default:
        return <div />;
    }
  };

  const getLabel = (attribute: AttributeOption) => {
    return (
      <Grid item xs={12}>
        <InputLabel
          htmlFor={attribute.key}
          classes={{
            root: classes.titleLabel,
          }}
        >
          <Typography color="textPrimary" variant="h6">
            {attribute.localization}
          </Typography>
        </InputLabel>
      </Grid>
    );
  };

  const getInputField = (attribute: AttributeOption) => {
    return (
      <Grid container key={attribute.key} className={classes.itemWrapper}>
        <Grid item xs={12}>
          {getAttributeComponent(attribute)}
        </Grid>
      </Grid>
    );
  };

  const attributes: AttributeOption[] = [
    {
      key: 'localization',
      localization: t('toolbar.localization'),
      value: localization.de[selectedStoreElement['localization']] || '',
      type: 'text',
      multiline: true,
      helperText: '',
    },
  ];

  if (
    currentStep?.stepType !== 'send' ||
    !(
      selectedElement?.primary.type === HTMLType.CHECKBOX &&
      selectedElement.primary.order &&
      (selectedElement.primary.order === 1 || selectedElement.primary.order === 2)
    )
  ) {
    attributes.push({
      key: 'required',
      localization: t('toolbar.required'),
      value: selectedStoreElement.required,
      type: 'boolean',
      multiline: false,
      helperText: t('toolbar.requiredHelper'),
    });
    attributes.push({
      key: 'type',
      localization: t('toolbar.type'),
      value: selectedStoreElement.type,
      type: 'select',
      multiline: false,
      helperText: '',
    });
  }

  return (
    <Grid container alignItems="center" className={classes.container}>
      {attributes.map((attribute) => getInputField(attribute))}
    </Grid>
  );
};

export default FormInputForm;
