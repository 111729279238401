/**
 * ElementsBar container
 *
 * @copyright 2020 Emden Consulting GmbH
 * @created 2019-12-13
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { connect, MapStateToProps } from 'react-redux';

// Presentational component
import ElementsBar from './ElementsBar';

// Data models
import { StateProps } from './propTypes';
import { ApplicationState } from 'models/store';

const mapStateToProps: MapStateToProps<StateProps, {}, ApplicationState> = (state) => ({
  selectedElement: state.configuration.selectedElement,
});

export default connect(mapStateToProps)(ElementsBar);
