/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-03-12
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { FC } from 'react';
import { useLocation, RouteComponentProps } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { Redirect, Route, Switch } from 'react-router-dom';
import startsWith from 'lodash/startsWith';
import { useSelector } from 'react-redux';

// Own Components
import Sidebar from 'components/dashboard/sidebar/Sidebar';
import Footer from 'components/footer';
import Overview from 'components/dashboard/overview/Overview';
import CreateOverlay from 'components/dashboard/create/CreateOverlay';
import BoxDetail from 'components/dashboard/detail/BoxDetail';

// Data Models
import { Jaybox } from 'models/boxes';
import { ApplicationState } from 'models/store';

// Constants
import { DASHBOARD_PATH, CREATE_NEW_PATH, BOX_DETAIL } from 'config/routes';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    minHeight: '100%',
  },
  contentWrapper: {
    display: 'flex',
    flex: 1,
    height: '100%',
    minHeight: '100vh',
    overflowY: 'auto',
  },
  content: {
    [theme.breakpoints.up('sm')]: {
      minHeight: `calc(100% - ${theme.spacing(17.5)}px)`,
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: `calc(100% - ${theme.spacing(12)}px)`,
    },
  },
  footer: {
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(17.5),
    },
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(12),
    },
  },
}));

const DashboardView: FC = () => {
  const availableBoxes = useSelector<ApplicationState, Jaybox[]>(
    (state) => state.boxes.availableJayboxes,
  );
  const classes = useStyles();
  const { pathname } = useLocation();

  const createNewOverlay = () => {
    if (startsWith(pathname, CREATE_NEW_PATH)) {
      return <CreateOverlay />;
    } else {
      return null;
    }
  };

  return (
    <Grid container className={classes.root} alignItems="stretch" wrap="nowrap">
      <CssBaseline />
      <Grid item style={{ width: '32.5rem', minWidth: '32.5rem' }}>
        <Sidebar />
      </Grid>
      <Grid item xs>
        {createNewOverlay()}
        <Grid className={classes.contentWrapper} item container xs={12}>
          <Grid item xs={12} className={classes.content}>
            <Switch>
              <Route path={CREATE_NEW_PATH} exact />
              <Route
                path={BOX_DETAIL}
                exact
                render={(props: RouteComponentProps<{ id: string }>) => {
                  const id = props.match.params.id;
                  const box = availableBoxes.find((box) => box.id === id);
                  if (box) {
                    return <BoxDetail jayboxId={id} />;
                  } else {
                    return <Redirect to={DASHBOARD_PATH} />;
                  }
                }}
              />
              <Route path={DASHBOARD_PATH} exact component={Overview} />
              <Route>
                <Redirect to={DASHBOARD_PATH} />
              </Route>
            </Switch>
          </Grid>
          <Grid className={classes.footer} item xs={12}>
            <Footer />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DashboardView;
