/**
 * App entry point for general data injection as with Providers for stores or themes.
 *
 * @copyright 2020 Emden Consulting GmbH
 * @created 2019-12-12
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { MuiThemeProvider } from '@material-ui/core';
import React, { FC, Suspense } from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { DndProvider } from 'react-dnd';
import MultiBackend from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';
import { ConnectedRouter } from 'connected-react-router';

// Store and connected history
import { store, history } from 'store';

// Theme
import blueTheme from 'themes/jb-portal-blue';
import RootView from 'views/root/RootView';

// Loader
import LoadingSpinner from 'components/common/loading-spinner/LoadingSpinner';

// Utils

class ErrorBoundary extends React.Component<{ fallback: React.ReactElement }, { hasError: any }> {
  constructor(props: { fallback: React.ReactElement }) {
    super(props);
    this.state = { hasError: false };
  }

  static defaultProps = {
    fallback: <h1>Something went wrong.</h1>,
  };

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }

    return this.props.children;
  }
}

const App: FC = () => {
  return (
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <Suspense fallback={<LoadingSpinner />}>
        <StoreProvider store={store}>
          <ConnectedRouter history={history}>
            <MuiThemeProvider theme={blueTheme}>
              <DndProvider backend={MultiBackend} options={HTML5toTouch}>
                <RootView />
              </DndProvider>
            </MuiThemeProvider>
          </ConnectedRouter>
        </StoreProvider>
      </Suspense>
    </ErrorBoundary>
  );
};

export default App;
