/**
 * Helper to make http requests
 *
 * @copyright ©2019 Emden Consulting GmbH
 * @created 2019-12-12
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 * @author Tim Lange <tl@systl.de>
 */

// Third party
import fetch from 'isomorphic-fetch';

// environment
import { PIPELINE_ENDPOINT, PIPELINE_AUTHORIZATION, BACKEND_URL } from 'config/env';

// Models
import { Pipeline } from 'models/pipeline';
import { FTPUpload } from 'models/FTPUpload';
import { AuthResponsePayload } from 'models/firebase/functions';

/**
 * Execute the Bitbucket Pipeline
 * @param data
 */
export const triggerPipelineBuild = (data: Pipeline): Promise<JSON> => {
  return fetch(PIPELINE_ENDPOINT, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: PIPELINE_AUTHORIZATION,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

/**
 * Upload data to an ftp
 * @param data
 */
export const uploadJSONToFTP = (data: FTPUpload): Promise<JSON> => {
  return fetch(BACKEND_URL + '/jayboxApp/ftp', {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getSessionToken = (jwtToken: string): Promise<string | null> => {
  return fetch(BACKEND_URL + `/jayboxApp/auth/${jwtToken}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return null;
      }
    })
    .then((res: AuthResponsePayload | null) => {
      if (res) {
        return res.payload;
      } else {
        return null;
      }
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

/**
 * Used mainly to download files
 * @param method
 * @param url
 */
export const makeRequest = (method = 'GET', url: any): Promise<string> => {
  return new Promise(function(resolve, reject) {
    const xhr = new XMLHttpRequest();
    xhr.open(method, url);
    xhr.onload = function() {
      if (this.status >= 200 && this.status < 300) {
        resolve(xhr.response);
      } else {
        reject({
          status: this.status,
          statusText: xhr.statusText,
        });
      }
    };
    xhr.onerror = function() {
      reject({
        status: this.status,
        statusText: xhr.statusText,
      });
    };
    xhr.send();
  });
};
