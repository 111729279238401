/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-02-27
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Grid, Theme, CssBaseline } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';

// Own Components
import Sidebar from 'components/configurator/sidebar/Sidebar';

// Data Models
import { Route, RouteComponentProps, Redirect, Switch } from 'react-router-dom';
import { BOX_CONFIG, DASHBOARD_PATH } from 'config/routes';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'models/store';
import { Jaybox } from 'models/boxes';
import Configurator from 'components/configurator/Configurator';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    minHeight: '100%',
  },
  content: {
    height: '100%',
    display: 'flex',
    flex: 1,
  },
}));

const ConfiguratorView: React.FC = () => {
  const availableBoxes = useSelector<ApplicationState, Jaybox[]>(
    (state) => state.boxes.availableJayboxes,
  );
  const classes = useStyles();

  return (
    <Grid container className={classes.root} alignItems="stretch" wrap="nowrap">
      <CssBaseline />
      <Grid item style={{ width: '32.5rem', minWidth: '32.5rem' }}>
        <Sidebar />
      </Grid>
      <Grid item xs style={{ overflowY: 'auto' }}>
        <Grid className={classes.content} item xs={12}>
          <Switch>
            <Route
              path={BOX_CONFIG}
              exact
              render={(props: RouteComponentProps<{ id: string }>) => {
                const id = props.match.params.id;
                const box = availableBoxes.find((box) => box.id === id);
                if (box) {
                  return <Configurator jayboxId={id} />;
                } else {
                  return <Redirect to={DASHBOARD_PATH} />;
                }
              }}
            />
            <Route>
              <Redirect to={DASHBOARD_PATH} />
            </Route>
          </Switch>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConfiguratorView;
