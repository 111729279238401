/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-02-26
 * @author Tim Lange <tl@systl.de>
 */

// Third Party
import { Reducer } from 'redux';

// Models
import { NavigationState } from 'models/navigation/state';
import { NavigationAction, NavigationActionType } from 'models/navigation/actions';
import { CurrentNavigation } from 'models/navigation';

export const initialState: NavigationState = {
  currentNavigation: CurrentNavigation.DEFAULT,
  mobileMode: false,
};

const navigationReducer: Reducer<NavigationState, NavigationAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case NavigationActionType.SET_CURRENT_NAVIGATION: {
      return {
        ...state,
        currentNavigation: action.navigation,
      };
    }
    case NavigationActionType.SET_MOBILE_MODE_ACTION: {
      return {
        ...state,
        mobileMode: action.mobileMode,
      };
    }
    default: {
      return state;
    }
  }
};

export default navigationReducer;
