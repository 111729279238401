/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-06-09
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Box, IconButton } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { FC } from 'react';
import uuidv1 from 'uuid/v1';
import { namespacedAction } from 'redux-subspace';

// Assets
import { ReactComponent as AddCircleIcon } from 'assets/img/jaybox_step_add.svg';

// Own Components

// Config

// Data Models
import { OwnProps } from './propTypes';
import { StepElementTitleStateEntity, StepStateEntity } from 'jaybox/dist/models/stepdata';
import { HTMLType } from 'jaybox/dist/models/HTMLElements';

// Action Creator
import { setLocalization, setUnsavedChanges } from 'store/configuration/configurationSlice';
import { insertStepData } from 'jaybox/dist/store/stepdata/actions';
import { useAppDispatch } from 'store';

const useStyles = makeStyles<Theme, OwnProps>((theme: Theme) => ({
  root: {
    top: '50%',
    right: (prop) => (prop.precending ? 'unset' : 0),
    left: (prop) => (prop.precending ? 0 : 'unset'),
    position: 'absolute',
    transform: (prop) => (prop.precending ? 'translate(-52%, -50%)' : 'translate(52%, -50%)'),
    height: '2.8rem',
    width: '2.8rem',
  },
  addButton: {
    padding: '0',
    width: '100%',
    color: theme.palette.primary.main,
    height: '100%',
    margin: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiSvgIcon-root': {
      width: '100%',
      height: '100%',
    },
  },
  iconLabel: {
    width: '100%',
    height: '100%',
  },
}));

const AddStepHover: FC<OwnProps> = (props) => {
  const { style, hoveredStepNumber, precending } = props;

  const dispatch = useAppDispatch();
  const classes = useStyles(props);
  const [isHovering, setiIsHovering] = React.useState<boolean>(false);

  const updateLocalization = (key: string, value: string) => {
    dispatch(setLocalization({ key: key, localization: 'Frage', namespace: 'de' }));
  };

  const createStep = () => {
    const newTitle: StepElementTitleStateEntity = {
      type: HTMLType.TITLE,
      lbIf: null,
      localization: uuidv1(),
      uuid: uuidv1(),
    };

    const newStep: StepStateEntity = {
      type: 'step',
      stepType: 'default',
      uuid: uuidv1(),
      elementPreviewUrl: null,
      number: precending ? hoveredStepNumber : hoveredStepNumber + 1,
      lbIf: null,
      elements: [newTitle],
    };
    dispatch(namespacedAction('@@jaybox')(insertStepData(newStep)));
    dispatch(setUnsavedChanges({ changes: true }));
    updateLocalization(newTitle.localization, 'Frage');
  };

  return (
    <Box
      style={style ? style : {}}
      className={classes.root}
      onMouseEnter={() => {
        setiIsHovering(true);
      }}
      onMouseLeave={() => {
        setiIsHovering(false);
      }}
    >
      {isHovering ? (
        <IconButton
          style={{}}
          className={classes.addButton}
          classes={{ label: classes.iconLabel }}
          edge="start"
          arial-label="close-button"
          disableRipple
          disableFocusRipple
          disableTouchRipple
          onClick={() => {
            createStep();
          }}
        >
          <AddCircleIcon />
        </IconButton>
      ) : null}
    </Box>
  );
};

export default AddStepHover;
