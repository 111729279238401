/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-05-12
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Grid } from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Own components
// Action creator

// Data models
import { StateProps, OwnProps } from './propTypes';
// Utils
import JayboxOverlay from 'components/common/overlay';
import { ApplicationState } from 'models/store';
import {
  setCurrentlySelectedElement,
  setUnsavedChanges,
} from 'store/configuration/configurationSlice';
import { deleteElement, setMaxSteps, changeToStep } from 'jaybox/dist/store/stepdata/actions';
import { namespacedAction } from 'redux-subspace';
import { SelectedElement } from 'models/configuration';

const DeleteOverlay: React.FC<OwnProps> = (props) => {
  const { closedCallback, elementToDelete } = props;
  const { selectedElement, maxSteps, steps } = useSelector<ApplicationState, StateProps>(
    (state) => ({
      selectedElement: state.configuration.selectedElement,
      maxSteps: state.jaybox.stepData.maxSteps,
      steps: state.jaybox.stepData.steps,
    }),
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const deleteStep = (selected: SelectedElement) => {
    if (selected.primary.type === 'step') {
      let newNumber =
        selected.primary.number - 1 > steps.length - 1 ? selected.primary.number - 1 : 0;
      // if we delete first step choose another
      if (selected.primary.number === 0) {
        newNumber = selected.primary.number + 1 < steps.length ? selected.primary.number + 1 : 0;
      }
      dispatch(
        namespacedAction('@@jaybox')(changeToStep(steps[newNumber].number, steps[newNumber].uuid)),
      );
      dispatch(namespacedAction('@@jaybox')(setMaxSteps(maxSteps - 1)));
    }
  };

  const handleOnClickDelete = () => {
    const selected: SelectedElement | null = selectedElement
      ? selectedElement
      : elementToDelete
      ? elementToDelete
      : null;
    if (selected) {
      dispatch(setUnsavedChanges({ changes: true }));
      dispatch(namespacedAction('@@jaybox')(deleteElement(selected.primary.uuid)));
      if (selected.primary.type === 'step') {
        deleteStep(selected);
      }
      dispatch(setCurrentlySelectedElement({ element: null }));
      if (closedCallback) {
        closedCallback();
      }
    }
  };

  return (
    <JayboxOverlay
      open={true}
      cancelAction={() => {
        if (closedCallback) {
          closedCallback();
        }
      }}
      confirmAction={() => {
        handleOnClickDelete();
      }}
      title={t('deleteOverlay.title')}
      subTitle={t('deleteOverlay.subTitle')}
      cancelButtonText={t('deleteOverlay.cancel')}
      confirmButtonText={t('deleteOverlay.delete')}
    >
      <Grid container>
        <Grid item xs={12}></Grid>
      </Grid>
    </JayboxOverlay>
  );
};

export default DeleteOverlay;
