/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-03-12
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies

// Data models
import { LoginData, LoginError } from 'models/login';
import { RequestStatus } from 'models/common';

export enum LoginActionType {
  DATA = '@@login/DATA',
  DATA_RESET = '@@login/DATA_RESET',
  LOGIN_STATUS = '@@login/STATUS',
}

export interface LoginDataAction {
  data: LoginData;
  type: LoginActionType.DATA;
}

export interface LoginDataResetAction {
  type: LoginActionType.DATA_RESET;
}

export interface LoginStatusAction {
  error: LoginError;
  status: RequestStatus;
  type: LoginActionType.LOGIN_STATUS;
}

export type LoginAction = LoginDataAction | LoginDataResetAction | LoginStatusAction;
