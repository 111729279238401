/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-06-22
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { ThunkDispatch } from 'redux-thunk';

import * as firebase from 'firebase/app';
import moment from 'moment';

// Utils

// Data models
import { ApplicationAction, ApplicationState } from 'models/store';
import { RequestStatus } from 'models/common';
import { FireStoreError } from 'models/firebase';
import {
  PermissionActionType,
  LoadPermissionStatusAction,
  UpdateGrantedPermissionsUnsubscribeAction,
  SetGrantedPermissionsAction,
  SetCurrentWorkspace,
} from 'models/permission/actions';
import { Permission } from 'models/permission';
import { PermissionDocument } from 'models/firebase/permission';

export const updateLoadPermissionStatus = (
  status: RequestStatus,
  error: FireStoreError = FireStoreError.NONE,
): LoadPermissionStatusAction => ({
  error,
  status,
  type: PermissionActionType.LOAD_PERMISSIONS_STATUS,
});

export const saveUpdateGrantedPermissionUnsubscribe = (
  unsubscribe: () => void,
): UpdateGrantedPermissionsUnsubscribeAction => ({
  unsubscribe,
  type: PermissionActionType.UPDATE_GRANTED_PERMISSIONS_UNSUBSCRIBE,
});

export const updatePermission = (permission: Permission) => {
  return async (
    dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>,
  ): Promise<void> => {
    try {
      const permissionData: PermissionDocument = {
        acceptedAt: firebase.firestore.Timestamp.fromDate(permission.acceptedAt.toDate()),
        granted: permission.granted,
        invitationEmail: permission.invitationEmail,
        status: permission.status,
        targetId: permission.targetId,
        userId: permission.userId || '',
      };

      if (permission.permissionId) {
        await firebase
          .firestore()
          .collection('/versions/v1/permissions')
          .doc(permission.permissionId)
          .set(permissionData);
      } else {
        await firebase
          .firestore()
          .collection('/versions/v1/permissions')
          .add(permissionData);
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const setCurrentWorkspace = (workspaceId: string | null): SetCurrentWorkspace => ({
  workspaceId,
  type: PermissionActionType.SET_CURRENT_WORKSPACE,
});

export const updateGrantedPermissionState = (
  permissions: Permission[],
): SetGrantedPermissionsAction => ({
  grantedPermissions: permissions,
  type: PermissionActionType.SET_GRANTED_PERMISSIONS,
});

/**
 * load my granted permissions
 */
export const loadGrantedPermissions = () => {
  return async (
    dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>,
  ): Promise<void> => {
    dispatch(updateLoadPermissionStatus(RequestStatus.LOADING));
    try {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        const permissionCollectionReference = firebase
          .firestore()
          .doc(`/versions/v1`)
          .collection('permissions');
        const permissionQuery = permissionCollectionReference.where(
          'userId',
          '==',
          currentUser.uid,
        );
        const permissionQueryResultSubscription = permissionQuery.onSnapshot(
          (querySnapshot: firebase.firestore.QuerySnapshot) => {
            const permissions: Permission[] = [];

            querySnapshot.docs.forEach((doc) => {
              const permissionDocument = doc.data() as PermissionDocument;
              permissions.push({
                permissionId: doc.id,
                userId: permissionDocument.userId,
                targetId: permissionDocument.targetId,
                granted: permissionDocument.granted,
                status: permissionDocument.status,
                invitationEmail: permissionDocument.invitationEmail,
                acceptedAt: moment(permissionDocument.acceptedAt.toDate()),
              });
            });
            dispatch(updateLoadPermissionStatus(RequestStatus.SUCCESS));
            dispatch(updateGrantedPermissionState(permissions));
          },
          (err: Error) => {
            console.log(err);
            throw err;
          },
        );
        dispatch(saveUpdateGrantedPermissionUnsubscribe(permissionQueryResultSubscription));
      }
    } catch (error) {
      dispatch(updateLoadPermissionStatus(RequestStatus.ERROR, error.code as FireStoreError));
    } finally {
      setTimeout(() => {
        dispatch(updateLoadPermissionStatus(RequestStatus.IDLE));
      }, 2000);
    }
  };
};
