/**
 * @copyright 2020 Your Company
 * @created 2020-09-23
 * @author Tim Lange <you@you.you>
 */

// Third-party dependencies
import * as React from 'react';
import { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Loader from 'react-loaders';

// React-Loader styles
import './LoadingSpinner.scss';

// Props
export type LoadingSpinnerProps = {
  size?: 'large' | 'medium' | 'small';
  text?: string;
};

const getDotDimensions = (props: LoadingSpinnerProps, theme: Theme): number => {
  switch (props.size) {
    case 'large': {
      return theme.typography.fontSize * 2;
    }
    case 'medium': {
      return theme.typography.fontSize;
    }
    default: {
      return theme.typography.fontSize * 0.5;
    }
  }
};

// Styles
const useStyles = makeStyles<Theme, LoadingSpinnerProps>((theme) => ({
  root: {
    flexGrow: 1,
    height: '100%',
  },
  spinner: {
    '&>div': {
      backgroundColor: theme.palette.primary.main,
      height: (props) => getDotDimensions(props, theme),
      margin: (props) => getDotDimensions(props, theme) * 0.2,
      width: (props) => getDotDimensions(props, theme),
    },
  },
  text: {
    marginTop: theme.spacing(10),
  },
}));

const LoadingSpinner: FC<LoadingSpinnerProps> = (props) => {
  const { text } = props;
  const classes = useStyles(props);

  return (
    <Grid
      alignItems="center"
      className={classes.root}
      container
      direction="column"
      item
      xs={12}
      justify="center"
    >
      <Loader active innerClassName={classes.spinner} type="ball-pulse"></Loader>
      {text ? (
        <Typography align="center" className={classes.text} variant="h4">
          {text}
        </Typography>
      ) : (
        ''
      )}
    </Grid>
  );
};

LoadingSpinner.defaultProps = {
  size: 'medium',
  text: '',
};

export default LoadingSpinner;
