/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-02-11
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';

// Action creators
import { setCurrentNavigation } from 'store/navigation/actions';
import { setCurrentlySelectedElement } from 'store/configuration/configurationSlice';

// Presentational compoent
import ClickArea from './ClickArea';

// Data models
import { DispatchProps, OwnProps, StateProps } from './propTypes';
import { ApplicationState } from 'models/store';

import { SelectedElement } from 'models/configuration';
import { CurrentNavigation } from 'models/navigation';

const mapStateToProps: MapStateToProps<StateProps, OwnProps, ApplicationState> = (state) => ({
  selectedElement: state.configuration.selectedElement,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (dispatch) => ({
  setCurrentNavigation: (navigation: CurrentNavigation) => {
    dispatch(setCurrentNavigation(navigation));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ClickArea);
