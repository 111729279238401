/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-01-03
 * @author Tim Lange <tl@systl.de>
 */

import { Grid, Typography, IconButton } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import React from 'react';
import { useDrag } from 'react-dnd';

// Data models
import { Props } from './propTypes';
import { DraggableType } from 'models/draggable';

// Assets
import JayboxText from 'assets/img/jaybox_text.svg';
import JayboxOption from 'assets/img/jaybox_option.svg';
import JayboxSlider from 'assets/img/jaybox_slider.svg';
import JayboxRadio from 'assets/img/jaybox_radio.png';

// Styles
const useStyles = makeStyles<Theme, Props>((theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      transform: 'translateZ(0)',
    },
    full: {
      height: '100%',
      width: '100%',
    },
    draggable: {
      width: '100%',
      height: '4.8rem',
      textAlign: 'center',
      borderRadius: '0',
      backgroundColor: 'white',
    },
    iconLabel: {
      display: 'contents',
    },
    iconButton: {
      paddingRight: theme.spacing(4),
      paddingLeft: 0,
      paddingTop: 0,
      paddingBottom: 0,
      height: '100%',
      margin: '0',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    label: {
      paddingLeft: theme.spacing(4),
      fontWeight: 500,
      textTransform: 'none',
    },
  }),
);

const Element: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const { item } = props;

  const [, drag] = useDrag({
    item: item,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      dragColor: monitor.isDragging() ? '#FE4E8C' : 'unset',
      //opacity: monitor.isDragging() ? 0.4 : 1,
      //backgroundColor: monitor.isDragging() ? 'red' : 'unset',
    }),
  });

  const getIcon = (type: DraggableType) => {
    switch (type) {
      case DraggableType.Slider:
        return (
          <img
            src={JayboxSlider}
            alt="Jaybox icon logo"
            style={{ height: '2.1rem', width: '10.3rem' }}
          />
        );
      case DraggableType.Radio:
        return (
          <img
            src={JayboxRadio}
            alt="Jaybox icon logo"
            style={{ height: '2rem', paddingRight: '3.1rem' }}
          />
        );
      case DraggableType.Option:
        return <img src={JayboxOption} alt="Jaybox icon logo" style={{ height: '3.1rem' }} />;
      case DraggableType.Text:
        return (
          <img
            src={JayboxText}
            alt="Jaybox icon logo"
            style={{ height: '2rem', paddingRight: '3.1rem' }}
          />
        );
      default:
        return (
          <img
            src={JayboxText}
            alt="Jaybox icon logo"
            style={{ height: '2rem', paddingRight: '3.1rem' }}
          />
        );
    }
  };

  return (
    <Grid ref={drag} container className={classes.container} alignItems="center">
      <Grid item container xs={12} className={classes.draggable}>
        <Grid container item xs={6} alignItems="center" justify="flex-start">
          <Typography color="textPrimary" variant="body1" className={classes.label}>
            {item.label}
          </Typography>
        </Grid>
        <Grid item container xs={6} className={classes.full} justify="flex-end">
          <IconButton
            disabled
            edge="end"
            aria-label="jaybox-logo"
            classes={{ label: classes.iconLabel }}
            className={classes.iconButton}
          >
            {getIcon(item.type)}
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Element;
