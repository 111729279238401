/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-04-16
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Grid, Typography, LinearProgress, Link, IconButton } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Assets
import { ReactComponent as HorizontalSplitIcon } from 'assets/img/jaybox_horizontal_layout.svg';
import { ReactComponent as VerticalSplitIcon } from 'assets/img/jaybox_vertical_layout.svg';

// Data Models
import { StateProps, OwnProps } from './propTypes';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from 'models/store';
import { Jaybox } from 'models/boxes';
import { regExpEmail } from 'utils/dataInput';
import { MetaStateEntity } from 'jaybox/dist/models/stepdata';
import { setMetaData } from 'jaybox/dist/store/stepdata/actions';
import { namespacedAction } from 'redux-subspace';

// Own components
import JayboxTextField from 'components/common/text-field';
import JayboxButton from 'components/common/button';
import JayboxOverlay from 'components/common/overlay';
import Links from 'components/dashboard//detail/links/Links';

// Config
import { BOX_CONFIG } from 'config/routes';

// Action Creator
import { updateJaybox, setCurrentJaybox, saveCheckJobStateInterval } from 'store/boxes/boxesSlice';
import { cancelSubscription } from 'store/payment/paymentSlice';
import { triggerDeployment, checkJobState } from 'store/deploy/actions';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  wrapper: {
    padding: '5.5rem 0 4.3rem 11rem',
    height: '100%',
    overflowY: 'auto',
  },
  colorPickerWrapper: {
    padding: '0 0.5rem',
  },
  progress: {
    width: '100%',
    height: '1.7rem',
    borderRadius: '1.2rem',
    border: '1px solid #00A3FF',
    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: 'white',
    },
    '& .MuiLinearProgress-colorSecondary': {
      backgroundColor: '#00A3FF',
    },
  },
  largeSpacing: {
    paddingBottom: theme.spacing(12),
  },
  mediumSpacing: {
    paddingBottom: theme.spacing(10),
  },
  smallSpacing: {
    paddingBottom: theme.spacing(4),
  },
  verySmallSpacing: {
    paddingBottom: theme.spacing(2),
  },
  progressText: {
    fontSize: '1.2rem',
  },
  iconButton: {
    padding: '0',
  },
  icon: {
    width: '6.5rem',
    height: '6.5rem',
  },
  iconSelected: {
    '& #inner': {
      fill: '#00A3FF',
    },
    '& #outer': {
      stroke: '#00A3FF',
    },
  },
  iconUnselected: {
    '& #inner': {
      fill: '#333333',
    },
    '& #outer': {
      stroke: '#333333',
    },
  },
}));

const BoxDetail: FC<OwnProps> = (props) => {
  const { jayboxId } = props;
  const { availableJayboxes, currentJaybox, jobStateIntervalId } = useSelector<
    ApplicationState,
    StateProps
  >((state) => ({
    availableJayboxes: state.boxes.availableJayboxes,
    meta: state.jaybox.stepData.meta,
    currentJaybox: state.boxes.currentJaybox,
    jobStateIntervalId: state.boxes.checkJobStateIntervalId,
  }));
  const dispatch = useDispatch();
  const classes = useStyles();
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [triggerButtonDisabled, setTriggerButtonDisabled] = useState(false);
  const [deleteOverlayOpen, setDeleteOverlayOpen] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    const box = availableJayboxes.find((box) => box.id === jayboxId);
    if (box) {
      dispatch(setCurrentJaybox({ jaybox: box }));
    }
  }, [availableJayboxes, dispatch, jayboxId]);

  const updateMeta = <K extends keyof MetaStateEntity>(value: MetaStateEntity[K], field: K) => {
    if (!currentJaybox) {
      return;
    }

    const updatedJaybox: Jaybox = {
      ...currentJaybox,
    };

    updatedJaybox.metaData.customerTemplate = 'd-095d4fc6cae04762aa8e36d94e9ce4cb';
    updatedJaybox.metaData[field] = value;

    if (field === 'customerEmail') {
      const emailIsValid = regExpEmail.test(value);
      setEmailIsValid(emailIsValid);
    }

    dispatch(namespacedAction('@@jaybox')(setMetaData(updatedJaybox.metaData)));

    dispatch(updateJaybox({ box: updatedJaybox }));
  };

  React.useEffect(() => {
    if (currentJaybox && !currentJaybox.buildOnce && currentJaybox.buildProgress.done) {
      const updatedJaybox = {
        ...currentJaybox,
        buildOnce: true,
      };
      dispatch(updateJaybox({ box: updatedJaybox }));
    }
  }, [currentJaybox, dispatch]);

  useEffect(() => {
    return () => {
      if (jobStateIntervalId) {
        clearInterval(jobStateIntervalId);
        dispatch(saveCheckJobStateInterval({ intervalId: null }));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkJob = React.useCallback(() => {
    if (currentJaybox && currentJaybox.buildProgress.running) {
      dispatch(checkJobState(currentJaybox.buildProgress.jobId, currentJaybox));
    }
  }, [currentJaybox, dispatch]);

  useEffect(() => {
    if (currentJaybox && currentJaybox.buildProgress.running) {
      if (jobStateIntervalId === null) {
        const interval = setInterval(() => {
          checkJob();
        }, 5000);

        dispatch(saveCheckJobStateInterval({ intervalId: interval }));
      }
    } else {
      if (jobStateIntervalId) {
        clearInterval(jobStateIntervalId);
        dispatch(saveCheckJobStateInterval({ intervalId: null }));
      }
    }
  }, [checkJob, currentJaybox, dispatch, jobStateIntervalId]);

  const getProgress = () => {
    if (currentJaybox && currentJaybox.buildProgress.running) {
      return (
        <Grid container style={{ width: '80%' }} className={classes.largeSpacing}>
          <Grid item container xs={12} justify="flex-start" className={classes.mediumSpacing}>
            <Typography color="textPrimary" variant="h4" align="left">
              {t('jayboxDetail.progress')}
            </Typography>
          </Grid>
          <Grid item container xs={12} justify="flex-start" className={classes.verySmallSpacing}>
            <LinearProgress
              classes={{ root: classes.progress }}
              variant="buffer"
              value={currentJaybox.buildProgress.progress}
              valueBuffer={100}
            />
          </Grid>
          {currentJaybox.buildProgress.progress === 0 ? (
            <Grid item container xs={12} justify="flex-start">
              <Typography
                className={classes.progressText}
                color="textPrimary"
                variant="body2"
                align="left"
              >
                {t('jayboxDetail.scheduled')}
              </Typography>
            </Grid>
          ) : (
            <Grid item container xs={12} justify="center">
              <Typography
                className={classes.progressText}
                color="textPrimary"
                variant="body2"
                align="left"
              >
                {`${currentJaybox.buildProgress.progress}%`}
              </Typography>
            </Grid>
          )}
        </Grid>
      );
    } else {
      return null;
    }
  };

  const cancelJaybox = (jaybox: Jaybox) => {
    dispatch(cancelSubscription(jaybox));
  };

  const handleBuildPress = () => {
    if (currentJaybox) {
      const updatedBox: Jaybox = {
        ...currentJaybox,
        unbuildChanges: false,
      };
      dispatch(updateJaybox({ box: updatedBox }));
      dispatch(triggerDeployment(updatedBox));
      setTriggerButtonDisabled(true);
      setTimeout(() => {
        setTriggerButtonDisabled(false);
      }, 15000);
    }
  };

  const getContent = () => {
    if (!currentJaybox) {
      return <div />;
    }

    return (
      <Grid container justify="flex-start" className={classes.wrapper}>
        <Grid item container xs={12} justify="flex-start">
          <Grid item container xs={12} justify="flex-start" className={classes.largeSpacing}>
            <Typography color="textPrimary" variant="h2" align="left">
              {`Jaybox „${currentJaybox.name}“`}
            </Typography>
          </Grid>
          <Grid item container xs={12} justify="flex-start" className={classes.mediumSpacing}>
            <Typography color="textPrimary" variant="h3" align="left">
              {t('jayboxDetail.config')}
            </Typography>
          </Grid>
          <Grid item container xs={12} justify="flex-start" className={classes.largeSpacing}>
            <JayboxButton
              onClick={() => {
                history.push(BOX_CONFIG.replace(':id', currentJaybox.id));
              }}
            >
              {t('jayboxDetail.load')}
            </JayboxButton>
          </Grid>
          <Grid item container xs={12} justify="flex-start" className={classes.mediumSpacing}>
            <Typography color="textPrimary" variant="h3" align="left">
              {t('jayboxDetail.settings')}
            </Typography>
          </Grid>
          <Grid item container xs={12} justify="flex-start" className={classes.mediumSpacing}>
            <JayboxTextField
              id={'targetEmail'}
              error={!emailIsValid}
              helperText={emailIsValid ? '' : t('common.emailInvalid')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                updateMeta(event.target.value, 'customerEmail');
              }}
              label={t('jayboxDetail.targetEmail')}
              value={currentJaybox.metaData.customerEmail || ''}
              type={'email'}
              variant="outlined"
              fullWidth={true}
              popperText={t('jayboxDetail.emailDetail')}
            />
          </Grid>
          <Grid item container xs={12} justify="flex-start" className={classes.mediumSpacing}>
            <Typography color="textPrimary" variant="h4" align="left">
              {t('jayboxDetail.layout')}
            </Typography>
          </Grid>
          <Grid item container xs={12} justify="flex-start" className={classes.largeSpacing}>
            <span style={{ paddingRight: '1.2rem' }}>
              <IconButton
                className={classes.iconButton}
                onClick={() => updateMeta('horizontal', 'layout')}
              >
                <HorizontalSplitIcon
                  className={
                    currentJaybox.metaData.layout === 'horizontal'
                      ? classes.iconSelected
                      : classes.iconUnselected
                  }
                />
              </IconButton>
            </span>
            <span>
              <IconButton
                className={classes.iconButton}
                onClick={() => updateMeta('vertical', 'layout')}
              >
                <VerticalSplitIcon
                  className={
                    currentJaybox.metaData.layout === 'vertical'
                      ? classes.iconSelected
                      : classes.iconUnselected
                  }
                />
              </IconButton>
            </span>
          </Grid>
          <Grid item container xs={12} justify="flex-start" className={classes.mediumSpacing}>
            <Typography color="textPrimary" variant="h3" align="left">
              {t('jayboxDetail.publish')}
            </Typography>
          </Grid>
          <Grid item container xs={12} justify="flex-start" className={classes.smallSpacing}>
            <Typography color="textPrimary" variant="body1" align="left">
              {currentJaybox.unbuildChanges
                ? t('jayboxDetail.lastChange', {
                    date: currentJaybox.lastSaved.format('DD.MM.YYYY'),
                    time: currentJaybox.lastSaved.format('HH:mm'),
                  })
                : currentJaybox.buildProgress.running || triggerButtonDisabled
                ? t('jayboxDetail.currentlyDeploying')
                : t('jayboxDetail.noChanges')}
            </Typography>
          </Grid>
          <Grid item container xs={12} justify="flex-start" className={classes.largeSpacing}>
            <JayboxButton
              disabled={
                !currentJaybox.unbuildChanges ||
                currentJaybox.buildProgress.running ||
                triggerButtonDisabled
              }
              cta={true}
              onClick={handleBuildPress}
              buttonWidth=">12Chars"
              variant="contained"
            >
              {t('jayboxDetail.publishNow')}
            </JayboxButton>
          </Grid>
          {getProgress()}
          <Links />
          <Grid item container xs={12} justify="flex-start" style={{ paddingBottom: '2.5rem' }}>
            <Typography color="textPrimary" variant="h3" align="left">
              {t('jayboxDetail.cancel')}
            </Typography>
          </Grid>
          <Grid item container xs={12} justify="flex-start" className={classes.smallSpacing}>
            <Typography
              color="textPrimary"
              variant="body1"
              align="left"
              style={{ maxWidth: '80%' }}
            >
              {t('jayboxDetail.cancelDetail')}
            </Typography>
          </Grid>
          <Grid item container xs={12} justify="flex-start">
            <Link
              style={{ fontSize: '1.2rem' }}
              onClick={() => {
                setDeleteOverlayOpen(true);
              }}
            >
              {t('jayboxDetail.cancelNow')}
            </Link>
          </Grid>
        </Grid>
        <JayboxOverlay
          open={deleteOverlayOpen}
          title={t('jayboxDetail.cancel')}
          subTitle={t('jayboxDetail.cancelDetail')}
          cancelAction={() => {
            setDeleteOverlayOpen(false);
          }}
          confirmAction={() => {
            cancelJaybox(currentJaybox);
          }}
          confirmButtonText={t('jayboxDetail.cancelNow')}
        />
      </Grid>
    );
  };

  return (
    <Grid container className={classes.root} justify="flex-start" alignContent="flex-start">
      <Grid item container xs style={{ height: '100%' }}>
        {getContent()}
      </Grid>
    </Grid>
  );
};

export default BoxDetail;
