/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-02-19
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies

export enum FireStoreError {
  NONE = 'none',
  NOT_FOUND = 'not-found',
  ALREADY_EXISTS = 'already-exists',
  PERMISSION_DENIED = 'permission-denied',
}
