/**
 * Root view wrapping all Subcomponents
 *
 * @copyright 2020 Emden Consulting GmbH
 * @created 2019-12-18
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { useState, FC, useCallback, useEffect, Fragment } from 'react';
import { I18nextProvider } from 'react-i18next';
import { useLocation, Route, Switch } from 'react-router-dom';
import * as firebase from 'firebase/app';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

// View
import ConfiguratorView from '../configurator/ConfiguratorView';
import DashboardView from '../dashboard/DashboardView';

// Loader
import LoadingSpinner from 'components/common/loading-spinner/LoadingSpinner';

// Action Creator
import { loadBoxes } from 'store/boxes/boxesSlice';
import { loadGrantedPermissions, setCurrentWorkspace } from 'store/permission/actions';
import { updateAuthData, signOut } from 'store/auth/authSlice';

// Data Models
import { StateProps } from './propTypes';
import { AuthError } from 'models/auth';
import { JayboxUser } from 'models/user';
import { RequestStatus } from 'models/common';

// Utils
import { getJBSessionToken } from 'utils/URLHelper';
import { ApplicationState } from 'models/store';
import { updateLoginData, login } from 'store/login/actions';
import { loadUser, updateManagedUser } from 'store/user/userSlice';
import { BOX_CONFIG } from 'config/routes';
import { UserDocument } from 'models/firebase/user';
import { RootState, useAppDispatch } from 'store';
import i18n from 'utils/i18n';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    minHeight: '100%',
  },
  topBar: {
    height: '5%',
    backgroundColor: '#FE4E8C',
  },
  full: {
    height: '100%',
    width: '100%',
  },
  contentHeader: {
    height: '10%',
    backgroundColor: 'white',
  },
  contentHeaderButton: {
    width: '100%',
    height: '100%',
  },
  content: {
    overflowY: 'auto',
    height: '90%',
  },
  contentWrapper: {
    height: '90%',
    backgroundColor: '#B2B2B2',
  },
  footer: {
    height: '5%',
    backgroundColor: '#1A1A1A',
  },
  label: {
    fontWeight: 650,
  },
  fullHeight: {
    height: '100%',
  },
  loader: {
    textAlign: 'center',
  },
  info: {
    marginTop: theme.spacing(5),
  },
}));

const Root: FC = () => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated,
    shallowEqual,
  );
  const loginStatus = useSelector((state: RootState) => state.login.loginStatus);
  const loginData = useSelector((state: RootState) => state.login.loginData, shallowEqual);
  const profile = useSelector((state: RootState) => state.user.profile, shallowEqual);
  const updateBoxesUnsubscribe = useSelector(
    (state: RootState) => state.boxes.updateBoxesUnsubscribe,
  );
  const boxesInitiallyLoaded = useSelector((state: RootState) => state.boxes.boxesInitiallyLoaded);
  const grantedPermissions = useSelector(
    (state: RootState) => state.permission.grantedPermissions,
    shallowEqual,
  );
  const updateUserProfileUnsubscribe = useSelector(
    (state: RootState) => state.user.updateUserProfileUnsubscribe,
  );
  const grantedPermissionsUnsubscribe = useSelector(
    (state: RootState) => state.permission.updateGrantedPermissionsUnsubscribe,
  );
  const workspaceId = useSelector((state: RootState) => state.permission.currentWorkspaceId);

  const authError = useSelector((state: ApplicationState) => state.auth.authError, shallowEqual);

  const classes = useStyles();
  //const [, setStateForRerender] = useState<any>();
  //const previousJaybox = usePrevious<Jaybox | null>(currentJaybox);
  const location = useLocation();
  const [initialized, setInitialized] = React.useState<boolean>(false);
  const { t } = useTranslation();

  // React.useEffect(() => {
  //   if (
  //     (currentJaybox === null || (previousJaybox && currentJaybox.id !== previousJaybox.id)) &&
  //     steps.length > 0
  //   ) {
  //     steps.forEach((step) => {
  //       deleteElement(step.uuid);
  //     });
  //     setMaxSteps(0);
  //   }
  // }, [currentJaybox, deleteElement, previousJaybox, setMaxSteps, steps]);

  React.useEffect(() => {
    const jwtToken = getJBSessionToken(location);
    if (jwtToken) {
      const updatedData = { ...loginData, jwtToken: jwtToken };
      dispatch(updateLoginData(updatedData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location]);

  React.useEffect(() => {
    if (loginData.jwtToken && loginStatus === RequestStatus.IDLE && !isAuthenticated) {
      dispatch(login(loginData));
    }
  }, [dispatch, isAuthenticated, loginData, loginStatus]);

  // DidMount hook
  React.useEffect(() => {
    /* Register auth state change handler. Will also react on loaded session from local storage if
     * existing. There will also be a redirect to login if the user is no longer signed in due to a
     * no longer valid refreshtoken or a changed password.
     */
    const unsubscribe = firebase.auth().onAuthStateChanged(
      (user: firebase.User | null) => {
        if (!user) {
          // User no longer authenticated but no error -> just sign out
          dispatch(updateAuthData({ error: AuthError.NONE, isAuthenticated: false }));
          setInitialized(false);
          signOut();
        } else {
          // Write new data to store
          updateAuthData({ error: AuthError.NONE, isAuthenticated: true, user: user });
          dispatch(loadUser());
          dispatch(loadGrantedPermissions());
          setInitialized(true);
        }
      },
      (error) => {
        // There were errors during authentication
        updateAuthData({ error: error.code as AuthError, isAuthenticated: false });
        setInitialized(false);
      },
    );
    // Cleanup
    return () => {
      unsubscribe();
      updateUserProfileUnsubscribe();
      grantedPermissionsUnsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let unsubscribe = () => {};

    if (profile && profile.accountType === 'sub' && workspaceId) {
      unsubscribe = firebase
        .firestore()
        .doc(`/versions/v1/users/${workspaceId}`)
        .onSnapshot(
          (snapshot: firebase.firestore.DocumentSnapshot) => {
            // Add event observer for event changes
            const user = snapshot.data() as UserDocument;

            const newProfile: JayboxUser = {
              accountType: user.accountType,
              uid: workspaceId,
              paymentDetails: {
                customerId: user.paymentDetails?.customerId || '',
              },
            };
            dispatch(updateManagedUser({ profile: newProfile }));
          },
          function(error: Error) {
            console.log(error);
          },
        );
    }

    return () => {
      unsubscribe();
    };
  }, [dispatch, profile, workspaceId]);

  // Only one managed account allowed for now
  // Therefore set the first fetched permission
  useEffect(() => {
    if (!initialized) {
      return;
    }

    if (grantedPermissions.length > 0) {
      dispatch(setCurrentWorkspace(grantedPermissions[0].targetId));
      dispatch(loadBoxes({ userId: grantedPermissions[0].targetId }));
    } else {
      dispatch(loadBoxes({}));
      dispatch(setCurrentWorkspace(null));
    }

    return () => {
      updateBoxesUnsubscribe();
    };
  }, [dispatch, grantedPermissions, initialized]);

  const getContent = () => {
    if (initialized && boxesInitiallyLoaded) {
      return (
        <Switch>
          <Route path={BOX_CONFIG} exact component={ConfiguratorView} />
          <Route component={DashboardView} />
        </Switch>
      );
    } else {
      return (
        <Grid container alignItems="center" justify="center" className={classes.fullHeight}>
          <Grid item xs={12} className={classes.loader}>
            {authError === AuthError.NONE ? (
              <Fragment>
                <LoadingSpinner />
                <Typography align="center" className={classes.info} variant="h4">
                  {t('common.appLoading')}
                </Typography>
              </Fragment>
            ) : (
              <Typography align="center" className={classes.info} variant="h4">
                {t('common.loginError')}
              </Typography>
            )}
          </Grid>
        </Grid>
      );
    }
  };

  return <I18nextProvider i18n={i18n}>{getContent()}</I18nextProvider>;
};

export default Root;
