/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-02-25
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

// Presentational component
import Footer from './Footer';

// Action Creator
import { setMobileMode } from 'store/navigation/actions';
import { triggerDeployment, checkJobState } from 'store/deploy/actions';

// Data models
import { StateProps } from './propTypes';
import { ApplicationState, ApplicationAction } from 'models/store';
import { DispatchProps } from './propTypes';

const mapStateToProps: MapStateToProps<StateProps, {}, ApplicationState> = (state) => ({
  steps: state.jaybox.stepData.steps,
  meta: state.jaybox.stepData.meta,
  mobileMode: state.navigation.mobileMode,
  currentJaybox: state.boxes.currentJaybox,
  user: state.user.profile,
  availableJayboxes: state.boxes.availableJayboxes,
  jobStateIntervalId: state.boxes.checkJobStateIntervalId,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = (
  dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>,
) => ({
  triggerDeployment: (jaybox) => {
    dispatch(triggerDeployment(jaybox));
  },
  setMobileMode: (mode) => {
    dispatch(setMobileMode(mode));
  },
  checkJobState: (jobId, jaybox) => {
    dispatch(checkJobState(jobId, jaybox));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
