/**
 * @copyright 2020 Your Company
 * @created 2020-09-01
 * @author Tim Lange <you@you.you>
 */

import { Environment } from 'models/firebase/functions';
import { FB_PROJECT_ID } from 'config/env';

export const getEnvironment = (): Environment => {
  if (FB_PROJECT_ID) {
    if (FB_PROJECT_ID.includes('dev')) {
      return 'dev';
    } else if (FB_PROJECT_ID.includes('stage')) {
      return 'stage';
    }
  }

  return 'prod';
};
