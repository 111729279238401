/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-03-11
 * @author Tim Lange <tl@systl.de>
 */

// third party
import * as React from 'react';
import { useCallback, useEffect } from 'react';

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = React.useRef<T>();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useDebouncedEffect = (
  effect: () => void | (() => void | undefined),
  delay: number,
  deps: ReadonlyArray<any>,
) => {
  const callback = useCallback(effect, deps);

  useEffect(() => {
    const handler = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [callback, delay]);
};
