/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-07-21
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { namespacedAction } from 'redux-subspace';

// Presentational component
import LabelForm from './LabelForm';

// Action creator
import { setLabelElement } from 'jaybox/dist/store/stepdata/actions';

// Data models
import { StateProps, DispatchProps } from './propTypes';
import { ApplicationState, ApplicationAction } from 'models/store';
import { StepElementLabelStateEntity } from 'jaybox/dist/models/stepdata';

const mapStateToProps: MapStateToProps<StateProps, {}, ApplicationState> = (state) => ({
  currentStepUUID: state.jaybox.stepData.currentStepUUID,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = (
  dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>,
) => ({
  setLabelElement: (element: StepElementLabelStateEntity, stepUUID: string) => {
    dispatch(namespacedAction('@@jaybox')(setLabelElement(element, stepUUID)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LabelForm);
