/**
 * ElementsBar component
 *
 * @copyright 2020 Emden Consulting GmbH
 * @created 2019-12-13
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Grid } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

// Own components
import Element from 'components/configurator/draggable/Element/Element';

// Data models
import { Props } from './propTypes';
import { DraggableType, DragItem } from 'models/draggable';

// Styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
    },
    content: {},
  }),
);

const ElementsBar: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const entries: DragItem[] = [
    { label: t('toolbar.option'), type: DraggableType.Option },
    { label: t('toolbar.slider'), type: DraggableType.Slider },
    { label: t('toolbar.text'), type: DraggableType.Text },
    { label: t('toolbar.radio'), type: DraggableType.Radio },
    { label: t('toolbar.label'), type: DraggableType.Label },
  ];

  return (
    <Grid container alignItems="flex-start" className={classes.container}>
      <Grid item xs={12} className={classes.content}>
        {entries.map((element) => (
          <Element item={element} key={`attribute-${element.type}`} />
        ))}
      </Grid>
    </Grid>
  );
};

export default ElementsBar;
