/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-03-12
 * @author Tim Lange <tl@systl.de>
 */

// Third Party
import startsWith from 'lodash/startsWith';
import * as qs from 'query-string';
import { Location } from 'history';

export interface ParsedQuery<T = string> {
  [key: string]: T | T[] | null | undefined;
}

export const getJBSessionToken = (location: Location): string | null => {
  const searchParams: ParsedQuery<string> = qs.parse(location.search);

  let foundSession: null | string = null;
  Object.keys(searchParams).forEach(function(key) {
    const param = searchParams[key];
    if (param && typeof param === 'string' && startsWith(key, 'jb_session')) {
      foundSession = param;
    }
  });

  return foundSession;
};
