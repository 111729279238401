/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-04-20
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Grid, Typography, Tabs, Tab } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Data Models
import { StateProps } from './propTypes';
import { ApplicationState } from 'models/store';

// Own Components
import JayboxButton from 'components/common/button';

// Store
import { RootState } from 'store';

// Utils
import { getTargetProfileId } from 'utils/userUtils';

// Assets

enum TabEntry {
  DIRECT = 'direct',
  EMBEDDED = 'embedded',
  INLINE = 'inline',
}

const tabs = [
  {
    id: TabEntry.DIRECT,
    label: 'jayboxDetail.direct',
  },
  {
    id: TabEntry.EMBEDDED,
    label: 'jayboxDetail.embedded',
  },
  {
    id: TabEntry.INLINE,
    label: 'jayboxDetail.inline',
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  largeSpacing: {
    paddingBottom: '3.8rem',
  },
  mediumSpacing: {
    paddingBottom: '2.9rem',
  },
  smallSpacing: {
    paddingBottom: '1.2rem',
  },
  verySmallSpacing: {
    paddingBottom: '0.8rem',
  },
  tabsRoot: {
    color: '#333333',
    minHeight: 'unset',
  },
  tabContainer: {
    margin: 0,
    justifyContent: 'flex-start',
    '& .Mui-selected': {
      borderBottom: '2px solid #FF3A82',
      color: '#FF3A82',
    },
    '& .MuiTab-textColorInherit': {
      opacity: '1',
    },
  },
  tabRoot: {
    '&:hover': {
      borderBottom: '2px solid #FF3A82',
    },
    lineHeight: '2.2rem',
    textTransform: 'capitalize',
    height: '3.2rem',
    fontWeight: 'bolder',
    fontSize: '1.6rem',
    minHeight: 'unset',
    maxHeight: 'unset',
    margin: '0 6.4rem 0 0',
    padding: '0',
    minWidth: 'unset',
    maxWidth: 'unset',
    [theme.breakpoints.down('sm')]: {},
    [theme.breakpoints.up('md')]: {},
  },
  tabContent: {
    backgroundColor: '#FFFFFF',
    padding: '1.5rem 1rem',
  },
}));

const Links: FC = () => {
  const { currentJaybox, user } = useSelector<ApplicationState, StateProps>((state) => ({
    currentJaybox: state.boxes.currentJaybox,
    user: state.user.profile,
  }));
  const permissions = useSelector((state: RootState) => state.permission.grantedPermissions);

  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const { t } = useTranslation();

  const getLinkComponent = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          {jayboxLink()}
        </Grid>
      </Grid>
    );
  };

  const getComponent = () => {
    return (
      <Grid container className={classes.tabContent}>
        <Grid item xs={12}>
          {activeTab.id === TabEntry.DIRECT ? getLinkComponent() : <div />}
        </Grid>
      </Grid>
    );
  };

  const jayboxLink = () => {
    const permissionUserId = getTargetProfileId(permissions);

    if (user) {
      return `http://static.jaybox.com/${permissionUserId || user.uid}/${
        currentJaybox ? currentJaybox.id : ''
      }`;
    }
    return '';
  };

  const copyToClipboard = () => {
    const el = document.createElement('textarea');
    el.value = '';
    if (activeTab.id === TabEntry.DIRECT) {
      el.value = jayboxLink();
    }
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  const getContent = () => {
    if (currentJaybox && currentJaybox.buildOnce) {
      return (
        <Grid container style={{ width: '80%' }} className={classes.largeSpacing}>
          <Grid item container xs={12} justify="flex-start" className={classes.mediumSpacing}>
            <Typography color="textPrimary" variant="h4" align="left">
              {t('jayboxDetail.links')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Tabs
              value={activeTab}
              centered
              TabIndicatorProps={{ style: { backgroundColor: '#FF3A82', height: '0' } }}
              classes={{ flexContainer: classes.tabContainer, root: classes.tabsRoot }}
            >
              {tabs.map((tabEntry) => (
                <Tab
                  classes={{ root: classes.tabRoot }}
                  key={`${tabEntry.id}-tab`}
                  label={t(tabEntry.label)}
                  value={tabEntry}
                  onClick={() => {
                    setActiveTab(tabEntry);
                  }}
                />
              ))}
            </Tabs>
            <Grid container style={{ backgroundColor: '#FFF' }}>
              <Grid item xs={12}>
                {getComponent()}
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: '4.2rem' }}>
              <JayboxButton cta={false} primaryColor={false} onClick={copyToClipboard}>
                {t('common.copy')}
              </JayboxButton>
            </Grid>
          </Grid>
        </Grid>
      );
    } else {
      return null;
    }
  };

  return (
    <Grid container className={classes.root} justify="flex-start" alignContent="flex-start">
      <Grid item xs>
        {getContent()}
      </Grid>
    </Grid>
  );
};

export default Links;
