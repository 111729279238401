/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-04-16
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import {
  Grid,
  ButtonBase,
  Theme,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FC, Fragment } from 'react';

// Own Components
import Breadcrumb from 'components/navigation/Breadcrumb';

// Utils

// Data Models
import { StateProps } from './propTypes';
import JayboxButton from 'components/common/button';
import { ApplicationState } from 'models/store';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentJaybox } from 'store/boxes/boxesSlice';

// Assets
import JayboxIcon from 'assets/img/jaybox-logo2-02.png';
import { useHistory } from 'react-router';
import { CREATE_NEW_PATH, BOX_DETAIL } from 'config/routes';
import { ReactComponent as JayboxIconWhite } from 'assets/img/jaybox_logo_white.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
    },
    topBar: {
      height: '4.8rem',
      backgroundColor: '#333333',
    },
    full: {
      height: '100%',
      width: '100%',
    },
    contentTitle: {
      padding: '2.8rem 1.6rem',
    },
    content: {
      height: '(100% - 6.5rem)',
    },
    contentWrapper: {
      height: 'calc(100% - 4.8rem - 4.8rem - 6.5rem)',
      overflowY: 'auto',
      backgroundColor: '#CCCCCC',
    },
    footer: {
      height: '4.8rem',
      backgroundColor: '#333333',
    },
    switchToJayboxIcon: {
      height: '3.9rem',
      width: '3.9rem',
      color: 'white',
      backgroundColor: '#1FC1AE',
    },
    switchToJayboxButton: {
      width: '100%',
      height: '3.9rem',
      backgroundColor: 'white',
      borderRadius: '0',
      padding: '0',
    },
    switchToJayboxLabel: {
      justifyContent: 'space-between',
    },
    topBarTitle: {
      textTransform: 'uppercase',
      color: 'white',
    },
    breadcrumbs: {
      height: '6.5rem',
      backgroundColor: 'white',
      paddingLeft: '1.6rem',
    },
    listRoot: {
      width: '100%',
      backgroundColor: 'white',
      padding: '0',
    },
    avatarRoot: {
      minWidth: 'unset',
      paddingRight: '1.6rem',
    },
    avatar: {
      width: '2.895rem',
      height: '3.2rem',
    },
    buttonContainer: {
      width: '100%',
      paddingTop: '6.4rem',
      paddingLeft: '1.6rem',
    },
    divider: {
      marginLeft: '6.095rem',
    },
    icon: {
      width: '3rem',
      height: '3rem',
      paddingRight: '0.5rem',
    },
  }),
);

const Sidebar: FC = () => {
  const dispatch = useDispatch();

  const { availableJayboxes, currentlySelectedJaybox } = useSelector<ApplicationState, StateProps>(
    (state) => ({
      availableJayboxes: state.boxes.availableJayboxes,
      currentlySelectedJaybox: state.boxes.currentJaybox,
    }),
  );

  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const getBoxes = () => {
    return (
      <Grid container className={classes.full} alignContent="flex-start">
        <List className={classes.listRoot}>
          {availableJayboxes.map((jaybox, index) => (
            <Grid item xs={12} key={jaybox.id}>
              {index > 0 ? (
                <Divider variant="inset" component="li" className={classes.divider} />
              ) : null}
              <ButtonBase
                style={{ width: '100%', height: '6.4rem' }}
                onClick={() => {
                  dispatch(setCurrentJaybox({ jaybox: jaybox }));
                  history.push(BOX_DETAIL.replace(':id', jaybox.id));
                }}
              >
                <ListItem alignItems="center">
                  <ListItemAvatar className={classes.avatarRoot}>
                    <Avatar alt="jaybox icon" src={JayboxIcon} classes={{ root: classes.avatar }} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography
                        component="span"
                        variant="body1"
                        color="textPrimary"
                        style={{
                          fontWeight: 'bold',
                          color:
                            currentlySelectedJaybox && currentlySelectedJaybox.id === jaybox.id
                              ? '#00A3FF'
                              : 'inherit',
                        }}
                      >
                        {jaybox.name}
                      </Typography>
                    }
                  />
                </ListItem>
              </ButtonBase>
            </Grid>
          ))}
        </List>
      </Grid>
    );
  };

  const createNewButtonClick = () => {
    history.push(CREATE_NEW_PATH);
  };

  const getCreateButton = () => {
    return (
      <Grid item container xs={12} justify="flex-start" className={classes.buttonContainer}>
        <JayboxButton onClick={createNewButtonClick} buttonWidth=">12Chars">
          {t('jayboxDetail.create')}
        </JayboxButton>
      </Grid>
    );
  };

  const getContent = () => {
    if (availableJayboxes.length === 0) {
      return getCreateButton();
    } else {
      return (
        <Fragment>
          <Grid item container xs={12} className={classes.contentTitle} alignItems="center">
            <Typography
              variant="body1"
              color="textPrimary"
              align="center"
              style={{ fontWeight: 'bold' }}
            >
              {t('toolbar.navigation.collection')}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.content}>
            {getBoxes()}
            {getCreateButton()}
          </Grid>
        </Fragment>
      );
    }
  };

  return (
    <Grid container className={classes.root} alignItems="flex-start">
      <Grid item container xs={12} className={classes.topBar} justify="center" alignItems="center">
        <Grid container justify="center" wrap="nowrap" alignItems="center">
          <span>
            <JayboxIconWhite className={classes.icon} />
          </span>
          <span>
            <Typography variant="h4" className={classes.topBarTitle} align="center">
              {t('toolbar.title')}
            </Typography>
          </span>
        </Grid>
      </Grid>
      <Grid item container xs={12} className={classes.breadcrumbs}>
        <Breadcrumb />
      </Grid>
      <Grid item xs={12} className={classes.contentWrapper}>
        {getContent()}
      </Grid>
      <Grid item xs={12} className={classes.footer}>
        <div />
      </Grid>
    </Grid>
  );
};

export default Sidebar;
