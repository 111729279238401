/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-01-20
 * @author Tim Lange <tl@systl.de>
 */

// Third party
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import set from 'set-value';
import produce from 'immer';

// models
import { CustomTheme } from 'jaybox/dist/models/Theme';
import { SelectedElement } from 'models/configuration';

// Utils
import { updateRessource, overwriteRessource } from 'utils/i18n/jayboxI18n';
import { createAppThunk } from 'utils/appActions';
import { FontStyle } from 'jaybox/dist/utils/FontStyling';

const sliceName = '@@configuration';

export interface ConfigurationState {
  xml: string;
  style: CustomTheme;
  selectedElement: SelectedElement | null;
  localization: Record<string, any>;
  buildJaybox: boolean;
  saveJaybox: boolean;
  deleteJaybox: boolean;
  unsavedChanges: boolean;
  configLoaded: boolean;
}

export interface SetXMLPayload {
  xml: string;
}

export interface SetUnsavedChangesPayload {
  changes: boolean;
}

export interface SetConfigLoadedPayload {
  configLoaded: boolean;
}

export interface SetStylePayload {
  theme: CustomTheme;
}

export interface SetStyleKeyPayload {
  key: string;
  option: string;
}

export interface SetFontStylePayload {
  fontStyle: FontStyle;
  uuid: string;
}

export interface SetCurrentlySelectedElementPayload {
  element: SelectedElement | null;
}

export interface SetLocalizationPayload {
  key: string;
  localization: string;
  namespace: string | 'de';
}

export interface SetLocalizationFilePayload {
  localization: Record<string, any>;
}

export interface SetBuildJayboxPressedStatePayload {
  build: boolean;
}

export interface SaveJayboxPressedStatePayload {
  save: boolean;
}

export interface DeleteJayboxPressedStatePayload {
  deletePressed: boolean;
}

export const initialState: ConfigurationState = {
  selectedElement: null,
  buildJaybox: false,
  saveJaybox: false,
  deleteJaybox: false,
  localization: {},
  configLoaded: false,
  unsavedChanges: false,
  xml: '',
  style: {
    optionBackgroundColor: '#4a5a5b',
    optionTextColor: '#FFFFFF',
    optionSelectedBackgroundColor: '#68b2f1',
    optionSelectedTextColor: '#FFFFFF',
    optionContourColor: '#cbb9ad',
    optionContourSize: '0px',

    sliderTextColor: '#ff3a82',
    sliderColor: '#68b2f1',

    inputTextColor: '#000000',
    inputColor: '#4a5a5b',

    checkboxTextColor: '#4a5a5b',
    checkboxColor: '#ff3a82',

    titleBackgroundColor: '#4a5a5b',
    titleTextColor: '#FFFFFF',

    radioTextColor: '#4a5a5b',
    radioColor: '#4a5a5b',
    radioSelectedColor: '#68b2f1',

    progressBarColor: '#abb4b3',
    progressBarUnfilledColor: '#FFFFFF',

    backgroundColor: '#FFFFFF',

    fontFamily: 'Verdana, Geneva, sans-serif',
    contentPadding: '6rem',
    errorColor: '#b8457d',
    primaryColor: '#4a5a5b',
    secondaryColor: '#FFFFFF',

    backButtonColor: '#ff3a82',
    navigationButtonBackgroundColor: '#f5f5f5',
    forwardButtonColor: '#ff3a82',
    sendButtonColor: '#ff3a82',
    sendButtonTextColor: '#FFFFFF',

    font: {},
  },
};

export const buildJaybox = createAppThunk(
  sliceName + '/buildJaybox',
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(setBuildJayboxPressedState({ build: true }));
      setTimeout(() => {
        dispatch(setBuildJayboxPressedState({ build: false }));
      }, 2000);
    } catch (error) {
      return rejectWithValue({ errorMessage: error });
    }
  },
);

export const cleanUp = createAppThunk(sliceName + '/cleanUp', async (_, { dispatch, getState }) => {
  try {
    await dispatch(init());
  } catch (err) {}
});

const configurationSlice = createSlice({
  extraReducers: (builder) => {},
  initialState,
  name: sliceName,
  reducers: {
    init: () => initialState,
    setXML(state, action: PayloadAction<SetXMLPayload>) {
      state.xml = action.payload.xml;
    },
    setUnsavedChanges(state, action: PayloadAction<SetUnsavedChangesPayload>) {
      state.unsavedChanges = action.payload.changes;
    },
    setConfigLoaded(state, action: PayloadAction<SetConfigLoadedPayload>) {
      state.configLoaded = action.payload.configLoaded;
    },
    setStyle(state, action: PayloadAction<SetStylePayload>) {
      state.style = action.payload.theme;
    },
    setStyleKey(state, action: PayloadAction<SetStyleKeyPayload>) {
      return {
        ...state,
        style: { ...state.style, [action.payload.key]: action.payload.option },
      };
    },
    setFontStyle(state, action: PayloadAction<SetFontStylePayload>) {
      return {
        ...state,
        style: {
          ...state.style,
          font: { ...state.style.font, [action.payload.uuid]: action.payload.fontStyle },
        },
      };
    },
    setCurrentlySelectedElement(state, action: PayloadAction<SetCurrentlySelectedElementPayload>) {
      state.selectedElement = action.payload.element;
    },
    setLocalization(state, action: PayloadAction<SetLocalizationPayload>) {
      updateRessource(action.payload.key, action.payload.localization);
      state.localization = produce(state.localization, (draftState) => {
        set(
          draftState,
          `${action.payload.namespace}.${action.payload.key}`,
          action.payload.localization,
        );
      });
    },
    setLocalizationFile(state, action: PayloadAction<SetLocalizationFilePayload>) {
      overwriteRessource(action.payload.localization);
      state.localization = action.payload.localization;
    },
    setBuildJayboxPressedState(state, action: PayloadAction<SetBuildJayboxPressedStatePayload>) {
      state.buildJaybox = action.payload.build;
    },
    saveJayboxPressedState(state, action: PayloadAction<SaveJayboxPressedStatePayload>) {
      state.saveJaybox = action.payload.save;
    },
    deleteJayboxPressedState(state, action: PayloadAction<DeleteJayboxPressedStatePayload>) {
      state.deleteJaybox = action.payload.deletePressed;
    },
  },
});

export const {
  init,
  deleteJayboxPressedState,
  saveJayboxPressedState,
  setBuildJayboxPressedState,
  setConfigLoaded,
  setUnsavedChanges,
  setXML,
  setStyleKey,
  setStyle,
  setLocalizationFile,
  setLocalization,
  setFontStyle,
  setCurrentlySelectedElement,
} = configurationSlice.actions;

export default configurationSlice.reducer;
