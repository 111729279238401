/**
 * Overall entry point for build and global styles. Just call ReactDOM.render() for root id
 *
 * @copyright 2020 Emden Consulting GmbH
 * @created 2019-12-11
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './components/app/App';
import * as serviceWorker from './serviceWorker';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

// Environment
import {
  FB_API_KEY,
  FB_APP_ID,
  FB_AUTH_DOMAIN,
  FB_DATABASE_URL,
  FB_MESSAGING_SENDER_ID,
  FB_PROJECT_ID,
  FB_STORAGE_BUCKET,
} from 'config/env';

// Initialize Firebase
firebase.initializeApp({
  apiKey: FB_API_KEY,
  authDomain: FB_AUTH_DOMAIN,
  databaseURL: FB_DATABASE_URL,
  projectId: FB_PROJECT_ID,
  storageBucket: FB_STORAGE_BUCKET,
  messagingSenderId: FB_MESSAGING_SENDER_ID,
  appId: FB_APP_ID,
});

// firebase.firestore().settings({ host: FB_DATABASE_URL, ssl: false });
// firebase.auth().useEmulator('http://localhost:9099/');

ReactDOM.render(<App />, document.getElementById('jaybox-configurator'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
