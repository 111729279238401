/* eslint-disable no-restricted-imports */
/**
 * Editor component
 *
 * @copyright 2020 Emden Consulting GmbH
 * @created 2019-12-13
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Grid, Theme, Hidden, Typography } from '@material-ui/core';
import {
  makeStyles,
  MuiThemeProvider,
  createMuiTheme,
  createStyles,
} from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { BreakpointsOptions } from '@material-ui/core/styles/createBreakpoints';
import React, { useState } from 'react';
import uuidv1 from 'uuid/v1';
import { blue, grey, red, pink } from '@material-ui/core/colors';
import update from 'immutability-helper';
import { useScreenshot } from 'use-react-screenshot';
import { I18nextProvider } from 'react-i18next';

// Own Components
import DropBox from 'components/configurator/draggable/DropBox/DropBox';
import Header from 'jaybox/dist/components/header/Header';
import ProgressBar from 'jaybox/dist/components/progressBar/ProgressBar';
import Footer from 'jaybox/dist/components/footer/Footer';
import Card from './Card/Card';
import ClickArea from 'components/hoc/ClickAreaContainer';

// Data models
import { Props } from './propTypes';
import { DragItem, DraggableType } from 'models/draggable';
import {
  StepElementSelectStateEntity,
  StepElementOptionStateEntity,
  StepStateEntity,
  StepElementFormStateEntity,
  StepElementFormInputTextStateEntity,
  StepElementSliderStateEntity,
  StepElementFormInputEmailStateEntity,
  StepElementFormTextAreaStateEntity,
  StepElementFormInputNumberStateEntity,
  StepElementFormInputCheckboxStateEntity,
  StepElementRadioStateEntity,
  StepElementRadiogroupStateEntity,
  StepElementLabelStateEntity,
} from 'jaybox/dist/models/stepdata';
import { HTMLType, FormType } from 'jaybox/dist/models/HTMLElements';
import { CustomTheme } from 'jaybox/dist/models/Theme';
import { SelectedElement } from 'models/configuration';
import { useAppDispatch } from 'store';

// Action Creator
import { savePreview } from 'store/config/configSlice';
import {
  setCurrentlySelectedElement,
  setLocalization,
  setUnsavedChanges,
} from 'store/configuration/configurationSlice';

// Utils
import { getStepByUUID } from 'jaybox/dist/utils/ConditionalChecker';
import { usePrevious, useDebouncedEffect } from 'utils/helper';
import i18n from 'utils/i18n/jayboxI18n';

// Styles
const useStyles = makeStyles<Theme, Props & { customTheme: CustomTheme }>((theme) =>
  createStyles({
    root: {
      height: '100%',
    },
    title: {
      marginTop: theme.spacing(12),
      height: theme.spacing(16.25),
    },
    titleText: {
      fontWeight: 'bold',
      //paddingBottom: theme.spacing(5),
    },
    mainContent: {
      border: `1px solid ${theme.palette.text.primary}`,
      height: `calc(100% - ${theme.spacing(16.25)}px - ${theme.spacing(12)}px)`,
      maxHeight: theme.spacing(142),
    },
    container: {
      height: '100%',
      //backgroundColor: '#79C8C5',
      position: 'relative',
    },
    progressBar: {
      height: '2.5rem',
    },
    header: (props) => {
      return {
        height:
          props.mobileMode || props.meta.layout === 'horizontal'
            ? 'calc(40% - 2.5rem)'
            : 'calc(100% - 2.5rem)',
      };
    },
    mainContainer: (props) => {
      if (props.customTheme.gradientColorLeft && props.customTheme.gradientColorRight) {
        return {
          backgroundImage: `linear-gradient(to bottom right, ${props.customTheme.gradientColorLeft}, ${props.customTheme.gradientColorRight})`,
        };
      } else {
        return {
          backgroundColor: props.customTheme.backgroundColor,
        };
      }
    },
    footer: {
      height: 'calc(6rem + 1px)',
      width: '100%',
      position: 'absolute',
      backgroundColor: 'transparent',
    },
    headerButton: {
      width: '100%',
      borderRadius: '0',
      padding: '1rem',
      border: '1px solid black',
    },
    content: (props) => {
      return {
        paddingTop: theme.spacing(17),
        paddingBottom: theme.spacing(17),
        paddingLeft: '5rem',
        paddingRight: '5rem',
        position: 'relative',
        height:
          props.mobileMode || props.meta.layout === 'horizontal' ? '60%' : 'calc(100% - 2.5rem)',
        maxHeight:
          props.mobileMode || props.meta.layout === 'horizontal' ? '60%' : 'calc(100% - 2.5rem)',
      };
    },
    cardContent: {
      border: `1px solid ${theme.palette.text.primary}`,
      height: '100%',
      width: '100%',
      position: 'relative',
      paddingTop: theme.spacing(4),
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    // select element
    selectButton: {
      width: '100%',
      borderRadius: '0',
      backgroundColor: 'white',
    },
    selectOptionAddContainer: {
      border: '1px dashed white',
      width: '100%',
      margin: '2rem',
      textAlign: 'center',
    },
    selectOptionAddIcon: {
      width: '4rem',
      height: '4rem',
      color: 'white',
    },
    selectOptionAddLabel: {
      color: 'white',
    },

    // option element
    optionButton: {
      width: '80%',
      borderRadius: '0',
      backgroundColor: 'white',
    },
  }),
);

const mainTheme = (imported: CustomTheme, customBreakpoints: BreakpointsOptions): Theme => {
  return createMuiTheme({
    imported: { ...imported },
    breakpoints: { ...customBreakpoints },
    palette: {
      contrastThreshold: 3,
      error: red,
      primary: blue,
      secondary: pink,
      text: {
        primary: grey[700],
      },
    },
    props: {
      MuiPaper: {
        elevation: 6,
      },
    },
    typography: {
      fontWeightMedium: 400,
      fontSize: 16,
      fontFamily: [
        'Roboto',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      htmlFontSize: 10,
    },
    spacing: 4,
  });
};

export interface SortedType {
  uuid: string;
  element: SelectedElement;
}

export type StepRenderElement = {
  order: number;
  element: SortedType;
};

const Editor: React.FC<Props> = (props) => {
  const {
    steps,
    currentStepNumber,
    currentStepUUID,
    setSelectElement,
    setLabelElement,
    setFormInputElement,
    changeToStep,
    setRadioElement,
    setSliderElement,
    theme,
    maxSteps,
    mobileMode,
    meta,
    currentJaybox,
  } = props;
  const [image, takeScreenshot] = useScreenshot();
  const editorContainerRef = React.useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const createCustomBreakpoints = () => {
    const breakpoints = createBreakpoints({});

    // the jaybox knows two breakpoints, smDown and mdUp to switch between mobile and desktop mode
    if (mobileMode) {
      // increase breakpoint for md, lg and xl to prevent desktop mode
      breakpoints.values.md = 99999;
      breakpoints.values.lg = 99999;
      breakpoints.values.xl = 99999;
    } else {
      // decrease breakpoint for sm, xs and md to prevent mobile mode
      breakpoints.values.md = 2;
      breakpoints.values.sm = 1;
      breakpoints.values.xs = 1;
    }

    return breakpoints;
  };
  const userTheme = mainTheme(theme, createCustomBreakpoints());
  const classes = useStyles({ ...props, customTheme: theme });
  const [currentStep, setCurrentStep] = useState<StepStateEntity | null>(null);
  const [cards, setCards] = useState<StepRenderElement[]>([]);
  const previousCards = usePrevious(cards);
  const previousTheme = usePrevious(theme);

  React.useEffect(() => {
    setCurrentStep(getStepByUUID(currentStepUUID, steps));
  }, [currentStepUUID, steps]);

  React.useEffect(() => {
    dispatch(setCurrentlySelectedElement({ element: null }));
  }, [currentStepUUID]);

  useDebouncedEffect(
    () => {
      if (currentStep && currentStep.elementPreviewUrl == null) {
        takeScreenshot(editorContainerRef.current);
        return;
      }

      if (!previousCards || !previousTheme) {
        return;
      }

      if (previousCards.length !== cards.length || previousTheme !== theme) {
        takeScreenshot(editorContainerRef.current);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    1000,
    [theme, cards],
  );

  React.useEffect(() => {
    if (currentJaybox && currentStepUUID) {
      dispatch(savePreview({ boxId: currentJaybox.id, stepUUID: currentStepUUID, base64: image }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  React.useEffect(() => {
    if (currentStep) {
      const newCards: StepRenderElement[] = [];
      currentStep.elements.forEach((element) => {
        switch (element.type) {
          case HTMLType.SELECT:
            element.options.forEach((option) => {
              newCards.push({
                order: option.order || newCards.length,
                element: {
                  uuid: option.uuid,
                  element: { primary: option, secondary: element },
                },
              });
            });
            break;
          case HTMLType.RADIOGROUP:
            element.radio.forEach((radio) => {
              newCards.push({
                order: radio.order || newCards.length,
                element: {
                  uuid: radio.uuid,
                  element: { primary: radio, secondary: element },
                },
              });
            });
            break;
          case HTMLType.FORM:
            element.inputElement.forEach((input) => {
              newCards.push({
                order: input.order || newCards.length,
                element: {
                  uuid: input.uuid,
                  element: { primary: input, secondary: element },
                },
              });
            });
            break;
          case HTMLType.SLIDER:
            newCards.push({
              order: element.order || newCards.length,
              element: {
                uuid: element.uuid,
                element: { primary: element, secondary: null },
              },
            });
            break;
          case HTMLType.LABEL:
            newCards.push({
              order: element.order || newCards.length,
              element: {
                uuid: element.uuid,
                element: { primary: element, secondary: null },
              },
            });
            break;

          default:
            break;
        }
      });
      setCards(newCards);
    }
  }, [currentStep]);

  React.useEffect(() => {
    if (steps.length > 0) {
      changeToStep(steps[0].number, steps[0].uuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateLocalization = (key: string, value: string) => {
    dispatch(setUnsavedChanges({ changes: true }));
    dispatch(
      setLocalization({
        key: key,
        localization: value,
        namespace: 'de',
      }),
    );
  };

  const createSelect = () => {
    const stepElement: StepElementSelectStateEntity = {
      type: HTMLType.SELECT,
      autoForward: true,
      singleSelection: true,
      reportLocalization: '',
      lbIf: null,
      required: false,
      options: [],
      localization: uuidv1(),
      uuid: uuidv1(),
    };
    stepElement.reportLocalization = stepElement.localization;
    return stepElement;
  };

  const createRadiogroup = () => {
    const stepElement: StepElementRadiogroupStateEntity = {
      type: HTMLType.RADIOGROUP,
      autoForward: true,
      singleSelection: true,
      reportLocalization: '',
      lbIf: null,
      required: false,
      radio: [],
      localization: uuidv1(),
      uuid: uuidv1(),
    };
    stepElement.reportLocalization = stepElement.localization;
    return stepElement;
  };

  const createForm = () => {
    const formElement: StepElementFormStateEntity = {
      type: HTMLType.FORM,
      formType: FormType.DEFAULT,
      inputElement: [],
      lbIf: null,
      localization: uuidv1(),
      uuid: uuidv1(),
    };
    return formElement;
  };

  const addSlider = () => {
    if (currentStepUUID === null) {
      return null;
    }
    const newSlider: StepElementSliderStateEntity = {
      type: HTMLType.SLIDER,
      lbIf: null,
      localization: uuidv1(),
      uuid: uuidv1(),
      reportLocalization: '',
      lowerRange: 2,
      defaultValue: 5,
      value: null,
      upperRange: 10,
      unitLocalization: uuidv1(),
      step: 1,
      order: cards.length + 1,
    };
    newSlider.reportLocalization = newSlider.localization;

    updateLocalization(newSlider.unitLocalization, '');
    updateLocalization(newSlider.localization, 'Slider');
    setSliderElement(newSlider, currentStepUUID);
    setCards(
      update(cards, {
        $push: [
          {
            order: newSlider.order || cards.length,
            element: { uuid: uuidv1(), element: { primary: newSlider, secondary: null } },
          },
        ],
      }),
    );
  };

  const addInput = (
    type: HTMLType.EMAIL | HTMLType.TEXT | HTMLType.NUMBER | HTMLType.TEXTAREA | HTMLType.CHECKBOX,
  ) => {
    if (!currentStepUUID) {
      return;
    }

    const defaultObject = {
      localization: uuidv1(),
      uuid: uuidv1(),
      required: false,
      reportLocalization: '',
      errorLocalization: uuidv1(),
      inputValid: true,
      order: cards.length + 1,
    };
    defaultObject.reportLocalization = defaultObject.localization;

    let newElement:
      | StepElementFormInputEmailStateEntity
      | StepElementFormInputTextStateEntity
      | StepElementFormTextAreaStateEntity
      | StepElementFormInputNumberStateEntity
      | StepElementFormInputCheckboxStateEntity;

    switch (type) {
      case HTMLType.EMAIL:
        newElement = {
          ...defaultObject,
          value: '',
          type: HTMLType.EMAIL,
        };
        break;
      case HTMLType.TEXT:
        newElement = {
          ...defaultObject,
          value: '',
          type: HTMLType.TEXT,
        };
        break;
      case HTMLType.NUMBER:
        newElement = {
          ...defaultObject,
          value: null,
          type: HTMLType.NUMBER,
        };
        break;
      case HTMLType.TEXTAREA:
        newElement = {
          ...defaultObject,
          value: '',
          type: HTMLType.TEXTAREA,
        };
        break;
      case HTMLType.CHECKBOX:
        newElement = {
          ...defaultObject,
          value: false,
          type: HTMLType.CHECKBOX,
        };
        break;
    }

    const formGroup = findElementOnCurrentStep(HTMLType.FORM);
    updateLocalization(newElement.localization, 'Eingabe');
    updateLocalization(newElement.errorLocalization, 'Fehler');

    if (formGroup && formGroup.type === HTMLType.FORM) {
      setFormInputElement(formGroup, newElement, currentStepUUID);
      setCards(
        update(cards, {
          $push: [
            {
              order: newElement.order || cards.length,
              element: { uuid: uuidv1(), element: { primary: newElement, secondary: formGroup } },
            },
          ],
        }),
      );
    } else {
      const newForm = createForm();
      if (newForm) {
        setFormInputElement(newForm, newElement, currentStepUUID);
        setCards(
          update(cards, {
            $push: [
              {
                order: newElement.order || cards.length,
                element: { uuid: uuidv1(), element: { primary: newElement, secondary: newForm } },
              },
            ],
          }),
        );
      }
    }
  };

  const handleDrop = (type: DragItem) => {
    switch (type.type) {
      case DraggableType.Option:
        addOption();
        dispatch(setUnsavedChanges({ changes: true }));
        break;
      case DraggableType.Text:
        addInput(HTMLType.TEXT);
        dispatch(setUnsavedChanges({ changes: true }));
        break;
      case DraggableType.Number:
        addInput(HTMLType.NUMBER);
        dispatch(setUnsavedChanges({ changes: true }));
        break;
      case DraggableType.TextArea:
        addInput(HTMLType.TEXTAREA);
        dispatch(setUnsavedChanges({ changes: true }));
        break;
      case DraggableType.Email:
        addInput(HTMLType.EMAIL);
        dispatch(setUnsavedChanges({ changes: true }));
        break;
      case DraggableType.Checkbox:
        addInput(HTMLType.CHECKBOX);
        dispatch(setUnsavedChanges({ changes: true }));
        break;
      case DraggableType.Slider:
        addSlider();
        dispatch(setUnsavedChanges({ changes: true }));
        break;
      case DraggableType.Radio:
        addRadio();
        dispatch(setUnsavedChanges({ changes: true }));
        break;
      case DraggableType.Label:
        addLabel();
        dispatch(setUnsavedChanges({ changes: true }));
        break;

      default:
        break;
    }
  };

  const findElementOnCurrentStep = (type: HTMLType) => {
    if (currentStep && currentStepUUID) {
      const selectGroup = currentStep.elements.filter((element) => element.type === type);
      if (selectGroup && selectGroup.length >= 1 && selectGroup[0].type === type) {
        return selectGroup[0];
      }
    }
    return null;
  };

  const addLabel = () => {
    if (!currentStepUUID) {
      return;
    }

    const newLabel: StepElementLabelStateEntity = {
      localization: uuidv1(),
      titleLocalization: uuidv1(),
      bodyLocalization: uuidv1(),
      buttonLocalization: uuidv1(),
      disableOverlay: false,
      lbIf: null,
      uuid: uuidv1(),
      type: HTMLType.LABEL,
      order: cards.length + 1,
    };

    updateLocalization(newLabel.titleLocalization, 'Hier steht Ihr Infotext');
    updateLocalization(newLabel.bodyLocalization, 'Ihre Infobeschreibung');
    updateLocalization(newLabel.buttonLocalization, 'Ok');

    setLabelElement(newLabel, currentStepUUID);
    setCards(
      update(cards, {
        $push: [
          {
            order: newLabel.order || cards.length,
            element: { uuid: uuidv1(), element: { primary: newLabel, secondary: null } },
          },
        ],
      }),
    );
  };

  const addOption = () => {
    if (!currentStepUUID) {
      return;
    }

    const newOption: StepElementOptionStateEntity = {
      url: null,
      deactivated: false,
      localization: uuidv1(),
      value: false,
      uuid: uuidv1(),
      type: HTMLType.OPTION,
      order: cards.length + 1,
    };

    const selectGroup = findElementOnCurrentStep(HTMLType.SELECT);
    updateLocalization(newOption.localization, 'Option');

    if (selectGroup && selectGroup.type === HTMLType.SELECT) {
      setSelectElement(selectGroup, newOption, currentStepUUID);
      setCards(
        update(cards, {
          $push: [
            {
              order: newOption.order || cards.length,
              element: { uuid: uuidv1(), element: { primary: newOption, secondary: selectGroup } },
            },
          ],
        }),
      );
    } else {
      const newSelect = createSelect();
      if (newSelect) {
        setSelectElement(newSelect, newOption, currentStepUUID);
        setCards(
          update(cards, {
            $push: [
              {
                order: newOption.order || cards.length,
                element: { uuid: uuidv1(), element: { primary: newOption, secondary: newSelect } },
              },
            ],
          }),
        );
      }
    }
  };

  const addRadio = () => {
    if (!currentStepUUID) {
      return;
    }

    const newRadio: StepElementRadioStateEntity = {
      deactivated: false,
      localization: uuidv1(),
      value: false,
      uuid: uuidv1(),
      type: HTMLType.RADIOINPUT,
      order: cards.length + 1,
    };

    const radioGroup = findElementOnCurrentStep(HTMLType.RADIOGROUP);
    updateLocalization(newRadio.localization, 'Radio');

    if (radioGroup && radioGroup.type === HTMLType.RADIOGROUP) {
      setRadioElement(radioGroup, newRadio, currentStepUUID);
      setCards(
        update(cards, {
          $push: [
            {
              order: newRadio.order || cards.length,
              element: { uuid: uuidv1(), element: { primary: newRadio, secondary: radioGroup } },
            },
          ],
        }),
      );
    } else {
      const newRadiogroup = createRadiogroup();
      if (newRadiogroup) {
        setRadioElement(newRadiogroup, newRadio, currentStepUUID);
        setCards(
          update(cards, {
            $push: [
              {
                order: newRadio.order || cards.length,
                element: {
                  uuid: uuidv1(),
                  element: { primary: newRadio, secondary: newRadiogroup },
                },
              },
            ],
          }),
        );
      }
    }
  };

  const moveCard = React.useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = cards[dragIndex];
      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      );
    },
    [cards],
  );

  const renderCard = (card: SortedType, index: number) => {
    return (
      <Card
        key={card.uuid}
        index={index}
        id={card.uuid}
        moveCard={moveCard}
        element={card.element}
        stepUUID={currentStepUUID || ''}
        meta={meta}
      />
    );
  };

  const getContent = () => {
    return (
      <Grid item container xs={12} style={{ height: '100%' }}>
        <Grid item container xs={12} className={classes.cardContent} alignContent="flex-start">
          {cards
            .sort((elementA, elementB) => elementA.order - elementB.order)
            .map((card, i) => renderCard(card.element, i))}
          <Grid item container xs={12} alignContent="center" style={{ minHeight: '80%' }}>
            <DropBox onDrop={handleDrop} hideLabel={cards.length !== 0} />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const getHeader = () => {
    if (currentStep) {
      const title = currentStep.elements.find((element) => element.type === HTMLType.TITLE);
      if (title) {
        return (
          <ClickArea ownElement={{ primary: title, secondary: null }}>
            <Header
              steps={steps}
              currentStepNumber={currentStepNumber}
              currentStepUUID={currentStepUUID}
            />
          </ClickArea>
        );
      }
    }
    return null;
  };

  const getFooter = () => {
    if (currentStep) {
      return (
        <Footer
          nextStep={() => ({})}
          previousStep={() => ({})}
          setFormInputElement={() => ({})}
          navigatedSteps={[]}
          meta={{ customerEmail: '', customerId: '', customerTemplate: '', layout: 'vertical' }}
          maxSteps={maxSteps}
          steps={steps}
          currentStepNumber={currentStepNumber}
          currentStepUUID={currentStepUUID}
        />
      );
    }
    return null;
  };

  const verticalLayout = () => {
    return (
      <Grid container alignItems="flex-end" justify="flex-start" className={classes.container}>
        <Grid item xs={12} className={classes.progressBar}>
          <ClickArea
            ownElement={{
              primary: { lbIf: null, localization: '', uuid: '', type: 'PROGRESS_BAR' },
              secondary: null,
            }}
          >
            <ProgressBar maxSteps={maxSteps} currentStep={currentStepNumber} />
          </ClickArea>
        </Grid>
        <Hidden smDown>
          <Grid item xs={6} className={`${classes.content} ${classes.mainContainer}`}>
            {getContent()}
          </Grid>
          <Grid item xs={6} className={classes.header}>
            {getHeader()}
          </Grid>
          <Grid item xs={6} className={classes.footer}>
            {getFooter()}
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={12} className={classes.header}>
            {getHeader()}
          </Grid>
          <Grid item xs={12} className={`${classes.content} ${classes.mainContainer}`}>
            {getContent()}
          </Grid>
          <Grid item xs={12} className={classes.footer}>
            {getFooter()}
          </Grid>
        </Hidden>
      </Grid>
    );
  };

  const horizontalLayout = () => {
    return (
      <Grid container alignItems="flex-end" justify="flex-start" className={classes.container}>
        <Grid item xs={12} className={classes.progressBar}>
          <ClickArea
            ownElement={{
              primary: { lbIf: null, localization: '', uuid: '', type: 'PROGRESS_BAR' },
              secondary: null,
            }}
          >
            <ProgressBar maxSteps={maxSteps} currentStep={currentStepNumber} />
          </ClickArea>
        </Grid>
        <Grid item xs={12} className={classes.header}>
          {getHeader()}
        </Grid>
        <Grid item xs={12} className={`${classes.content} ${classes.mainContainer}`}>
          {getContent()}
        </Grid>
        <Grid item xs={12} className={classes.footer}>
          {getFooter()}
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container alignItems="flex-start" justify="flex-start" className={classes.root}>
      <Grid item container alignItems="center" xs={12} className={classes.title}>
        <Typography variant="body1" className={classes.titleText}>
          {currentJaybox ? currentJaybox.name : ''}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.mainContent} ref={editorContainerRef}>
        <I18nextProvider i18n={i18n}>
          <MuiThemeProvider theme={userTheme}>
            {meta.layout === 'vertical' ? verticalLayout() : horizontalLayout()}
          </MuiThemeProvider>
        </I18nextProvider>
      </Grid>
    </Grid>
  );
};

export default Editor;
