/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-04-15
 * @author Tim Lange <tl@systl.de>
 */

export const DASHBOARD_PATH = '/';
export const CREATE_NEW_PATH = '/create';
export const BOX_DETAIL = '/:id';
export const BOX_CONFIG = '/:id/config';
