/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-06-22
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies

// Data models
import { FireStoreError } from 'models/firebase';
import { RequestStatus } from 'models/common';
import { Permission } from 'models/permission';

export enum PermissionActionType {
  SET_GRANTED_PERMISSIONS = '@@permissions/SET_GRANTED_PERMISSIONS',
  SET_CURRENT_WORKSPACE = '@@permissions/SET_CURRENT_WORKSPACE',
  LOAD_PERMISSIONS_STATUS = '@@permissions/LOAD_PERMISSIONS_STATUS',
  UPDATE_GRANTED_PERMISSIONS_UNSUBSCRIBE = '@@permissions/UPDATE_GRANTED_PERMISSIONS_UNSUBSCRIBE',
}

export interface SetCurrentWorkspace {
  workspaceId: string | null;
  type: PermissionActionType.SET_CURRENT_WORKSPACE;
}

export interface SetGrantedPermissionsAction {
  grantedPermissions: Permission[];
  type: PermissionActionType.SET_GRANTED_PERMISSIONS;
}

export interface LoadPermissionStatusAction {
  error: FireStoreError;
  status: RequestStatus;
  type: PermissionActionType.LOAD_PERMISSIONS_STATUS;
}

export interface UpdateGrantedPermissionsUnsubscribeAction {
  unsubscribe: () => void;
  type: PermissionActionType.UPDATE_GRANTED_PERMISSIONS_UNSUBSCRIBE;
}

export type PermissionAction =
  | LoadPermissionStatusAction
  | SetGrantedPermissionsAction
  | UpdateGrantedPermissionsUnsubscribeAction
  | SetCurrentWorkspace;
