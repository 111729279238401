/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-04-15
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { FC } from 'react';

// Own Components
import NoBoxes from 'components/dashboard/noBoxes/NoBoxes';

// Data Models
import { StateProps } from './propTypes';

// Utils
import { useSelector } from 'react-redux';
import { ApplicationState } from 'models/store';

// Assets
import Parrot from 'assets/img/jaybox_box_creator_parrot.svg';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    minHeight: '100%',
    paddingTop: '3rem',
  },
  container: {
    height: '100%',
    minHeight: '100%',
  },
}));

const Overview: FC = () => {
  const { availableJayboxes } = useSelector<ApplicationState, StateProps>((state) => ({
    availableJayboxes: state.boxes.availableJayboxes,
  }));
  const classes = useStyles();

  const getContent = () => {
    if (availableJayboxes.length === 0) {
      return <NoBoxes />;
    }

    return (
      <Grid container justify="center" className={classes.container}>
        <Grid item xs={12}>
          <img src={Parrot} alt="no jaybox" style={{ height: '100%', width: '100%' }} />
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container className={classes.root} justify="center" alignContent="flex-start">
      <Grid item xs style={{ height: '100%' }}>
        {getContent()}
      </Grid>
    </Grid>
  );
};

export default Overview;
