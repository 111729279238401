/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-02-25
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { connect, MapStateToProps } from 'react-redux';

// Presentational component
import StyleBar from './StyleBar';

// Data models
import { StateProps } from './propTypes';
import { ApplicationState } from 'models/store';

const mapStateToProps: MapStateToProps<StateProps, {}, ApplicationState> = (state) => ({
  selectedElement: state.configuration.selectedElement,
  currentStepUUID: state.jaybox.stepData.currentStepUUID,
  steps: state.jaybox.stepData.steps,
  style: state.configuration.style,
});

export default connect(mapStateToProps)(StyleBar);
