/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-04-16
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { FC, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { namespacedAction } from 'redux-subspace';

// Own Components
import Editor from 'components/configurator/editor/EditorContainer';
import FlowChart from 'components/configurator/flowchart/FlowChartContainer';

// Data Models
import { RequestStatus } from 'models/common';
import { MetaStateEntity } from 'jaybox/dist/models/stepdata';
import { StateProps, OwnProps } from './propTypes';

// Utils
import { parseFile } from 'jaybox/dist/utils/XMLParser';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from 'models/store';

// Action Creator
import { setConfigLoaded } from 'store/configuration/configurationSlice';
import { checkForRequiredSteps, setCurrentJaybox } from 'store/boxes/boxesSlice';
import { loadConfiguration, setConfigFilesLoaded } from 'store/config/configSlice';
import {
  deleteElement,
  addStepData,
  setMaxSteps,
  setMetaData,
} from 'jaybox/dist/store/stepdata/actions';

// Loader
import LoadingSpinner from 'components/common/loading-spinner/LoadingSpinner';
import { RootState } from 'store';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    minHeight: '100%',
    //minHeight: '100vh',
  },
  loader: {
    textAlign: 'center',
  },
  main: {
    height: '100%',
  },
  header: {
    //height: '10vh',
    height: '10%',
  },
  editor: {
    //height: '75vh',
    height: 'calc(100% - 14.5rem)',
    backgroundColor: '#F2F2F2',
  },
  content: {
    //height: '75vh',
    height: '100%',
  },
  footer: {
    //height: '15vh',
    height: '14.5rem',
  },
}));

const Configurator: FC<OwnProps> = (props) => {
  const { jayboxId } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    availableBoxes,
    requestDataStatus,
    user,
    currentJaybox,
    steps,
    xml,
    configLoaded,
    workspaceId,
  } = useSelector<ApplicationState, StateProps>((state) => ({
    availableBoxes: state.boxes.availableJayboxes,
    requestDataStatus: state.config.requestState,
    user: state.user.profile,
    currentJaybox: state.boxes.currentJaybox,
    steps: state.jaybox.stepData.steps,
    xml: state.configuration.xml,
    configLoaded: state.configuration.configLoaded,
    workspaceId: state.permission.currentWorkspaceId,
  }));
  const configurationFilesLoaded = useSelector(
    (state: RootState) => state.config.configurationFilesLoaded,
  );
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      //dispatch(setConfigLoaded({ configLoaded: false }));
      // dispatch(setConfigFilesLoaded({ configurationFilesLoaded: false }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const currentBox = availableBoxes.find((box) => box.id === jayboxId);
    if (currentBox) {
      dispatch(setCurrentJaybox({ jaybox: currentBox }));
    }
  }, [availableBoxes, dispatch, jayboxId]);

  useEffect(() => {
    if ((user || workspaceId) && currentJaybox) {
      dispatch(
        loadConfiguration({
          userId: workspaceId ? workspaceId : user ? user.uid : '',
          boxId: currentJaybox.id,
        }),
      );
      dispatch(namespacedAction('@@jaybox')(setMetaData(currentJaybox.metaData)));
    }
  }, [currentJaybox, dispatch, user, workspaceId]);

  useEffect(() => {
    if (
      !configLoaded &&
      configurationFilesLoaded &&
      (requestDataStatus === RequestStatus.IDLE || requestDataStatus === RequestStatus.ERROR)
    ) {
      initBox();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configLoaded, requestDataStatus]);

  const initBox = useCallback(() => {
    if (requestDataStatus === RequestStatus.IDLE) {
      if (steps.length !== 0) {
        steps.forEach((step) => {
          dispatch(namespacedAction('@@jaybox')(deleteElement(step.uuid)));
        });
        dispatch(namespacedAction('@@jaybox')(setMaxSteps(0)));
      }
      const parsedConfig = parseFile(xml);
      let maxStep = 0;
      // TODO: refactor when conditional questions inserted
      parsedConfig.steps.forEach((step) => {
        // if (step.number > maxStep) {
        //   maxStep = step.number;
        // }
        maxStep += 1;
        dispatch(namespacedAction('@@jaybox')(addStepData(step)));
      });
      dispatch(namespacedAction('@@jaybox')(setMaxSteps(maxStep)));

      dispatch(checkForRequiredSteps());
    } else if (requestDataStatus === RequestStatus.ERROR) {
      const newMeta: MetaStateEntity = {
        customerId: user ? user.uid : '',
        customerEmail: '',
        customerTemplate: '',
        layout: 'vertical',
      };
      dispatch(namespacedAction('@@jaybox')(setMetaData(newMeta)));
    }
    dispatch(setConfigLoaded({ configLoaded: true }));
  }, [dispatch, requestDataStatus, steps, user, xml]);

  const getContent = () => {
    if (!configLoaded || requestDataStatus !== RequestStatus.IDLE) {
      return (
        <Grid container alignItems="center" justify="center" className={classes.main}>
          <Grid item xs={12} className={classes.loader}>
            <LoadingSpinner />
            <Typography align="center" variant="h4">
              {t('common.appLoading')}
            </Typography>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid item container xs className={classes.main}>
          <Grid item container xs={12} className={classes.editor}>
            <Grid item xs={12} style={{ padding: '0 10rem 5rem', height: '100%' }}>
              <Editor />
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.footer}>
            <FlowChart />
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <Grid container className={classes.root} justify="center" alignContent="center">
      <Grid item xs style={{ height: '100%' }}>
        {getContent()}
      </Grid>
    </Grid>
  );
};

export default Configurator;
