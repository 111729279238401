/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-02-19
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies

export enum Functions {
  DEPLOY = 'deploy',
}

export type Environment = 'dev' | 'stage' | 'prod';

export interface DeployPayload {
  boxId: string;
  userId: string;
  environment: Environment;
}

export interface DeployResponsePayload {
  message: string;
  jobId: string;
}

export interface AuthResponsePayload {
  message: `Success`;
  payload: string;
}

export interface JobStatusResponsePayload {
  message: `Success`;
  payload: { progress: number; done: boolean };
}
