/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-06-22
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies

// Data models
import { FireStoreError } from 'models/firebase';
import { RequestStatus } from 'models/common';
import { Reducer } from 'redux';
import { PermissionAction, PermissionActionType } from 'models/permission/actions';
import { PermissionState } from 'models/permission/state';

export const initialState: PermissionState = {
  currentWorkspaceId: null,
  loadPermissionsError: FireStoreError.NONE,
  loadPermissionsStatus: RequestStatus.IDLE,
  updateGrantedPermissionsUnsubscribe: () => {},
  grantedPermissions: [],
};

const permissionReducer: Reducer<PermissionState, PermissionAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case PermissionActionType.LOAD_PERMISSIONS_STATUS: {
      return {
        ...state,
        loadPermissionsError: action.error,
        loadPermissionsStatus: action.status,
      };
    }
    case PermissionActionType.SET_CURRENT_WORKSPACE: {
      return {
        ...state,
        currentWorkspaceId: action.workspaceId,
      };
    }
    case PermissionActionType.SET_GRANTED_PERMISSIONS: {
      return {
        ...state,
        grantedPermissions: action.grantedPermissions,
      };
    }
    case PermissionActionType.UPDATE_GRANTED_PERMISSIONS_UNSUBSCRIBE: {
      return {
        ...state,
        updateGrantedPermissionsUnsubscribe: action.unsubscribe,
      };
    }
    default: {
      return state;
    }
  }
};

export default permissionReducer;
