/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-03-12
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import 'firebase/storage';
import * as firebase from 'firebase/app';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// Data models
import { AuthError } from 'models/auth';

// Action Creator
import { cleanUp as boxCleanUp } from 'store/boxes/boxesSlice';
// import { cleanUp as commonCleanUp } from 'store/common/actions';
// import { cleanUp as invoiceCleanUp } from 'store/invoice/invoiceSlice';
// import { cleanUp as loginCleanUp } from 'store/login/loginSlice';
// import { cleanUp as methodCleanUp } from 'store/payment/methodSlice';
// import { cleanUp as notificationCleanUp } from 'store/notification/notificationSlice';
// import { cleanUp as permissionCleanUp } from 'store/permission/permissionSlice';
// import { cleanUp as priceCleanUp } from 'store/payment/priceSlice';
// import { cleanUp as signUpCleanUp } from 'store/signup/actions';
// import { cleanUp as subscriptionCleanUp } from 'store/payment/subscriptionSlice';
// import { triggerNotification } from 'store/notification/notificationSlice';
// import { cleanUp as userCleanUp } from 'store/user/userSlice';

//Utils
import { createAppThunk } from 'utils/appActions';

const sliceName = '@@auth';

export interface AuthState {
  authError: AuthError;
  configuratorRedirectUrl: string;
  isAuthenticated: boolean;
  redirectUrl: string;
  user?: firebase.User;
}

export interface SetRedirectUrlPayload {
  url: string;
}

export interface UpdateAuthDataPayload {
  error: AuthError;
  isAuthenticated: boolean;
  user?: firebase.User;
}

export const initialState: AuthState = {
  authError: AuthError.NONE,
  configuratorRedirectUrl: '',
  isAuthenticated: false,
  redirectUrl: '/dashboard',
  user: undefined,
};

export const prepareLogout = createAppThunk(
  sliceName + '/prepareLogout',
  async (_, { dispatch }) => {
    try {
      await Promise.all([
        dispatch(boxCleanUp()),
        // dispatch(invoiceCleanUp()),
        // dispatch(loginCleanUp()),
        // dispatch(methodCleanUp()),
        // dispatch(notificationCleanUp()),
        // dispatch(priceCleanUp()),
        // dispatch(subscriptionCleanUp()),
        // dispatch(userCleanUp()),
        // dispatch(permissionCleanUp()),
      ]);

      await dispatch(cleanUp());
      await firebase.firestore().clearPersistence();
    } catch (err) {}
  },
);

export const signOut = createAppThunk(sliceName + '/signOut', async (_, { dispatch }) => {
  try {
    await dispatch(prepareLogout());
  } catch (err) {
  } finally {
    firebase
      .auth()
      .signOut()
      .then(() => {});
  }
});

export const cleanUp = createAppThunk(sliceName + '/cleanUp', async (_, { dispatch }) => {
  try {
    await dispatch(init());
  } catch (err) {}
});

const authSlice = createSlice({
  extraReducers: (builder) => {},
  initialState,
  name: sliceName,
  reducers: {
    init: () => initialState,
    setRedirectUrl(state, action: PayloadAction<SetRedirectUrlPayload>) {
      state.redirectUrl = action.payload.url;
    },
    updateAuthData(state, action: PayloadAction<UpdateAuthDataPayload>) {
      state.authError = action.payload.error;
      state.user = action.payload.user;
      state.isAuthenticated = action.payload.isAuthenticated;
    },
  },
});

export const { init, setRedirectUrl, updateAuthData } = authSlice.actions;

export default authSlice.reducer;
