/**
 * FlowChart component
 *
 * @copyright 2020 Emden Consulting GmbH
 * @created 2019-12-13
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Grid, Button, Box, Divider, Collapse, IconButton } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

// Assets
import { ReactComponent as CloseIcon } from 'assets/img/jaybox_close.svg';

// Own config
import * as colors from 'themes/colors';

// Own components
import DeleteOverlay from 'components/configurator/sidebar/deleteOverlay/DeleteOverlay';
import AddStepHover from './addStepHover';

// Data models
import { Props } from './propTypes';
import { StepStateEntity } from 'jaybox/dist/models/stepdata';
import { SelectedElement } from 'models/configuration';

// Styles
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100%',
    backgroundColor: '#F2F2F2',
  },
  full: {
    width: '100%',
    height: '100%',
  },
  icon: {
    // width: '2.8rem',
    // height: '2.3rem',
  },
  stepContainer: {
    width: 'calc(100% - 20rem)',
    height: '100%',
    textAlign: 'left',
  },
  stepWrapper: {
    width: '100%',
    height: '100%',
    overflowX: 'hidden',
    paddingLeft: '1.5rem',
  },
  expandButton: {
    color: theme.palette.primary.contrastText,
    width: theme.spacing(26.5),
    borderRadius: 0,
    height: '3.2rem',
    backgroundColor: '#333333',
    '&:hover': {
      backgroundColor: '#333333',
    },
  },
  arrowContainer: {
    width: '10rem',
    height: '100%',
  },
  arrow: {
    color: theme.palette.primary.contrastText,
    width: '8rem',
    height: '100%',
  },
  expandWrapper: {
    width: '100%',
    textAlign: 'center',
  },
  expandedContainer: {
    backgroundColor: colors.GREY80,
  },
  divider: {
    height: '1px',
    border: '2px solid #00A3FF',
    margin: '0 10rem',
  },
  label: {
    padding: '1rem',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textTransform: 'capitalize',
    overflow: 'hidden',
    display: 'inline',
  },
  buttonWrapper: {
    position: 'relative',
    '&:not(:last-child)': {
      marginRight: theme.spacing(7),
    },
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  leftArrowButton: {
    paddingRight: theme.spacing(8),
    width: 0,
  },
  rightArrowButton: {
    paddingLeft: theme.spacing(8),
    width: 0,
  },
  previewImage: {
    width: '100%',
    position: 'absolute',
    height: '100%',
    left: 0,
  },
  buttonRoot: {
    flexShrink: 0,
    borderRadius: 0,
    width: theme.spacing(29),
    height: '6rem',
    color: 'black',
  },
  addButtonRoot: {
    borderRadius: 0,
    backgroundColor: '#78C8C5',
    width: '5rem',
    minWidth: '5rem',
    color: 'white',
    height: '6rem',
    margin: '0 1rem',
    '&:hover': {
      backgroundColor: '#78C8C5',
    },
  },
  lastButtonRoot: {
    borderRadius: 0,
    color: 'black',
    backgroundColor: 'white',
    width: '15rem',
    minWidth: '15rem',
    height: '6rem',
    margin: '0 1rem',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  closeButton: {
    padding: '0',
    position: 'absolute',
    right: theme.spacing(2),
    // width: '1.6rem',
    // height: '1.6rem',
    top: theme.spacing(2),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiSvgIcon-root': {
      width: '1.6rem',
      height: '1.6rem',
    },
  },
  iconLabel: {
    width: '100%',
    height: '100%',
  },
}));

const FlowChart: React.FC<Props> = (props) => {
  const { steps, maxSteps, changeToStep, currentStepUUID } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(true);
  const stepRef = React.useRef<HTMLDivElement>(null);
  const [overlayOpen, setOverlayOpen] = React.useState(false);
  const [elementToDelete, setElementToDelete] = React.useState<SelectedElement | null>(null);
  const theme = useTheme();

  const handleChangeStepClick = (stepNumber: number, stepUUID: string) => {
    changeToStep(stepNumber, stepUUID);
  };

  const getHeaderText = (step: StepStateEntity): string => {
    return `${t('editor.footer.step')} ${step.number + 1}`;
    // const title = step.elements.find((element) => element.type === HTMLType.TITLE);
    // if (title && title.type === HTMLType.TITLE) {
    //   return `${step.number + 1}. ${userTrans(title.localization)}`;
    // } else {
    //   return `${step.number + 1}.`;
    // }
  };

  const handleDelete = () => {
    setOverlayOpen(true);
  };

  const expandedContainer = () => {
    return (
      <Grid container alignItems="center" className={classes.expandedContainer}>
        <Grid
          item
          container
          xs={12}
          className={classes.full}
          direction="row"
          alignItems="center"
          wrap="nowrap"
        >
          <Box style={{ textAlign: 'right' }} className={classes.arrowContainer}>
            <IconButton
              className={classes.leftArrowButton}
              onClick={() => {
                if (stepRef.current) {
                  stepRef.current.scrollLeft -= 20;
                }
              }}
            >
              <ArrowLeftIcon className={classes.arrow} />
            </IconButton>
          </Box>
          <Box className={classes.stepContainer}>
            <Grid
              className={classes.stepWrapper}
              item
              container
              xs={12}
              alignContent="center"
              ref={stepRef}
              wrap="nowrap"
            >
              {steps.map((step) => {
                return (
                  <Box key={`step-¢${step.uuid}`} className={classes.buttonWrapper}>
                    {step.elementPreviewUrl ? (
                      <img
                        className={classes.previewImage}
                        src={step.elementPreviewUrl}
                        alt={'Screenshot'}
                      />
                    ) : null}
                    <Button
                      onClick={() => {
                        handleChangeStepClick(step.number, step.uuid);
                      }}
                      style={
                        currentStepUUID === step.uuid
                          ? { border: `1px solid ${theme.palette.primary.main}` }
                          : { border: `1px solid transparent` }
                      }
                      classes={{ root: classes.buttonRoot, label: classes.label }}
                    >
                      {getHeaderText(step)}
                    </Button>

                    {maxSteps > 1 && step.stepType === 'default' ? (
                      <IconButton
                        style={{}}
                        className={classes.closeButton}
                        classes={{ label: classes.iconLabel }}
                        edge="start"
                        arial-label="close-button"
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                        onClick={() => {
                          setElementToDelete({ primary: step, secondary: null });
                          handleDelete();
                        }}
                      >
                        <CloseIcon className={classes.icon} />
                      </IconButton>
                    ) : null}
                    {step.stepType === 'default' || step.stepType === 'send' ? (
                      <AddStepHover precending hoveredStepNumber={step.number} style={{}} />
                    ) : null}
                    {step.stepType === 'default' ? (
                      <AddStepHover hoveredStepNumber={step.number} style={{}} />
                    ) : null}
                  </Box>
                );
              })}
            </Grid>
          </Box>
          <Box style={{ textAlign: 'left' }} className={classes.arrowContainer}>
            <IconButton
              className={classes.rightArrowButton}
              onClick={() => {
                if (stepRef.current) {
                  stepRef.current.scrollLeft += 20;
                }
              }}
            >
              <ArrowRightIcon className={classes.arrow} />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container alignItems="flex-end" className={classes.container}>
      <Grid
        item
        container
        xs={12}
        justify="center"
        className={classes.full}
        direction="row"
        alignItems="flex-end"
        wrap="wrap"
      >
        <Box className={classes.expandWrapper}>
          <Button
            variant="contained"
            onClick={() => {
              setExpanded(!expanded);
            }}
            className={classes.expandButton}
          >
            {t('editor.footer.steps')}
          </Button>
          <Divider className={classes.divider} />
          <Collapse in={expanded}>{expandedContainer()}</Collapse>
        </Box>
      </Grid>
      <Box>
        {overlayOpen && elementToDelete ? (
          <DeleteOverlay
            elementToDelete={elementToDelete}
            closedCallback={() => {
              setElementToDelete(null);
              setOverlayOpen(false);
            }}
          />
        ) : null}
      </Box>
    </Grid>
  );
};

export default FlowChart;
