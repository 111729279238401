/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-03-12
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies

export enum AuthError {
  NONE = 'auth/none',
  INVALID_USER_TOKEN = 'auth/invalid-user-token',
  NETWORK_REQUEST_FAILED = 'auth/network-request-failed',
  REQUIRES_RECENT_LOGIN = 'auth/requires-recent-login',
  USER_DISABLED = 'auth/user-disabled',
  USER_TOKEN_EXPIRED = 'auth/user-token-expired',
}

export enum PasswordStrength {
  WEAK = 1,
  MEDIUM = 2,
  STRONG = 3,
}
