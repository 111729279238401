/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-02-13
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Grid } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { namespacedAction } from 'redux-subspace';

// Own Components
import JayboxTextfield from 'components/common/text-field';

// Data models
import { Props } from './propTypes';
import { StepElementOptionStateEntity } from 'jaybox/dist/models/stepdata';

// Utils
import { HTMLType } from 'jaybox/dist/models/HTMLElements';

// Action Creator
import { RootState, useAppDispatch } from 'store';
import { setLocalization, setUnsavedChanges } from 'store/configuration/configurationSlice';
import { setSelectElement } from 'jaybox/dist/store/stepdata/actions';
import { useSelector } from 'react-redux';

// Styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      width: '100%',
    },
    content: {
      padding: '1rem 1rem',
      width: '100%',
      backgroundColor: '#D1D1D1',
    },
    itemWrapper: {
      '&:not(:first-child)': {
        paddingTop: theme.spacing(7),
      },
    },
  }),
);

type AttributeOption = {
  key: 'localization' | keyof StepElementOptionStateEntity;
  localization: string;
  value: null | number | string | boolean;
  type: 'text' | 'number';
  multiline: boolean;
};

const OptionForm: React.FC<Props> = (props) => {
  const { selectedStoreElement, currentStepUUID, selectedElement } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const localization = useSelector((root: RootState) => root.configuration.localization);

  const updateOption = <K extends keyof StepElementOptionStateEntity>(
    key: K,
    value: StepElementOptionStateEntity[K],
  ) => {
    if (
      currentStepUUID &&
      selectedElement &&
      selectedElement.secondary &&
      selectedElement.secondary.type === HTMLType.SELECT
    ) {
      const update = { ...selectedStoreElement };
      update[key] = value;
      dispatch(setUnsavedChanges({ changes: true }));
      dispatch(
        namespacedAction('@@jaybox')(
          setSelectElement(selectedElement.secondary, update, currentStepUUID),
        ),
      );
    }
  };

  const updateLocalization = (value: string) => {
    if (selectedStoreElement && selectedStoreElement['localization']) {
      dispatch(setUnsavedChanges({ changes: true }));
      dispatch(
        setLocalization({
          key: selectedStoreElement['localization'],
          localization: value,
          namespace: 'de',
        }),
      );
    }
  };

  const getInputField = (attribute: AttributeOption) => {
    return (
      <Grid container key={attribute.key} className={classes.itemWrapper}>
        <Grid item xs={12}>
          <JayboxTextfield
            jayboxVariant="medium"
            label={attribute.localization}
            id={attribute.key}
            multiline={attribute.multiline}
            rows="3"
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              if (attribute.key === 'localization') {
                updateLocalization(event.target.value);
              } else {
                updateOption(attribute.key, event.target.value);
              }
            }}
            value={attribute.value || ''}
            type={attribute.type}
            variant="outlined"
            fullWidth={true}
          />
        </Grid>
      </Grid>
    );
  };

  const attributes: AttributeOption[] = [
    {
      key: 'localization',
      localization: t('toolbar.localization'),
      value: localization.de[selectedStoreElement['localization']] || '',
      type: 'text',
      multiline: true,
    },
    {
      key: 'url',
      localization: t('toolbar.url'),
      value: selectedStoreElement.url,
      type: 'text',
      multiline: false,
    },
  ];

  return (
    <Grid container alignItems="center" className={classes.container}>
      {attributes.map((attribute) => getInputField(attribute))}
    </Grid>
  );
};

export default OptionForm;
