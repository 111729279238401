/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-03-27
 * @author Tim Lange <tl@systl.de>
 */

// Third party
import fetch from 'isomorphic-fetch';
import * as firebase from 'firebase/app';

// Action creator
import { updateJaybox } from 'store/boxes/boxesSlice';

// Models
import {
  Functions,
  DeployPayload,
  DeployResponsePayload,
  AuthResponsePayload,
} from 'models/firebase/functions';
import { Jaybox, BuildProgress } from 'models/boxes';

// Environment
import { BACKEND_URL, BUILD_SERVER_URL } from 'config/env';
import { ThunkDispatch } from 'redux-thunk';
import { ApplicationAction } from 'models/store';

// Store
import { store } from 'store';

// Utils
import { getTargetProfileId } from 'utils/userUtils';
import { getEnvironment } from 'utils/environment';

export const postSessionRequest = (
  idToken: string,
): Promise<{ message: string; payload: AuthResponsePayload }> => {
  return fetch(BACKEND_URL + '/jayboxApp/auth', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${idToken}`,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

const updateJayboxBuildProgress = (updates: Partial<BuildProgress>, jaybox: Jaybox) => {
  return async (dispatch: ThunkDispatch<{}, {}, ApplicationAction>): Promise<void> => {
    const currentBox = store.getState().boxes.availableJayboxes.find((box) => box.id === jaybox.id);
    if (currentBox) {
      const update: Jaybox = {
        ...currentBox,
        buildProgress: {
          ...currentBox.buildProgress,
          ...updates,
        },
      };
      dispatch(updateJaybox({ box: update }));
    }
  };
};

export const checkJobState = (jobId: string, jaybox: Jaybox) => {
  return async (dispatch: ThunkDispatch<{}, {}, ApplicationAction>): Promise<void> => {
    try {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        if (jobId === '') {
          return;
        }
        const idToken = await currentUser.getIdToken();
        await fetch(BUILD_SERVER_URL + `/${Functions.DEPLOY}/${jobId}/${getEnvironment()}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        }).then((response) => {
          if (response.ok) {
            response.json().then((body) => {
              const updates: Partial<BuildProgress> = {
                progress: body.payload.progress,
                done: body.payload.done,
                running: !body.payload.done,
              };

              dispatch(updateJayboxBuildProgress(updates, jaybox));
            });
          } else {
            throw Error('Error in job status response');
          }
        });
      } else {
        throw Error('User not set while checking job state');
      }
    } catch (error) {
      console.log(error);
      dispatch(updateJayboxBuildProgress({ running: false, progress: 0, done: true }, jaybox));
    }
  };
};

export const triggerDeployment = (jaybox: Jaybox) => {
  return async (dispatch: ThunkDispatch<{}, {}, ApplicationAction>): Promise<void> => {
    try {
      const permissionUserId = getTargetProfileId(store.getState().permission.grantedPermissions);

      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        const idToken = await currentUser.getIdToken();

        const data: DeployPayload = {
          boxId: jaybox.id,
          userId: permissionUserId || currentUser.uid,
          environment: getEnvironment(),
        };
        await fetch(BUILD_SERVER_URL + `/${Functions.DEPLOY}`, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              return null;
            }
          })
          .then((res: DeployResponsePayload | null) => {
            if (res) {
              dispatch(
                updateJayboxBuildProgress({ jobId: res.jobId, running: true, progress: 0 }, jaybox),
              );
            }
          });
      } else {
        throw Error('Can not start build process because firebase user invalid');
      }
    } catch (error) {
      console.log(error);
      dispatch(updateJayboxBuildProgress({ running: false }, jaybox));
    }
  };
};
