/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-02-25
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Grid, IconButton, Theme, Typography, Box } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import moment from 'moment';

// Data Models
import { Props } from './propTypes';

// Own Components
import JayboxButton from 'components/common/button';

// Action Creator
import { setUnsavedChanges } from 'store/configuration/configurationSlice';
import { saveCheckJobStateInterval, updateJaybox } from 'store/boxes/boxesSlice';
import { saveConfiguration } from 'store/config/configSlice';

// Assets
import { Jaybox } from 'models/boxes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      backgroundColor: '#333333',
    },
    full: {
      height: '100%',
      width: '100%',
    },
    icon: {
      '&:hover': {
        color: '#0C5DE8',
      },
      color: 'white',
      width: '100%',
      height: '100%',
    },
    iconSelected: {
      '&:hover': {
        color: '#0C5DE8',
      },
      color: '#00A3FF',
      width: '100%',
      height: '100%',
    },
    iconLabel: {
      display: 'contents',
    },
    iconButton: {
      height: '100%',
      margin: '0',
      padding: '0',
      justifyContent: 'flex-end',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    buttonGroup: {
      height: 'calc(100% - 1rem)',
      padding: '0.5rem 0',
    },
    groupedHorizontal: {
      '&:not(:last-child)': {
        borderRight: '1px solid #1A1A1A',
      },
    },
    button: {
      padding: '0',
      height: '100%',
      backgroundColor: '#18C4A8',
    },
    buttonLabel: {
      color: 'white',
      '& .MuiSvgIcon-root': {
        //fontSize: '1rem',
      },
    },
    popperButton: {
      padding: '0',
      width: '100%',
      height: '2.5rem',
      backgroundColor: '#B2B2B2',
      paddingLeft: '2rem',
      justifyContent: 'flex-start',
    },
    popperSelectedButton: {
      backgroundColor: '#4D4D4D',
      border: '1px solid #B2B2B2',
    },
    popperCallToActionButton: {
      backgroundColor: '#18C4A8',
    },
    popper: {
      backgroundColor: '#4D4D4D',
      color: 'white',
    },
    popperTypo: {
      fontWeight: 'bold',
      color: 'white',
    },
    saveButtonWrapper: {
      paddingRight: theme.spacing(8),
    },
  }),
);

const Footer: React.FC<Props> = (props) => {
  const { setMobileMode, mobileMode, currentJaybox, checkJobState, jobStateIntervalId } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const popperRef = React.useRef<HTMLDivElement>(null);

  const handleSaveClick = () => {
    if (currentJaybox) {
      const updatedBox: Jaybox = {
        ...currentJaybox,
        unbuildChanges: true,
        lastSaved: moment(),
      };
      dispatch(updateJaybox({ box: updatedBox }));
      dispatch(saveConfiguration({ boxId: currentJaybox.id }));
    }
    dispatch(setUnsavedChanges({ changes: false }));
  };

  React.useEffect(() => {
    if (jobStateIntervalId) {
      clearInterval(jobStateIntervalId);
      saveCheckJobStateInterval({ intervalId: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkJob = React.useCallback(() => {
    if (currentJaybox && currentJaybox.buildProgress.running) {
      checkJobState(currentJaybox.buildProgress.jobId, currentJaybox);
    }
  }, [checkJobState, currentJaybox]);

  React.useEffect(() => {
    if (currentJaybox && currentJaybox.buildProgress.running) {
      if (jobStateIntervalId === null) {
        const interval = setInterval(() => {
          checkJob();
        }, 5000);

        saveCheckJobStateInterval({ intervalId: interval });
      }
    } else {
      if (jobStateIntervalId) {
        clearInterval(jobStateIntervalId);
        saveCheckJobStateInterval({ intervalId: null });
      }
    }
  }, [checkJob, currentJaybox, jobStateIntervalId]);

  return (
    <Grid
      container
      className={classes.root}
      justify="center"
      alignItems="center"
      ref={popperRef}
      wrap="nowrap"
    >
      <Box>
        <IconButton
          edge="end"
          aria-label="jaybox-logo"
          classes={{ label: classes.iconLabel }}
          className={classes.iconButton}
          onClick={() => {
            setMobileMode(true);
          }}
        >
          <SmartphoneIcon
            style={{ height: '50%', width: '50%' }}
            className={clsx(mobileMode ? classes.iconSelected : classes.icon)}
          />
        </IconButton>
      </Box>
      <Box style={{ paddingRight: '3.9rem' }}>
        <IconButton
          edge="end"
          aria-label="jaybox-logo"
          classes={{ label: classes.iconLabel }}
          className={classes.iconButton}
          onClick={() => {
            setMobileMode(false);
          }}
        >
          <DesktopMacIcon
            style={{ height: '50%', width: '50%' }}
            className={clsx(!mobileMode ? classes.iconSelected : classes.icon)}
          />
        </IconButton>
      </Box>
      <Box className={classes.saveButtonWrapper}>
        <JayboxButton small onClick={handleSaveClick}>
          <Typography color="textPrimary" variant="body2" className={classes.popperTypo}>
            {t('toolbar.footer.save')}
          </Typography>
        </JayboxButton>
      </Box>
    </Grid>
  );
};

export default Footer;
