/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-04-09
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Button } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import * as React from 'react';
import { FC, Fragment } from 'react';

// Data models
import { Props } from './propTypes';
// Styles
const useStyles = makeStyles<Theme, Props>((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: (props) => (props.cta ? '#CC2F69' : '#0C5DE8'),
      borderColor: (props) => (props.cta ? '#CC2F69' : '#0C5DE8'),
      color: 'white',
    },
    '&.Mui-disabled': {
      color: 'white',
      backgroundColor: '#CCCCCC',
    },
    [theme.breakpoints.down('xs')]: {},
    [theme.breakpoints.up('sm')]: {},
    backgroundColor: (props) =>
      !props.primaryColor ? '#FFFFFF' : props.cta ? '#FF3A82' : '#00A3FF',
    borderRadius: '1.2rem',
    fontSize: (props) => (props.small ? '1.4rem' : '1.6rem'),
    lineHeight: (props) => (props.small ? '1.7rem' : '2.1rem'),
    color: (props) => (props.primaryColor ? 'white' : props.cta ? '#FF3A82' : '#00A3FF'),
    border: (props) =>
      props.primaryColor ? 'unset' : props.cta ? '2px solid #FF3A82' : '2px solid #00A3FF',
    width: (props) => {
      switch (props.buttonWidth) {
        case '>=8Chars':
          return props.small ? '14rem' : '16rem';
        case '<3Chars':
          return props.small ? '9.8rem' : '10rem';
        case '<8Chars':
          return props.small ? '14.1rem' : '14.4rem';
        case '>12Chars':
          return props.small ? '28.4rem' : '29rem';
      }
    },
    height: (props) => (props.small ? '3.2rem' : '4rem'),
  },
  label: {
    textTransform: 'none',
    fontSize: (props) => (props.small ? '1.4rem' : '1.6rem'),
  },
  focused: {},
}));

const JayboxButton: FC<Props> = (props) => {
  const { cta, small, primaryColor, buttonWidth, ...rest } = props;
  const classes = useStyles(props);

  return (
    <Fragment>
      <Button
        {...rest}
        classes={{ root: classes.root, label: classes.label }}
        disableFocusRipple
        fullWidth
        size="small"
        variant="contained"
      />
    </Fragment>
  );
};

JayboxButton.defaultProps = {
  cta: false,
  primaryColor: true,
  small: false,
  buttonWidth: '>=8Chars',
};

export default JayboxButton;
