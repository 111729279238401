/**
 * AttributeBar component
 *
 * @copyright 2020 Emden Consulting GmbH
 * @created 2019-12-13
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Grid, Box } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Own components
import SliderForm from 'components/configurator/sidebar/attribute/attributeForms/SliderForm/SliderFormContainer';
import TitleForm from 'components/configurator/sidebar/attribute/attributeForms/TitleForm/TitleFormContainer';
import OptionForm from 'components/configurator/sidebar/attribute/attributeForms/OptionForm/OptionFormContainer';
import RadioForm from 'components/configurator/sidebar/attribute/attributeForms/RadioForm/RadioFormContainer';
import LabelForm from 'components/configurator/sidebar/attribute/attributeForms/LabelForm/LabelFormContainer';
import FormInputForm from 'components/configurator/sidebar/attribute/attributeForms/FormInputForm/FormInputFormContainer';
import JayboxButton from 'components/common/button';
import DeleteOverlay from '../deleteOverlay/DeleteOverlay';

// Data models
import { Props } from './propTypes';
import { HTMLType } from 'jaybox/dist/models/HTMLElements';
import {
  StepElementOptionStateEntity,
  StepElementTitleStateEntity,
  StepStateEntity,
  StepElementSelectStateEntity,
  StepElementFormInputTextStateEntity,
  StepElementFormInputNumberStateEntity,
  StepElementFormInputCheckboxStateEntity,
  StepElementFormTextAreaStateEntity,
  StepElementFormInputEmailStateEntity,
  StepElementSliderStateEntity,
  StepElementFormStateEntity,
  StepElementRadioStateEntity,
  StepElementRadiogroupStateEntity,
  StepElementLabelStateEntity,
} from 'jaybox/dist/models/stepdata';
import { StepElementProgressBarStateEntity } from 'models/configuration';

// Utils
import { getStepByUUID } from 'jaybox/dist/utils/ConditionalChecker';

// Styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      width: '100%',
      paddingTop: theme.spacing(16),
      paddingBottom: theme.spacing(16),
    },
    content: {
      width: '100%',
    },
    deleteButton: {
      paddingTop: theme.spacing(7),
    },
    textField: {
      backgroundColor: 'white',
      width: '100%',
      boxSizing: 'border-box',
    },
  }),
);

const AttributeBar: React.FC<Props> = (props) => {
  const { selectedElement, currentStepUUID, steps } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [overlayOpen, setOverlayOpen] = useState(false);

  const [selectedStoreElement, setSelectedStoreElement] = React.useState<
    | StepElementOptionStateEntity
    | StepElementRadioStateEntity
    | StepElementTitleStateEntity
    | StepElementSelectStateEntity
    | StepElementRadiogroupStateEntity
    | StepElementFormStateEntity
    | StepElementFormInputTextStateEntity
    | StepElementFormInputEmailStateEntity
    | StepElementFormInputNumberStateEntity
    | StepElementFormInputCheckboxStateEntity
    | StepElementFormTextAreaStateEntity
    | StepElementSliderStateEntity
    | StepElementProgressBarStateEntity
    | StepElementLabelStateEntity
    | null
  >(null);
  const [currentStep, setCurrentStep] = React.useState<StepStateEntity | null>(null);

  React.useEffect(() => {
    const step = getStepByUUID(currentStepUUID, steps);
    setCurrentStep(step);
  }, [currentStepUUID, steps]);

  React.useEffect(() => {
    setSelectedStoreElement(null);
    if (currentStep && selectedElement) {
      if (selectedElement.primary.type === 'PROGRESS_BAR') {
        setSelectedStoreElement(selectedElement.primary);
        return;
      }
      currentStep.elements.find((element) => {
        if (
          selectedElement.primary.uuid === element.uuid ||
          (selectedElement.secondary && selectedElement.secondary.uuid === element.uuid)
        ) {
          switch (element.type) {
            case HTMLType.TITLE:
              setSelectedStoreElement(element);
              return true;
            case HTMLType.SELECT: {
              // if we selected the select element itself
              // otherwise find the option
              if (element.uuid === selectedElement.primary.uuid) {
                setSelectedStoreElement(element);
                return true;
              } else {
                const found = element.options.find(
                  (option) => option.uuid === selectedElement.primary.uuid,
                );
                if (found && found.type === HTMLType.OPTION) {
                  setSelectedStoreElement(found);
                  return true;
                }
              }
              break;
            }
            case HTMLType.RADIOGROUP: {
              // if we selected the select element itself
              // otherwise find the option
              if (element.uuid === selectedElement.primary.uuid) {
                setSelectedStoreElement(element);
                return true;
              } else {
                const found = element.radio.find(
                  (radio) => radio.uuid === selectedElement.primary.uuid,
                );
                if (found && found.type === HTMLType.RADIOINPUT) {
                  setSelectedStoreElement(found);
                  return true;
                }
              }
              break;
            }
            case HTMLType.FORM: {
              // if we selected the select element itself
              // otherwise find the option
              if (element.uuid === selectedElement.primary.uuid) {
                setSelectedStoreElement(element);
                return true;
              } else {
                const found = element.inputElement.find(
                  (element) => element.uuid === selectedElement.primary.uuid,
                );
                if (found) {
                  switch (found.type) {
                    case HTMLType.TEXTAREA:
                    case HTMLType.NUMBER:
                    case HTMLType.CHECKBOX:
                    case HTMLType.TEXT:
                    case HTMLType.EMAIL:
                      setSelectedStoreElement(found);
                      return true;
                    default:
                      break;
                  }
                }
              }
              break;
            }
            case HTMLType.SLIDER: {
              // if we selected the select element itself
              // otherwise find the option
              if (element.uuid === selectedElement.primary.uuid) {
                setSelectedStoreElement(element);
                return true;
              }
              break;
            }
            case HTMLType.LABEL: {
              if (element.uuid === selectedElement.primary.uuid) {
                setSelectedStoreElement(element);
                return true;
              }
              break;
            }
            default:
              break;
          }
        }
        return false;
      });
    }
  }, [currentStep, selectedElement]);

  const getContent = () => {
    if (selectedStoreElement) {
      switch (selectedStoreElement.type) {
        case HTMLType.TITLE:
          return <TitleForm selectedStoreElement={selectedStoreElement} />;
        case HTMLType.EMAIL:
        case HTMLType.NUMBER:
        case HTMLType.TEXTAREA:
        case HTMLType.TEXT:
          return <FormInputForm selectedStoreElement={selectedStoreElement} />;
        case HTMLType.CHECKBOX:
          return <FormInputForm selectedStoreElement={selectedStoreElement} />;
        case HTMLType.SLIDER:
          return <SliderForm selectedStoreElement={selectedStoreElement} />;
        case HTMLType.OPTION:
          return <OptionForm selectedStoreElement={selectedStoreElement} />;
        case HTMLType.RADIOINPUT:
          return <RadioForm selectedStoreElement={selectedStoreElement} />;
        case HTMLType.LABEL:
          return <LabelForm selectedStoreElement={selectedStoreElement} />;
        default:
          break;
      }
      return null;
    }
    return null;
  };

  const handleOnClickDelete = () => {
    if (selectedElement) {
      setOverlayOpen(true);
    }
  };

  return (
    <Box>
      <Grid container alignContent="flex-start" justify="center" className={classes.container}>
        <Grid item xs={12} className={classes.content}>
          {getContent()}
        </Grid>
        {selectedElement !== null &&
        selectedElement.primary.type !== HTMLType.TITLE &&
        selectedElement.primary.type !== 'PROGRESS_BAR' &&
        !(
          currentStep?.stepType === 'send' && selectedElement.primary.type === HTMLType.CHECKBOX
        ) ? (
          <Grid item xs={12} className={classes.deleteButton}>
            <JayboxButton
              onClick={() => {
                handleOnClickDelete();
              }}
            >
              {t('toolbar.delete')}
            </JayboxButton>
          </Grid>
        ) : null}
      </Grid>
      <Box>
        {overlayOpen ? <DeleteOverlay closedCallback={() => setOverlayOpen(false)} /> : null}
      </Box>
    </Box>
  );
};

export default AttributeBar;
