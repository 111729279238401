/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-02-13
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { namespacedAction } from 'redux-subspace';

// Presentational component
import RadioForm from './RadioForm';

// Action creator
import { setRadioElement } from 'jaybox/dist/store/stepdata/actions';

// Data models
import { StateProps, DispatchProps } from './propTypes';
import { ApplicationState, ApplicationAction } from 'models/store';
import {
  StepElementRadioStateEntity,
  StepElementRadiogroupStateEntity,
} from 'jaybox/dist/models/stepdata';

const mapStateToProps: MapStateToProps<StateProps, {}, ApplicationState> = (state) => ({
  currentStepUUID: state.jaybox.stepData.currentStepUUID,
  selectedElement: state.configuration.selectedElement,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = (
  dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>,
) => ({
  setRadioElement: (
    element: StepElementRadiogroupStateEntity,
    radio: StepElementRadioStateEntity | null,
    stepUUID: string,
  ) => {
    dispatch(namespacedAction('@@jaybox')(setRadioElement(element, radio, stepUUID)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RadioForm);
