/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-05-12
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Grid } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState, FC, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

// Own components
import JayboxOverlay from 'components/common/overlay';

// Action creator
import { setUnsavedChanges } from 'store/configuration/configurationSlice';
import { updateJaybox } from 'store/boxes/boxesSlice';
import { saveConfiguration } from 'store/config/configSlice';

// Data models
import { StateProps } from './propTypes';
import { ApplicationState } from 'models/store';
import { Jaybox } from 'models/boxes';

const UnsavedChangesOverlay: FC = () => {
  const { unsavedChanges, currentJaybox } = useSelector<ApplicationState, StateProps>((state) => ({
    unsavedChanges: state.configuration.unsavedChanges,
    currentJaybox: state.boxes.currentJaybox,
  }));
  const history = useHistory();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [overlayOpen, setOverlayOpen] = useState(false);
  const unblock = useRef<null | (() => void)>(null);

  // useEffect(() => {
  //   if (previousConfigLoaded && uploadState === RequestStatus.IDLE) {
  //     if (
  //       previousLocalization !== localization ||
  //       previousStyle !== style ||
  //       previousSteps !== steps
  //     ) {
  //       //dispatch(setUnsavedChanged(true));
  //     }
  //   }
  // }, [
  //   dispatch,
  //   previousLocalization,
  //   localization,
  //   previousStyle,
  //   style,
  //   previousSteps,
  //   steps,
  //   previousConfigLoaded,
  //   uploadState,
  //   history,
  // ]);

  useEffect(() => {
    if (unsavedChanges) {
      if (!unblock.current) {
        unblock.current = history.block((location, action) => {
          setOverlayOpen(true);
          return false;
        });
      }
    } else {
      if (unblock.current) {
        unblock.current();
      }
    }
    return () => {
      if (unblock.current) {
        unblock.current();
      }
    };
  }, [history, unsavedChanges]);

  const handleWindowClose = useCallback(
    (ev: BeforeUnloadEvent) => {
      if (unsavedChanges) {
        setOverlayOpen(true);
        ev.preventDefault();
        ev.returnValue = t('common.unsavedChanges');
        return;
      }
    },
    [t, unsavedChanges],
  );

  useEffect(() => {
    window.addEventListener('beforeunload', handleWindowClose);
    return () => {
      window.removeEventListener('beforeunload', handleWindowClose);
    };
  }, [handleWindowClose]);

  const handleSaveClick = () => {
    if (currentJaybox) {
      const updatedBox: Jaybox = {
        ...currentJaybox,
        unbuildChanges: true,
        lastSaved: moment(),
      };
      dispatch(updateJaybox({ box: updatedBox }));
      dispatch(saveConfiguration({ boxId: currentJaybox.id }));
    }
    dispatch(setUnsavedChanges({ changes: false }));
  };

  return (
    <JayboxOverlay
      open={overlayOpen}
      cancelAction={() => {
        setOverlayOpen(false);
      }}
      confirmAction={() => {
        handleSaveClick();
        setOverlayOpen(false);
      }}
      title={t('unsavedChangesOverlay.title')}
      subTitle={t('unsavedChangesOverlay.subTitle')}
      cancelButtonText={t('unsavedChangesOverlay.cancel')}
      confirmButtonText={t('unsavedChangesOverlay.save')}
    >
      <Grid container>
        <Grid item xs={12}></Grid>
      </Grid>
    </JayboxOverlay>
  );
};

export default UnsavedChangesOverlay;
