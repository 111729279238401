/**
 * @copyright 2021 Emden Consulting GmbH
 * @created 2021-04-19
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as firebase from 'firebase/app';

// Data models
import { RequestStatus } from 'models/common';
import { JayboxUser } from 'models/user';
import { FireStoreError } from 'models/firebase';

// Config

// Action Creator
import { UserDocument } from 'models/firebase/user';
import { updateCustomerId } from 'store/payment/paymentSlice';
import { createAppThunk } from 'utils/appActions';

const sliceName = '@@user';

export type UserState = {
  profile: JayboxUser | null;
  managedProfile: JayboxUser | null;
  loadUserError: FireStoreError;
  loadUserStatus: RequestStatus;
  updateUserProfileUnsubscribe: () => void;
};

export type UpdateUserPayload = {
  profile: JayboxUser | null;
};

export type UpdateManagedUserPayload = {
  profile: JayboxUser | null;
};

export type UpdateLoadUserErrorPayload = {
  loadUserError: FireStoreError;
};

export type UpdateUserProfileUnsubscribePayload = {
  updateUserProfileUnsubscribe: () => void;
};

export const initialState: UserState = {
  loadUserError: FireStoreError.NONE,
  loadUserStatus: RequestStatus.IDLE,
  updateUserProfileUnsubscribe: () => {},
  profile: null,
  managedProfile: null,
};

export const loadUser = createAppThunk(
  sliceName + '/loadUser',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const currentUser = firebase.auth().currentUser;

      if (currentUser) {
        const userProfileUnsubscribe = firebase
          .firestore()
          .doc(`/versions/v1/users/${currentUser.uid}`)
          .onSnapshot(
            (snapshot: firebase.firestore.DocumentSnapshot) => {
              if (!snapshot.exists) {
                return;
              }
              // Add event observer for event changes
              const user = snapshot.data() as UserDocument;
              const customerId = user.paymentDetails?.customerId || '';

              const profile: JayboxUser = {
                uid: currentUser.uid,
                accountType: user.accountType,
                paymentDetails: {
                  customerId: customerId,
                },
              };
              dispatch(updateUser({ profile: profile }));
              //dispatch(updateCustomerId({ customerId: customerId }));
            },
            function(error: Error) {
              console.log(error);
            },
          );
        dispatch(
          setUpdateUserUnsubscribe({ updateUserProfileUnsubscribe: userProfileUnsubscribe }),
        );
      }
    } catch (err) {
      return rejectWithValue({ errorMessage: err });
    }
  },
);

const userSlice = createSlice({
  name: sliceName,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(loadUser.pending, (state, _) => {
      state.loadUserStatus = RequestStatus.LOADING;
    });
    builder.addCase(loadUser.fulfilled, (state, _) => {
      state.loadUserStatus = RequestStatus.IDLE;
    });
    builder.addCase(loadUser.rejected, (state, action) => {
      state.loadUserStatus = RequestStatus.ERROR;
    });
  },
  reducers: {
    updateUser(state, action: PayloadAction<UpdateUserPayload>) {
      state.profile = action.payload.profile;
    },
    updateManagedUser(state, action: PayloadAction<UpdateManagedUserPayload>) {
      state.managedProfile = action.payload.profile;
    },
    updateLoadUserError(state, action: PayloadAction<UpdateLoadUserErrorPayload>) {
      state.loadUserError = action.payload.loadUserError;
    },
    setUpdateUserUnsubscribe(state, action: PayloadAction<UpdateUserProfileUnsubscribePayload>) {
      state.updateUserProfileUnsubscribe = action.payload.updateUserProfileUnsubscribe;
    },
  },
});

export const {
  updateUser,
  updateManagedUser,
  updateLoadUserError,
  setUpdateUserUnsubscribe,
} = userSlice.actions;

export default userSlice.reducer;
