/**
 * Editor container
 *
 * @copyright 2020 Emden Consulting GmbH
 * @created 2019-12-13
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { namespacedAction } from 'redux-subspace';

// Presentational component
import Editor from './Editor';

// Action Creators
import {
  addStepData,
  setMaxSteps,
  setSelectElement,
  changeToStep,
  setFormInputElement,
  setSliderElement,
  setRadioElement,
  setLabelElement,
} from 'jaybox/dist/store/stepdata/actions';

// Data models
import { StateProps, DispatchProps } from './propTypes';
import { ApplicationState, ApplicationAction } from 'models/store';
import {
  StepElementSelectStateEntity,
  StepElementOptionStateEntity,
  StepElementFormInputTextStateEntity,
  StepElementFormInputCheckboxStateEntity,
  StepElementFormInputNumberStateEntity,
  StepElementFormInputEmailStateEntity,
  StepElementFormTextAreaStateEntity,
  StepElementFormStateEntity,
  StepElementRadiogroupStateEntity,
  StepElementRadioStateEntity,
} from 'jaybox/dist/models/stepdata';
import { SelectedElement } from 'models/configuration';

const mapStateToProps: MapStateToProps<StateProps, {}, ApplicationState> = (state) => ({
  steps: state.jaybox.stepData.steps,
  currentStepNumber: state.jaybox.stepData.currentStepNumber,
  currentStepUUID: state.jaybox.stepData.currentStepUUID,
  theme: state.configuration.style,
  maxSteps: state.jaybox.stepData.maxSteps,
  mobileMode: state.navigation.mobileMode,
  currentJaybox: state.boxes.currentJaybox,
  meta: state.jaybox.stepData.meta,
  unsavedChanges: state.configuration.unsavedChanges,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = (
  dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>,
) => ({
  addStep: (stepData) => {
    dispatch(namespacedAction('@@jaybox')(addStepData(stepData)));
  },
  setMaxSteps: (maxSteps: number) => {
    dispatch(namespacedAction('@@jaybox')(setMaxSteps(maxSteps)));
  },
  setSelectElement: (
    element: StepElementSelectStateEntity,
    option: StepElementOptionStateEntity | null,
    stepUUID: string,
  ) => {
    dispatch(namespacedAction('@@jaybox')(setSelectElement(element, option, stepUUID)));
  },
  setRadioElement: (
    element: StepElementRadiogroupStateEntity,
    radio: StepElementRadioStateEntity | null,
    stepUUID: string,
  ) => {
    dispatch(namespacedAction('@@jaybox')(setRadioElement(element, radio, stepUUID)));
  },
  setFormInputElement: (
    element: StepElementFormStateEntity,
    inputElement:
      | StepElementFormInputTextStateEntity
      | StepElementFormInputCheckboxStateEntity
      | StepElementFormInputNumberStateEntity
      | StepElementFormInputEmailStateEntity
      | StepElementFormTextAreaStateEntity
      | null,
    stepUUID: string,
  ) => {
    dispatch(namespacedAction('@@jaybox')(setFormInputElement(element, inputElement, stepUUID)));
  },
  changeToStep: (stepNumber: number, stepUUID: string) => {
    dispatch(namespacedAction('@@jaybox')(changeToStep(stepNumber, stepUUID)));
  },
  setSliderElement: (element, stepUUID) => {
    dispatch(namespacedAction('@@jaybox')(setSliderElement(element, stepUUID)));
  },
  setLabelElement: (element, stepUUID) => {
    dispatch(namespacedAction('@@jaybox')(setLabelElement(element, stepUUID)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
