/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-04-18
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Grid, Typography, Breadcrumbs } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { FC } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

// Data Models
import { StateProps } from './propTypes';

// Utils
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from 'models/store';

// Assets
import { BOX_DETAIL } from 'config/routes';

// Action Creator
import { setCurrentJaybox } from 'store/boxes/boxesSlice';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  fullHeight: {
    width: '100%',
    height: '100%',
  },
  link: {
    textDecoration: 'none',
  },
  linkText: {
    color: '#333333',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    '& a': {
      textDecoration: 'none',
    },
  },
  separator: {
    margin: '0',
  },
}));

const Breadcrumb: FC = () => {
  const dispatch = useDispatch();

  const { availableJayboxes } = useSelector<ApplicationState, StateProps>((state) => ({
    availableJayboxes: state.boxes.availableJayboxes,
  }));

  const location = useLocation();
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const getContent = () => {
    const pathnames = location.pathname.split('/').filter((x) => x);

    const match = matchPath<{ id: string }>(location.pathname, {
      path: BOX_DETAIL,
    });

    const boxId = match ? match.params.id : '';

    const getTrans = (route: string) => {
      if (i18n.exists(route)) {
        return t(route);
      } else {
        return route;
      }
    };

    const handleLinkClick = () => {
      dispatch(setCurrentJaybox({ jaybox: null }));
    };

    return (
      <Grid container justify="center" className={classes.fullHeight}>
        <Grid item xs={12}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
            classes={{ separator: classes.separator }}
          >
            <RouterLink color="inherit" to="/" className={classes.link} onClick={handleLinkClick}>
              <Typography color="textPrimary" className={classes.linkText}>
                {t('navigation.collection')}
              </Typography>
            </RouterLink>
            {pathnames.map((value, index) => {
              const last = index === pathnames.length - 1;
              const to = `/${pathnames.slice(0, index + 1).join('/')}`;
              if (boxId === value) {
                const foundBox = availableJayboxes.find((box) => box.id === boxId);
                if (foundBox) {
                  value = foundBox.name;
                }
              }
              return last ? (
                <Typography color="textPrimary" key={to} className={classes.linkText}>
                  {getTrans(value)}
                </Typography>
              ) : (
                <RouterLink
                  color="inherit"
                  to={to}
                  key={to}
                  className={classes.link}
                  onClick={handleLinkClick}
                >
                  <Typography color="textPrimary" key={to} className={classes.linkText}>
                    {getTrans(value)}
                  </Typography>
                </RouterLink>
              );
            })}
          </Breadcrumbs>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container className={classes.root} justify="center" alignContent="center">
      <Grid item xs>
        {getContent()}
      </Grid>
    </Grid>
  );
};

export default Breadcrumb;
