/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-02-13
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { connect, MapStateToProps } from 'react-redux';

// Presentational component
import TitleForm from './TitleForm';

// Action creator

// Data models
import { StateProps } from './propTypes';
import { ApplicationState } from 'models/store';

const mapStateToProps: MapStateToProps<StateProps, {}, ApplicationState> = (state) => ({
  currentStepUUID: state.jaybox.stepData.currentStepUUID,
});

export default connect(mapStateToProps)(TitleForm);
