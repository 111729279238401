/**
 * Internationlization utils
 *
 * @copyright 2020 Emden Consulting GmbH
 * @created 2019-12-11
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import BackendLoader, { BackendOptions } from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

// Models
import { Language } from 'models/common';

//import { default as configurator_de } from 'assets/locales/de/translation.json';

// Options for lazy loading from server
const backendOptions: BackendOptions = {
  loadPath: '/locales/{{lng}}/{{ns}}.json',
};

let newInstance = i18n.createInstance({
  appendNamespaceToMissingKey: true,
  detection: {
    order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    lookupQuerystring: 'lng',
  },
  ns: ['configurator'],
  fallbackNS: 'configurator',
  defaultNS: 'configurator',
  backend: backendOptions,
  fallbackLng: Language.DE,
  interpolation: {
    escapeValue: false,
  },
  debug: true,
  load: 'languageOnly',
  react: {
    bindI18nStore: '',
    useSuspense: true,
    wait: false,
  },
});

newInstance
  .use(BackendLoader)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init();

export default newInstance;
