/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-02-25
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Grid } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { v1 as uuidv1 } from 'uuid';

// Action Creator
import {
  setFontStyle,
  setStyleKey,
  setUnsavedChanges,
} from 'store/configuration/configurationSlice';
import { useAppDispatch } from 'store';

// Own components
import StyleEdit from './StyleEdit';

// Data models
import { Props } from './propTypes';
import { HTMLType } from 'jaybox/dist/models/HTMLElements';
import {
  StepElementOptionStateEntity,
  StepElementTitleStateEntity,
  StepStateEntity,
  StepElementSelectStateEntity,
  StepElementFormInputTextStateEntity,
  StepElementFormInputNumberStateEntity,
  StepElementFormInputCheckboxStateEntity,
  StepElementFormTextAreaStateEntity,
  StepElementFormInputEmailStateEntity,
  StepElementSliderStateEntity,
  StepElementFormStateEntity,
  StepElementRadiogroupStateEntity,
  StepElementRadioStateEntity,
  StepElementLabelStateEntity,
} from 'jaybox/dist/models/stepdata';
import { StepElementProgressBarStateEntity } from 'models/configuration';
import { StyleOption, FontStyleOption } from 'models/common';

// Utils
import { getStepByUUID } from 'jaybox/dist/utils/ConditionalChecker';

// Styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      width: '100%',
      paddingTop: theme.spacing(7),
      paddingBottom: theme.spacing(7),
    },
    content: {
      width: '100%',
    },
    textField: {
      backgroundColor: 'white',
      width: '100%',
      boxSizing: 'border-box',
    },
    button: {
      color: 'white',
      width: '100%',
      borderRadius: '0',
      backgroundColor: 'red',
      '&:hover': {
        backgroundColor: 'red',
      },
    },
  }),
);

const StyleBar: React.FC<Props> = (props) => {
  const { selectedElement, currentStepUUID, steps, style } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [selectedStoreElement, setSelectedStoreElement] = React.useState<
    | StepElementOptionStateEntity
    | StepElementTitleStateEntity
    | StepElementSelectStateEntity
    | StepElementRadiogroupStateEntity
    | StepElementRadioStateEntity
    | StepElementFormStateEntity
    | StepElementFormInputTextStateEntity
    | StepElementFormInputEmailStateEntity
    | StepElementFormInputNumberStateEntity
    | StepElementFormInputCheckboxStateEntity
    | StepElementFormTextAreaStateEntity
    | StepElementSliderStateEntity
    | StepElementProgressBarStateEntity
    | StepElementLabelStateEntity
    | null
  >(null);
  const [currentStep, setCurrentStep] = React.useState<StepStateEntity | null>(null);

  React.useEffect(() => {
    const step = getStepByUUID(currentStepUUID, steps);
    setCurrentStep(step);
  }, [currentStepUUID, steps]);

  React.useEffect(() => {
    setSelectedStoreElement(null);
    if (currentStep && selectedElement) {
      if (selectedElement.primary.type === 'PROGRESS_BAR') {
        setSelectedStoreElement(selectedElement.primary);
        return;
      }
      currentStep.elements.find((element) => {
        if (
          selectedElement.primary.uuid === element.uuid ||
          (selectedElement.secondary && selectedElement.secondary.uuid === element.uuid)
        ) {
          switch (element.type) {
            case HTMLType.TITLE:
              setSelectedStoreElement(element);
              return true;
            case HTMLType.SELECT: {
              // if we selected the select element itself
              // otherwise find the option
              if (element.uuid === selectedElement.primary.uuid) {
                setSelectedStoreElement(element);
                return true;
              } else {
                const found = element.options.find(
                  (option) => option.uuid === selectedElement.primary.uuid,
                );
                if (found && found.type === HTMLType.OPTION) {
                  setSelectedStoreElement(found);
                  return true;
                }
              }
              break;
            }
            case HTMLType.RADIOGROUP: {
              // if we selected the select element itself
              // otherwise find the option
              if (element.uuid === selectedElement.primary.uuid) {
                setSelectedStoreElement(element);
                return true;
              } else {
                const found = element.radio.find(
                  (radio) => radio.uuid === selectedElement.primary.uuid,
                );
                if (found && found.type === HTMLType.RADIOINPUT) {
                  setSelectedStoreElement(found);
                  return true;
                }
              }
              break;
            }
            case HTMLType.FORM: {
              // if we selected the select element itself
              // otherwise find the option
              if (element.uuid === selectedElement.primary.uuid) {
                setSelectedStoreElement(element);
                return true;
              } else {
                const found = element.inputElement.find(
                  (element) => element.uuid === selectedElement.primary.uuid,
                );
                if (found) {
                  switch (found.type) {
                    case HTMLType.TEXTAREA:
                    case HTMLType.NUMBER:
                    case HTMLType.CHECKBOX:
                    case HTMLType.TEXT:
                    case HTMLType.EMAIL:
                      setSelectedStoreElement(found);
                      return true;
                    default:
                      break;
                  }
                }
              }
              break;
            }
            case HTMLType.SLIDER: {
              // if we selected the select element itself
              // otherwise find the option
              if (element.uuid === selectedElement.primary.uuid) {
                setSelectedStoreElement(element);
                return true;
              }
              break;
            }
            case HTMLType.LABEL: {
              if (element.uuid === selectedElement.primary.uuid) {
                setSelectedStoreElement(element);
                return true;
              }
              break;
            }
            default:
              break;
          }
        }
        return false;
      });
    }
  }, [currentStep, selectedElement]);

  const getContent = () => {
    if (selectedStoreElement) {
      const colors: (StyleOption | FontStyleOption)[] = [];
      switch (selectedStoreElement.type) {
        case HTMLType.TITLE:
          colors.push({ key: 'titleTextColor', hexColor: style.titleTextColor, type: 'color' });
          colors.push({
            key: 'titleBackgroundColor',
            hexColor: style.titleBackgroundColor,
            type: 'color',
          });
          break;
        case HTMLType.TEXT:
        case HTMLType.EMAIL:
        case HTMLType.NUMBER:
        case HTMLType.TEXTAREA:
          colors.push({ key: 'inputTextColor', hexColor: style.inputTextColor, type: 'color' });
          colors.push({ key: 'inputColor', hexColor: style.inputColor, type: 'color' });
          break;
        case HTMLType.CHECKBOX:
          colors.push({ key: 'checkboxColor', hexColor: style.checkboxColor, type: 'color' });
          colors.push({
            key: 'checkboxTextColor',
            hexColor: style.checkboxTextColor,
            type: 'color',
          });
          break;
        case HTMLType.SLIDER:
          colors.push({ key: 'sliderColor', hexColor: style.sliderColor, type: 'color' });
          colors.push({ key: 'sliderTextColor', hexColor: style.sliderTextColor, type: 'color' });
          break;
        case HTMLType.LABEL:
          if (selectedElement) {
            const fontStyle = style.font[selectedElement.primary.uuid] || null;
            colors.push({ fontStyle: fontStyle, type: 'fontColor' });
            colors.push({ fontStyle: fontStyle, type: 'fontSize' });
          }

          break;
        case HTMLType.OPTION:
          colors.push({
            key: 'optionBackgroundColor',
            hexColor: style.optionBackgroundColor,
            type: 'color',
          });
          // colors.push({
          //   key: 'optionContourColor',
          //   hexColor: style.optionContourColor,
          //   type: 'color',
          // });
          // colors.push({
          //   key: 'optionContourSize',
          //   hexColor: style.optionContourSize,
          //   type: 'pixel',
          // });
          colors.push({
            key: 'optionSelectedBackgroundColor',
            hexColor: style.optionSelectedBackgroundColor,
            type: 'color',
          });
          colors.push({
            key: 'optionSelectedTextColor',
            hexColor: style.optionSelectedTextColor,
            type: 'color',
          });
          colors.push({ key: 'optionTextColor', hexColor: style.optionTextColor, type: 'color' });
          break;
        case HTMLType.RADIOINPUT:
          colors.push({
            key: 'radioTextColor',
            hexColor: style.radioTextColor,
            type: 'color',
          });
          colors.push({
            key: 'radioColor',
            hexColor: style.radioColor,
            type: 'color',
          });
          colors.push({
            key: 'radioSelectedColor',
            hexColor: style.radioSelectedColor,
            type: 'color',
          });
          break;
        case 'PROGRESS_BAR':
          colors.push({ key: 'progressBarColor', hexColor: style.progressBarColor, type: 'color' });
          colors.push({
            key: 'progressBarUnfilledColor',
            hexColor: style.progressBarUnfilledColor,
            type: 'color',
          });
          break;
        default:
          break;
      }
      return (
        <StyleEdit
          selectedElementUUID={selectedElement ? selectedElement.primary.uuid : uuidv1()}
          setStyleKey={(key, option) => {
            dispatch(setStyleKey({ key: key, option: option }));
            dispatch(setUnsavedChanges({ changes: true }));
          }}
          stylingOptions={colors}
          setFontStyle={(fontStyle, uuid) => {
            dispatch(setFontStyle({ fontStyle: fontStyle, uuid: uuid }));
            dispatch(setUnsavedChanges({ changes: true }));
          }}
          style={style}
        />
      );
    }
    return null;
  };

  return (
    <Grid container alignContent="flex-start" justify="center" className={classes.container}>
      <Grid item xs={12} className={classes.content}>
        {getContent()}
      </Grid>
    </Grid>
  );
};

export default StyleBar;
