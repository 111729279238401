/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-01-22
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Theme, createStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { SketchPicker, ColorResult, RGBColor } from 'react-color';

// Data models
import { Props } from './propTypes';

// Styles
const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    container: {
      height: '2.6rem',
      width: '2.6rem',
    },
    color: {
      width: '100%',
      height: '100%',
      borderRadius: '2px',
    },
    swatch: {
      width: '100%',
      height: '100%',
      padding: '0px',
      background: '#fff',
      borderRadius: '1px',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      cursor: 'pointer',
    },
    popover: {
      position: 'absolute',
      zIndex: 2,
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    },
  }),
);

const ColorPicker: React.FC<Props> = (props) => {
  const { colorChangeHandler, initialColor } = props;
  const classes = useStyles(props);
  const [displayColorPicker, setDisplayColorPicker] = React.useState<boolean>(false);
  const [color, setColor] = React.useState<RGBColor>(
    initialColor ? initialColor : { r: 255, g: 0, b: 0, a: 1 },
  );

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (color: ColorResult) => {
    setColor(color.rgb);
    colorChangeHandler(color.rgb, color.hex);
  };

  return (
    <Grid container justify="flex-start" className={classes.container}>
      <div style={{ width: '100%' }}>
        <div className={classes.swatch} onClick={handleClick}>
          <div
            className={classes.color}
            style={{
              background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
            }}
          />
        </div>
        {displayColorPicker ? (
          <div className={classes.popover}>
            <div className={classes.cover} onClick={handleClose} />
            <SketchPicker color={color} onChange={handleChange} />
          </div>
        ) : null}
      </div>
    </Grid>
  );
};

export default ColorPicker;
