/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-01-03
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDrop } from 'react-dnd';

// Assets
import { ReactComponent as AddIcon } from 'assets/img/jaybox_drag_plus.svg';

// Data models
import { Props } from './propTypes';
import { DraggableType, DragItem } from 'models/draggable';

// Styles
const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    dropboxContainer: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      //padding: '2rem',
      marginTop: -theme.spacing(4),
      textAlign: 'center',
      pointerEvents: 'none',
    },
    labelContainer: {
      width: '100%',
      paddingTop: theme.spacing(14),
      paddingBottom: theme.spacing(14),
      textAlign: 'center',
    },
    icon: {
      height: theme.spacing(12),
      width: theme.spacing(12),
      color: theme.palette.secondary.main,
    },
    dropboxLabel: {
      color: theme.palette.text.primary,
      fontWeight: 'bold',
    },
    dropboxHover: {
      pointerEvents: 'all',
      backgroundColor: '#F7F7F7',
      opacity: '0.5',
    },
    labelPadding: {
      paddingTop: theme.spacing(12),
    },
  }),
);

const DropBox: React.FC<Props> = (props) => {
  const { hideLabel } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [{ isOver, isDragging }, drop] = useDrop({
    accept: [
      DraggableType.Option,
      DraggableType.Checkbox,
      DraggableType.Slider,
      DraggableType.Text,
      DraggableType.Number,
      DraggableType.TextArea,
      DraggableType.Email,
      DraggableType.Radio,
      DraggableType.Label,
    ],
    drop(item: DragItem) {
      props.onDrop(item);
      return undefined;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isDragging: monitor.getItem() !== null,
    }),
  });

  return (
    <>
      <Grid
        key="dropbox"
        ref={drop}
        container
        alignItems="flex-end"
        className={`${isOver ? classes.dropboxHover : ''} ${classes.dropboxContainer}`}
        style={isDragging ? { pointerEvents: 'all' } : {}}
      ></Grid>
      <Grid
        key="dropbox-label"
        container
        alignItems="center"
        justify="center"
        className={classes.labelContainer}
      >
        <Grid item xs={12}>
          <AddIcon className={classes.icon} />
        </Grid>
        {!hideLabel ? (
          <Grid item xs={12} className={classes.labelPadding}>
            <Typography color="textPrimary" className={classes.dropboxLabel}>
              {t('editor.dropboxLabel')}
            </Typography>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

export default DropBox;
