/**
 * Toolbar Wrapper
 *
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-01-02
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Grid, IconButton, Theme, Typography, Link, Box } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Own Components
import AttributeBar from './attribute/AttributeBarContainer';
import StyleBar from './style/StyleBarContainer';
import ElementsBar from './element/ElementsBarContainer';
import Footer from 'components/configurator/sidebar/footer/FooterContainer';
import UnsavedChangesOverlay from './unsavedChangesOverlay/UnsavedChangesOverlay';

// Action creator
import { setCurrentlySelectedElement } from 'store/configuration/configurationSlice';

// Data Models
import { Props } from './propTypes';

// Assets
import { ReactComponent as JayboxIconWhite } from 'assets/img/jaybox_logo_white.svg';

// Routes
import { BOX_DETAIL } from 'config/routes';

// Store
import { RootState } from 'store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
    },
    topBar: {
      height: '4.8rem',
      backgroundColor: '#333333',
    },
    full: {
      height: '100%',
      width: '100%',
    },
    link: {
      '&:hover': {
        color: '#0C5DE8',
      },
    },
    contentHeader: {
      height: '6.5rem',
      backgroundColor: 'white',
    },
    contentHeaderButton: {
      width: '100%',
      height: '100%',
    },
    contentHeaderButtonSelected: {
      textDecoration: 'underline',
      backgroundColor: '#E5E5E5',
      '&:hover': {
        textDecoration: 'underline',
        backgroundColor: '#E5E5E5',
      },
    },
    content: {
      overflowY: 'auto',
      height: 'calc(100% - 6.5rem)',
      paddingTop: theme.spacing(10),
    },
    contentWrapper: {
      height: 'calc(100% - 4.8rem - 4.8rem)',
      backgroundColor: '#B2B2B2',
    },
    footer: {
      height: '4.8rem',
      backgroundColor: '#1A1A1A',
    },
    switchToJayboxIcon: {
      height: '3.9rem',
      width: '3.9rem',
      color: 'white',
      backgroundColor: '#1FC1AE',
    },
    switchToJayboxButton: {
      width: '100%',
      height: '3.9rem',
      backgroundColor: 'white',
      borderRadius: '0',
      padding: '0',
    },
    switchToJayboxLabel: {
      justifyContent: 'space-between',
    },
    jayboxIcon: {
      width: '3rem',
      height: '3rem',
      paddingRight: '0.5rem',
    },
    topBarTitle: {
      textTransform: 'uppercase',
      color: 'white',
    },
    backButton: {
      color: 'white',
      padding: '0',
      paddingLeft: theme.spacing(4),
      position: 'relative',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '& .MuiSvgIcon-root': {
        width: '1.5rem',
        height: '1.5rem',
      },
    },
    contentHeaderText: {
      fontWeight: 'bold',
    },
    tabContainer: {
      height: '100%',
      '& .Mui-selected': {
        borderBottom: '2px solid #FF3A82',
        color: '#FF3A82',
      },
      '& .MuiTab-textColorInherit': {
        opacity: '1',
      },
    },
    tabLinkContainer: {
      paddingLeft: '1.5rem',
      display: 'inline-flex',
      backgroundColor: '#F7F7F7',
      height: '100%',
      width: '100%',
    },
    tabLinkContainerSelected: {
      paddingLeft: '1.5rem',
      display: 'inline-flex',
      backgroundColor: '#FFF',
      height: '100%',
      width: '100%',
    },
    tabLink: {
      color: '#333333',
      paddingBottom: '1.5rem',
      borderBottom: '2px solid transparent',
      display: 'inline-flex',
      alignItems: 'flex-end',
    },
    tabLinkSelected: {
      borderBottom: '2px solid #FF3A82',
      color: '#333333',
      paddingBottom: '1.5rem',
      display: 'inline-flex',
      alignItems: 'flex-end',
    },
    tabRoot: {
      backgroundColor: '#FFF',
    },
    tabs: {
      height: '4.8rem',
    },
    tabContent: {
      backgroundColor: '#FFF',
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  }),
);

const Sidebar: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const [styleSelected, setStyleSelected] = React.useState(false);
  const history = useHistory();
  const { currentJaybox } = useSelector((state: RootState) => ({
    currentJaybox: state.boxes.currentJaybox,
  }));
  const selectedElement = useSelector((state: RootState) => state.configuration.selectedElement);

  const getContent = () => {
    if (selectedElement) {
      return (
        <Grid item container xs={12} className={classes.tabRoot}>
          <Grid item container xs={12} className={classes.tabs} alignItems="flex-end">
            <Grid
              item
              xs={6}
              style={{ height: '100%', cursor: 'pointer' }}
              onClick={() => setStyleSelected(false)}
            >
              <Box
                className={clsx(
                  !styleSelected ? classes.tabLinkContainerSelected : classes.tabLinkContainer,
                )}
              >
                <Link
                  variant="body1"
                  className={clsx(!styleSelected ? classes.tabLinkSelected : classes.tabLink)}
                >
                  {t('toolbar.navigation.content')}
                </Link>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ height: '100%', cursor: 'pointer' }}
              onClick={() => setStyleSelected(true)}
            >
              <Box
                className={clsx(
                  styleSelected ? classes.tabLinkContainerSelected : classes.tabLinkContainer,
                )}
              >
                <Link
                  variant="body1"
                  className={clsx(styleSelected ? classes.tabLinkSelected : classes.tabLink)}
                >
                  {t('toolbar.navigation.style')}
                </Link>
              </Box>
            </Grid>
          </Grid>
          <Grid container className={classes.tabContent}>
            <Grid item xs={12}>
              {styleSelected ? <StyleBar /> : <AttributeBar />}
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container className={classes.full}>
        <Grid item xs={12}>
          <ElementsBar />
        </Grid>
      </Grid>
    );
  };

  const getContentHeader = () => {
    if (selectedElement) {
      return (
        <Grid container item xs={12} alignItems="center" justify="center" className={classes.full}>
          <Link
            onClick={() => dispatch(setCurrentlySelectedElement({ element: null }))}
            className={classes.link}
          >
            {`< ${t('toolbar.navigation.backToToolbar')}`}
          </Link>
        </Grid>
      );
    }
    return (
      <Grid container item xs={12} alignItems="center" justify="center" className={classes.full}>
        <Typography
          color="textPrimary"
          align="center"
          variant="body2"
          className={classes.contentHeaderText}
        >
          {t('toolbar.navigation.header')}
        </Typography>
      </Grid>
    );
  };

  const header = () => {
    return (
      <Grid item container xs={12} className={classes.topBar} justify="center" alignItems="center">
        <Grid item xs={1}>
          <IconButton
            className={classes.backButton}
            arial-label="close-button"
            disableRipple
            disableFocusRipple
            disableTouchRipple
            onClick={() => {
              if (history.length === 1 && currentJaybox?.id) {
                history.replace(BOX_DETAIL.replace(':id', currentJaybox.id));
              } else {
                history.goBack();
              }
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>
        </Grid>
        <Grid item xs>
          <Grid container justify="center" wrap="nowrap" alignItems="center">
            <span>
              <JayboxIconWhite className={classes.jayboxIcon} />
            </span>
            <span>
              <Typography variant="h4" className={classes.topBarTitle} align="center">
                {t('toolbar.title')}
              </Typography>
            </span>
          </Grid>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    );
  };

  return (
    <Grid container className={classes.root} alignItems="flex-start">
      {header()}
      <Grid item container xs={12} className={classes.contentWrapper}>
        <Grid item xs={12} className={classes.contentHeader}>
          {getContentHeader()}
        </Grid>
        <Grid item xs={12} className={classes.content}>
          {getContent()}
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.footer}>
        <Footer />
      </Grid>
      <UnsavedChangesOverlay />
    </Grid>
  );
};

export default Sidebar;
