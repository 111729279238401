/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-02-13
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { namespacedAction } from 'redux-subspace';

// Presentational component
import FormInputForm from './FormInputForm';

// Action creator
import { setFormInputElement } from 'jaybox/dist/store/stepdata/actions';

// Data models
import { StateProps, DispatchProps } from './propTypes';
import { ApplicationState, ApplicationAction } from 'models/store';
import {
  StepElementFormStateEntity,
  StepElementFormInputEmailStateEntity,
  StepElementFormInputCheckboxStateEntity,
  StepElementFormInputTextStateEntity,
  StepElementFormInputNumberStateEntity,
  StepElementFormTextAreaStateEntity,
} from 'jaybox/dist/models/stepdata';

const mapStateToProps: MapStateToProps<StateProps, {}, ApplicationState> = (state) => ({
  currentStepUUID: state.jaybox.stepData.currentStepUUID,
  selectedElement: state.configuration.selectedElement,
  steps: state.jaybox.stepData.steps,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = (
  dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>,
) => ({
  setFormInputElement: (
    element: StepElementFormStateEntity,
    inputField:
      | StepElementFormInputEmailStateEntity
      | StepElementFormInputCheckboxStateEntity
      | StepElementFormInputTextStateEntity
      | StepElementFormTextAreaStateEntity
      | StepElementFormInputNumberStateEntity,
    stepUUID: string,
  ) => {
    dispatch(namespacedAction('@@jaybox')(setFormInputElement(element, inputField, stepUUID)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FormInputForm);
