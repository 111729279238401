/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-02-27
 * @author Tim Lange <tl@systl.de>
 */

// Third Party
import * as firebase from 'firebase/app';
import moment from 'moment';
import { v1 as uuidv1 } from 'uuid';
import { namespacedAction } from 'redux-subspace';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// models
import { Jaybox } from 'models/boxes';
import { BoxDocument } from 'models/firebase/boxes';
import { initialState as configuratorInitialState } from '../configuration/configurationSlice';
import { RequestStatus } from 'jaybox/dist/models/common';
import {
  StepStateEntity,
  StepElementTitleStateEntity,
  StepElementFormStateEntity,
  StepElementFormInputCheckboxStateEntity,
} from 'jaybox/dist/models/stepdata';
import { FormType, HTMLType } from 'jaybox/dist/models/HTMLElements';

// Action creator
import { saveConfiguration } from 'store/config/configSlice';
import { setMaxSteps, deleteElement, insertStepData } from 'jaybox/dist/store/stepdata/actions';
import {
  setLocalization,
  setStyle,
  setLocalizationFile,
} from '../configuration/configurationSlice';

// Utils
import { createAppThunk } from 'utils/appActions';

const sliceName = '@@boxes';

export interface BoxState {
  availableJayboxes: Jaybox[];
  currentJaybox: Jaybox | null;
  updateBoxesUnsubscribe: () => void;
  checkJobStateIntervalId: null | NodeJS.Timeout;
  boxesInitiallyLoaded: boolean;
  updateJayboxState: RequestStatus;
}

export interface SetAvailableJayboxesPayload {
  jayboxes: Jaybox[];
}

export interface SetCurrentJayboxPayload {
  jaybox: Jaybox | null;
}

export interface SetUpdateBoxesUnsubscribePayload {
  unsubscribe: () => void;
}

export interface SaveCheckJobStateIntervalPayload {
  intervalId: null | NodeJS.Timeout;
}

export interface SetBoxesInitiallyLoadedPayload {
  boxesInitiallyLoaded: boolean;
}

export const initialState: BoxState = {
  availableJayboxes: [],
  currentJaybox: null,
  updateBoxesUnsubscribe: () => {},
  checkJobStateIntervalId: null,
  boxesInitiallyLoaded: false,
  updateJayboxState: RequestStatus.IDLE,
};

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const loadBoxes = createAppThunk<void, { userId?: string }>(
  sliceName + '/loadBoxes',
  async ({ userId = '' }, { getState, rejectWithValue, dispatch }) => {
    try {
      const currentUser = firebase.auth().currentUser;

      if (currentUser || userId !== '') {
        const boxesUnsubscribe = await firebase
          .firestore()
          .doc(`/versions/v1/users/${userId !== '' ? userId : currentUser ? currentUser.uid : ''}`)
          .collection('boxes')
          .onSnapshot(
            (snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) => {
              const boxes: Jaybox[] = [];
              snapshot.forEach((doc) => {
                const boxDocument = doc.data() as BoxDocument;

                if (boxDocument.activeUntil) {
                  return;
                }
                boxes.push({
                  id: boxDocument.id,
                  name: boxDocument.name,
                  rootPath: boxDocument.rootPath,
                  unbuildChanges: boxDocument.unbuildChanges,
                  buildOnce: boxDocument.buildOnce,
                  lastSaved: moment(boxDocument.lastSaved.toDate()),
                  activeUntil: null,
                  metaData: {
                    customerEmail: boxDocument.meta.customerEmail,
                    customerId: boxDocument.meta.customerId,
                    customerTemplate: boxDocument.meta.customerTemplate,
                    layout: boxDocument.meta.layout,
                  },
                  buildProgress: {
                    done: boxDocument.buildProgress.done,
                    progress: boxDocument.buildProgress.progress,
                    jobId: boxDocument.buildProgress.jobId,
                    running: boxDocument.buildProgress.running,
                  },
                  license: {
                    active: boxDocument.license.active,
                    assignedAt: moment(boxDocument.license.assignedAt.toDate()),
                    createdAt: moment(boxDocument.license.createdAt.toDate()),
                    subscriptionId: boxDocument.license.subscriptionId,
                    updatedAt: moment(boxDocument.license.updatedAt.toDate()),
                  },
                });
              });
              dispatch(setAvailableJayboxes({ jayboxes: boxes }));
              dispatch(setBoxesInitiallyLoaded({ boxesInitiallyLoaded: true }));
              const currentJaybox = getState().boxes.currentJaybox;
              if (currentJaybox) {
                const updatedCurrent = boxes.find((el) => el.id === currentJaybox.id);
                if (updatedCurrent) {
                  dispatch(setCurrentJaybox({ jaybox: updatedCurrent }));
                }
              }
            },
          );
        dispatch(setUpdateBoxesUnsubscribe({ unsubscribe: boxesUnsubscribe }));
      }
    } catch (error) {
      return rejectWithValue({ errorMessage: error });
    }
  },
);

export const deleteBox = createAppThunk<void, { box: Jaybox }>(
  sliceName + '/deleteBox',
  async ({ box }, { getState, rejectWithValue, dispatch }) => {
    try {
      let userId = '';
      const workspaceId = getState().permission.currentWorkspaceId;
      if (workspaceId) {
        userId = workspaceId;
      } else {
        const currentUser = firebase.auth().currentUser;
        if (currentUser) {
          userId = currentUser.uid;
        }
      }

      if (userId) {
        await firebase
          .firestore()
          .doc(`/versions/v1/users/${userId}`)
          .collection('boxes')
          .doc(box.id)
          .delete();
      }
    } catch (error) {
      return rejectWithValue({ errorMessage: error });
    } finally {
      setTimeout(() => {}, 2000);
    }
  },
);

export const addDefaultStep = createAppThunk<void, { atNumber: number }>(
  sliceName + '/addDefaultStep',
  async ({ atNumber }, { getState, rejectWithValue, dispatch }) => {
    try {
      // DSGVO Step
      const initialStateEntity: StepStateEntity = {
        type: 'step',
        stepType: 'default',
        number: atNumber,
        lbIf: null,
        elements: [],
        elementPreviewUrl: null,
        uuid: uuidv1(),
      };
      // Title
      const initialTitle: StepElementTitleStateEntity = {
        type: HTMLType.TITLE,
        lbIf: null,
        localization: uuidv1(),
        uuid: uuidv1(),
      };
      await dispatch(
        setLocalization({
          key: initialTitle.localization,
          localization: 'Frage',
          namespace: 'de',
        }),
      );
      initialStateEntity.elements.push(initialTitle);

      dispatch(namespacedAction('@@jaybox')(insertStepData(initialStateEntity)));
    } catch (err) {
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const addDSGVOStep = createAppThunk<void, { atNumber: number }>(
  sliceName + '/addDSGVOStep',
  async ({ atNumber }, { getState, rejectWithValue, dispatch }) => {
    try {
      // DSGVO Step
      const initialStateEntity: StepStateEntity = {
        type: 'step',
        stepType: 'send',
        number: atNumber,
        lbIf: null,
        elements: [],
        elementPreviewUrl: null,
        uuid: uuidv1(),
      };
      // Title
      const initialTitle: StepElementTitleStateEntity = {
        type: HTMLType.TITLE,
        lbIf: null,
        localization: uuidv1(),
        uuid: uuidv1(),
      };
      await dispatch(
        setLocalization({
          key: initialTitle.localization,
          localization: 'Fast geschafft',
          namespace: 'de',
        }),
      );
      initialStateEntity.elements.push(initialTitle);

      // DSGVO Checkbox
      const dsgvoCheckboxLocalization = uuidv1();
      const dsgvoCheckbox: StepElementFormInputCheckboxStateEntity = {
        type: HTMLType.CHECKBOX,
        errorLocalization: uuidv1(),
        inputValid: true,
        order: 1,
        reportLocalization: dsgvoCheckboxLocalization,
        required: true,
        value: false,
        localization: dsgvoCheckboxLocalization,
        uuid: uuidv1(),
      };

      // Terms Checkbox
      const termsCheckboxLocalization = uuidv1();
      const termsCheckbox: StepElementFormInputCheckboxStateEntity = {
        type: HTMLType.CHECKBOX,
        errorLocalization: uuidv1(),
        inputValid: true,
        order: 2,
        reportLocalization: termsCheckboxLocalization,
        required: true,
        value: false,
        localization: termsCheckboxLocalization,
        uuid: uuidv1(),
      };
      const dsgvoForm: StepElementFormStateEntity = {
        type: HTMLType.FORM,
        formType: FormType.SUBMIT,
        inputElement: [dsgvoCheckbox, termsCheckbox],
        lbIf: null,
        localization: uuidv1(),
        uuid: uuidv1(),
      };
      await dispatch(
        setLocalization({
          key: termsCheckbox.errorLocalization,
          localization: 'Bitte bestätigen Sie die Nutzungsbedingungen',
          namespace: 'de',
        }),
      );
      await dispatch(
        setLocalization({
          key: dsgvoCheckbox.errorLocalization,
          localization: 'Bitte bestätigen Sie die Datenschutzbedingungen',
          namespace: 'de',
        }),
      );
      await dispatch(
        setLocalization({
          key: termsCheckbox.localization,
          localization: 'Ich akzeptiere die Nutzungsbedingungen',
          namespace: 'de',
        }),
      );
      await dispatch(
        setLocalization({
          key: dsgvoCheckbox.localization,
          localization: 'Ich willige ein die Daten an {{name}} zu senden',
          namespace: 'de',
        }),
      );
      initialStateEntity.elements.push(dsgvoForm);

      dispatch(namespacedAction('@@jaybox')(insertStepData(initialStateEntity)));
    } catch (err) {
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const addLastStep = createAppThunk<void, { atNumber: number }>(
  sliceName + '/addLastStep',
  async ({ atNumber }, { getState, rejectWithValue, dispatch }) => {
    try {
      const lastStepStateEntity: StepStateEntity = {
        type: 'step',
        number: atNumber,
        stepType: 'last',
        lbIf: null,
        elements: [],
        elementPreviewUrl: null,
        uuid: uuidv1(),
      };
      const lastTitle: StepElementTitleStateEntity = {
        type: HTMLType.TITLE,
        lbIf: null,
        localization: uuidv1(),
        uuid: uuidv1(),
      };
      await dispatch(
        setLocalization({
          key: lastTitle.localization,
          localization: 'Danke für Ihre Anfrage',
          namespace: 'de',
        }),
      );
      lastStepStateEntity.elements.push(lastTitle);
      dispatch(namespacedAction('@@jaybox')(insertStepData(lastStepStateEntity)));
      await timeout(300);
    } catch (err) {
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const createNewJaybox = createAppThunk<void, { box: Jaybox }>(
  sliceName + '/createNewJaybox',
  async ({ box }, { getState, rejectWithValue, dispatch }) => {
    try {
      const newBox: Jaybox = {
        ...box,
        unbuildChanges: true,
        lastSaved: moment(),
      };

      await dispatch(updateJaybox({ box: newBox }));
      await dispatch(setStyle({ theme: configuratorInitialState.style }));
      await dispatch(setLocalizationFile({ localization: {} }));

      // delete old state in jaybox
      const steps = getState().jaybox.stepData.steps;
      if (steps.length !== 0) {
        steps.forEach((step) => {
          dispatch(namespacedAction('@@jaybox')(deleteElement(step.uuid)));
        });
        dispatch(namespacedAction('@@jaybox')(setMaxSteps(0)));
      }

      await dispatch(addDefaultStep({ atNumber: 0 }));
      await dispatch(addDSGVOStep({ atNumber: 1 }));
      await dispatch(addLastStep({ atNumber: 2 }));

      dispatch(namespacedAction('@@jaybox')(setMaxSteps(3)));
      await dispatch(
        setLocalization({
          key: 'navigation.next-button',
          localization: 'Weiter',
          namespace: 'de',
        }),
      );
      await dispatch(
        setLocalization({
          key: 'navigation.submit-button',
          localization: 'Absenden',
          namespace: 'de',
        }),
      );

      await dispatch(saveConfiguration({ boxId: box.id }));
    } catch (err) {
      console.log(err);
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const updateJaybox = createAppThunk<void, { box: Jaybox }>(
  sliceName + '/updateJaybox',
  async ({ box }, { getState, rejectWithValue, dispatch }) => {
    try {
      const boxData: BoxDocument = {
        id: box.id,
        name: box.name,
        rootPath: box.rootPath,
        unbuildChanges: box.unbuildChanges,
        buildOnce: box.buildOnce,
        lastSaved: firebase.firestore.Timestamp.fromDate(box.lastSaved.toDate()),
        activeUntil: box.activeUntil
          ? firebase.firestore.Timestamp.fromDate(box.activeUntil.toDate())
          : null,
        meta: {
          customerEmail: box.metaData.customerEmail,
          customerId: box.metaData.customerId,
          customerTemplate: box.metaData.customerTemplate,
          layout: box.metaData.layout,
        },
        buildProgress: {
          done: box.buildProgress.done,
          progress: box.buildProgress.progress,
          jobId: box.buildProgress.jobId,
          running: box.buildProgress.running,
        },
        license: {
          active: box.license.active,
          assignedAt: firebase.firestore.Timestamp.fromDate(box.license.assignedAt.toDate()),
          createdAt: firebase.firestore.Timestamp.fromDate(box.license.createdAt.toDate()),
          subscriptionId: box.license.subscriptionId,
          updatedAt: firebase.firestore.Timestamp.fromDate(box.license.updatedAt.toDate()),
        },
      };
      let userId = '';
      const workspaceId = getState().permission.currentWorkspaceId;
      if (workspaceId) {
        userId = workspaceId;
      } else {
        const currentUser = firebase.auth().currentUser;
        if (currentUser) {
          userId = currentUser.uid;
        }
      }

      if (userId) {
        await firebase
          .firestore()
          .collection('/versions/v1/users')
          .doc(userId)
          .collection('boxes')
          .doc(box.id)
          .set(boxData);
      }
    } catch (err) {
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const updateJayboxes = createAppThunk<void, { boxes: Jaybox[] }>(
  sliceName + '/updateJayboxes',
  async ({ boxes }, { getState, rejectWithValue, dispatch }) => {
    try {
      await Promise.all(
        boxes.map(async (box) => {
          const boxData: BoxDocument = {
            id: box.id,
            name: box.name,
            rootPath: box.rootPath,
            unbuildChanges: box.unbuildChanges,
            buildOnce: box.buildOnce,
            lastSaved: firebase.firestore.Timestamp.fromDate(box.lastSaved.toDate()),
            activeUntil: box.activeUntil
              ? firebase.firestore.Timestamp.fromDate(box.activeUntil.toDate())
              : null,
            meta: {
              customerEmail: box.metaData.customerEmail,
              customerId: box.metaData.customerId,
              customerTemplate: box.metaData.customerTemplate,
              layout: box.metaData.layout,
            },
            buildProgress: {
              done: box.buildProgress.done,
              progress: box.buildProgress.progress,
              jobId: box.buildProgress.jobId,
              running: box.buildProgress.running,
            },
            license: {
              active: box.license.active,
              assignedAt: firebase.firestore.Timestamp.fromDate(box.license.assignedAt.toDate()),
              createdAt: firebase.firestore.Timestamp.fromDate(box.license.createdAt.toDate()),
              subscriptionId: box.license.subscriptionId,
              updatedAt: firebase.firestore.Timestamp.fromDate(box.license.updatedAt.toDate()),
            },
          };

          let userId = '';
          const workspaceId = getState().permission.currentWorkspaceId;
          if (workspaceId) {
            userId = workspaceId;
          } else {
            const currentUser = firebase.auth().currentUser;
            if (currentUser) {
              userId = currentUser.uid;
            }
          }

          if (userId) {
            await firebase
              .firestore()
              .collection('/versions/v1/users')
              .doc(userId)
              .collection('boxes')
              .doc(box.id)
              .set(boxData);
          }
        }),
      );
    } catch (err) {
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const checkForRequiredSteps = createAppThunk(
  sliceName + '/checkForRequiredSteps',
  async (_, { dispatch, getState }) => {
    try {
      const steps = getState().jaybox.stepData.steps;
      const lastStep = steps.find((step) => step.stepType === 'last');
      const sendStep = steps.find((step) => step.stepType === 'send');

      let stepCount = steps.length;
      if (!sendStep) {
        dispatch(addDSGVOStep({ atNumber: stepCount }));
        stepCount += 1;
      }
      if (!lastStep) {
        dispatch(addLastStep({ atNumber: stepCount }));

        stepCount += 1;
      }

      dispatch(namespacedAction('@@jaybox')(setMaxSteps(stepCount)));
    } catch (err) {}
  },
);

export const cleanUp = createAppThunk(sliceName + '/cleanUp', async (_, { dispatch, getState }) => {
  try {
    getState().boxes.updateBoxesUnsubscribe();
    await dispatch(init());
  } catch (err) {}
});

const boxSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(updateJaybox.pending, (state, _) => {
      state.updateJayboxState = RequestStatus.LOADING;
    });
    builder.addCase(updateJaybox.fulfilled, (state, _) => {
      state.updateJayboxState = RequestStatus.IDLE;
    });
    builder.addCase(updateJaybox.rejected, (state, action) => {
      state.updateJayboxState = RequestStatus.ERROR;
    });
  },
  initialState,
  name: sliceName,
  reducers: {
    init: () => initialState,
    setAvailableJayboxes(state, action: PayloadAction<SetAvailableJayboxesPayload>) {
      state.availableJayboxes = action.payload.jayboxes;
    },
    setCurrentJaybox(state, action: PayloadAction<SetCurrentJayboxPayload>) {
      state.currentJaybox = action.payload.jaybox;
    },
    setUpdateBoxesUnsubscribe(state, action: PayloadAction<SetUpdateBoxesUnsubscribePayload>) {
      state.updateBoxesUnsubscribe = action.payload.unsubscribe;
    },
    saveCheckJobStateInterval(state, action: PayloadAction<SaveCheckJobStateIntervalPayload>) {
      state.checkJobStateIntervalId = action.payload.intervalId;
    },
    setBoxesInitiallyLoaded(state, action: PayloadAction<SetBoxesInitiallyLoadedPayload>) {
      state.boxesInitiallyLoaded = action.payload.boxesInitiallyLoaded;
    },
  },
});

export const {
  init,
  saveCheckJobStateInterval,
  setAvailableJayboxes,
  setBoxesInitiallyLoaded,
  setCurrentJaybox,
  setUpdateBoxesUnsubscribe,
} = boxSlice.actions;

export default boxSlice.reducer;
