/**
 * Application store with all store creations, data mapping, redux middelware, etc.
 *
 * @copyright 2020 Emden Consulting GmbH
 * @created 2019-12-13
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import {
  Action,
  configureStore,
  getDefaultMiddleware,
  Middleware,
  Dispatch,
} from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { ThunkAction } from 'redux-thunk';
import { useDispatch } from 'react-redux';

// Data model

// Config
import { NODE_ENV } from 'config/env';

// Reducer and history
import createRootReducer from './rootReducer';

export const history = NODE_ENV !== 'test' ? createBrowserHistory() : createMemoryHistory();

const rootReducer = createRootReducer(history);

export const store = configureStore({
  middleware: [
    ...getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
    routerMiddleware(history) as Middleware<any, RootState, Dispatch<any>>,
  ],
  reducer: rootReducer,
});

if (NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const nextReducer = require('./rootReducer').default;
    store.replaceReducer(nextReducer(history));
  });
}

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
