/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-01-20
 * @author Tim Lange <tl@systl.de>
 */

// Third party
import builder from 'xmlbuilder';

// Jaybox
import { StepStateEntity, MetaStateEntity } from 'jaybox/dist/models/stepdata';
import {
  StepAttributes,
  StepTitleAttributes,
  SelectAttributes,
  OptionAttributes,
  FormRadiogroupEntityAttributes,
  FormRadiogroupInputEntityAttributes,
  SliderAttributes,
  FormAttributes,
  FormInputEntityAttributes,
  LabelAttributes,
} from 'jaybox/dist/models/XMLConfig';
import { HTMLType } from 'jaybox/dist/models/HTMLElements';

export const convertStateToXML = (steps: StepStateEntity[], meta: MetaStateEntity): string => {
  const root = builder.create('stepper').dec('1.0', 'UTF-8', true);

  root.element('meta', { ...meta });

  steps.forEach((step) => {
    const stepAttributes: StepAttributes = {
      stepType: step.stepType,
      number: step.number.toString(),
      uuid: step.uuid,
      previewUrl: step.elementPreviewUrl ? step.elementPreviewUrl : undefined,
      lbIf: step.lbIf ? step.lbIf : undefined,
    };
    const stepEntity = root.element('step', stepAttributes);
    const stepElementsEntity = stepEntity.element('stepElements');
    const title = step.elements.find((element) => {
      if (element.type === HTMLType.TITLE) {
        return true;
      } else {
        return false;
      }
    });

    step.elements.forEach((element) => {
      switch (element.type) {
        case HTMLType.TITLE: {
          const elementAttributes: StepTitleAttributes = {
            localization: element.localization,
            lbIf: element.lbIf ? element.lbIf : undefined,
          };
          stepEntity.element(element.type, elementAttributes);
          break;
        }
        case HTMLType.SELECT: {
          const elementAttributes: SelectAttributes = {
            autoForward: element.autoForward ? String(element.autoForward) : undefined,
            lbIf: element.lbIf ? element.lbIf : undefined,
            uuid: element.uuid,
            reportLocalization: title ? title.localization : '',
            required: element.required ? String(element.required) : undefined,
            singleSelection: element.singleSelection ? String(element.singleSelection) : undefined,
          };
          const selectEntity = stepElementsEntity.element(element.type, elementAttributes);

          element.options.forEach((option) => {
            const optionAttributes: OptionAttributes = {
              uuid: option.uuid,
              localization: option.localization,
              order: option.order ? option.order.toString() : undefined,
            };
            selectEntity.element(option.type, optionAttributes);
          });
          break;
        }
        case HTMLType.RADIOGROUP: {
          const elementAttributes: FormRadiogroupEntityAttributes = {
            autoForward: element.autoForward ? String(element.autoForward) : undefined,
            lbIf: element.lbIf ? element.lbIf : undefined,
            uuid: element.uuid,
            reportLocalization: title ? title.localization : '',
            required: element.required ? String(element.required) : undefined,
            singleSelection: element.singleSelection ? String(element.singleSelection) : undefined,
          };
          const radioEntity = stepElementsEntity.element(element.type, elementAttributes);

          element.radio.forEach((radio) => {
            const radioOptions: FormRadiogroupInputEntityAttributes = {
              uuid: radio.uuid,
              localization: radio.localization,
              order: radio.order ? radio.order.toString() : undefined,
            };
            radioEntity.element(radio.type, radioOptions);
          });
          break;
        }
        case HTMLType.SLIDER: {
          const elementAttributes: SliderAttributes = {
            lbIf: element.lbIf ? element.lbIf : undefined,
            defaultValue: element.defaultValue.toString(),
            unit: element.unitLocalization,
            upperRange: element.upperRange.toString(),
            lowerRange: element.lowerRange.toString(),
            step: element.step.toString(),
            uuid: element.uuid,
            localization: element.localization,
            reportLocalization: title ? title.localization : '',
            order: element.order ? element.order.toString() : undefined,
          };
          stepElementsEntity.element(element.type, elementAttributes);
          break;
        }
        case HTMLType.LABEL: {
          const elementAttributes: LabelAttributes = {
            bodyLocalization: element.bodyLocalization,
            uuid: element.uuid,
            titleLocalization: element.titleLocalization,
            order: element.order ? element.order.toString() : undefined,
            disableOverlay: String(element.disableOverlay),
            buttonLocalization: element.buttonLocalization,
          };
          stepElementsEntity.element(element.type, elementAttributes);
          break;
        }
        case HTMLType.FORM: {
          const elementAttributes: FormAttributes = {
            lbIf: element.lbIf ? element.lbIf : undefined,
            uuid: element.uuid,
            localization: title ? title.localization : element.localization,
            formtype: element.formType,
          };
          const formEntity = stepElementsEntity.element(element.type, elementAttributes);

          element.inputElement.forEach((input) => {
            const inputAttributes: FormInputEntityAttributes = {
              uuid: input.uuid,
              localization: input.localization,
              type: input.type,
              errorLocalization: input.errorLocalization,
              reportLocalization: input.localization,
              required: input.required ? String(input.required) : undefined,
              order: input.order ? input.order.toString() : undefined,
            };
            formEntity.element(input.type, inputAttributes);
          });
          break;
        }
        default:
          break;
      }
    });
  });

  const xmlString = root.end({
    pretty: false, // keep the string as small as possible
    indent: '', // no intend
    newline: '', // \n for new line
    width: 0,
    allowEmpty: true,
  });
  return xmlString;
};
