/**
 * Configuration for all environment variables.
 *
 * @copyright 2020 Emden Consulting GmbH
 * @created 2019-12-11
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies

//Common
export const NODE_ENV = process.env.NODE_ENV || 'production';
export const DEBUG = NODE_ENV !== 'production';
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const APP_VERSION = process.env.REACT_APP_VERSION || '';

// Build Server
export const BUILD_SERVER_URL = process.env.REACT_APP_BUILD_SERVER_URL;

// Firebase
export const FB_API_KEY = process.env.REACT_APP_FB_API_KEY;
export const FB_AUTH_DOMAIN = process.env.REACT_APP_FB_AUTH_DOMAIN;
export const FB_DATABASE_URL = process.env.REACT_APP_FB_DATABASE_URL;
export const FB_PROJECT_ID = process.env.REACT_APP_FB_PROJECT_ID;
export const FB_STORAGE_BUCKET = process.env.REACT_APP_FB_STORAGE_BUCKET;
export const FB_MESSAGING_SENDER_ID = process.env.REACT_APP_FB_MESSAGING_SENDER_ID;
export const FB_APP_ID = process.env.REACT_APP_FB_APP_ID;

// Sendgrid
export const SENDGRID_EMAIL_FROM = process.env.REACT_APP_SG_EMAIL_FROM;
export const SENDGRID_WELCOME_MAIL_ID = process.env.REACT_APP_SG_WELCOME_TEMPLATE_ID;

// Pipeline
export const PIPELINE_ENDPOINT =
  process.env.PIPELINE_ENDPOINT ||
  'https://api.bitbucket.org/2.0/repositories/emdenconsultinggmbh/jaybox/pipelines/';
export const PIPELINE_AUTHORIZATION =
  process.env.PIPELINE_AUTHORIZATION || 'Basic dGltbGFuZ2VFQzpheEVDZzVTVUdRNDc2Z3RMbUpzNg==';
export const STORAGE_BASE_URL =
  process.env.REACT_APP_STORAGE_BASE_URL ||
  'https://firebasestorage.googleapis.com/v0/b/jaybox-portal-dev.appspot.com/o/';
