/**
 * @copyright 2020 Your Company
 * @created 2020-09-30
 * @author Tim Lange <you@you.you>
 */

import { Permission } from 'models/permission';

export const getTargetProfileId = (permissions: Permission[]): string | null => {
  if (permissions.length > 0) {
    return permissions[0].targetId;
  } else {
    return null;
  }
};
