/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-02-13
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { namespacedAction } from 'redux-subspace';

// Presentational component
import SliderForm from './SliderForm';

// Action creator
import { setSliderElement } from 'jaybox/dist/store/stepdata/actions';

// Data models
import { StateProps, DispatchProps } from './propTypes';
import { ApplicationState, ApplicationAction } from 'models/store';
import { StepElementSliderStateEntity } from 'jaybox/dist/models/stepdata';

const mapStateToProps: MapStateToProps<StateProps, {}, ApplicationState> = (state) => ({
  currentStepUUID: state.jaybox.stepData.currentStepUUID,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = (
  dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>,
) => ({
  setSliderElement: (element: StepElementSliderStateEntity, stepUUID: string) => {
    dispatch(namespacedAction('@@jaybox')(setSliderElement(element, stepUUID)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SliderForm);
