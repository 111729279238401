/**
 * @copyright 2021 Emden Consulting GmbH
 * @created 2021-04-19
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import BackendLoader, { BackendOptions } from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

// Models
import { Language } from 'models/common';

//import { default as configurator_de } from 'assets/locales/de/translation.json';

// Options for lazy loading from server
const backendOptions: BackendOptions = {
  loadPath: '/locales/{{lng}}/{{ns}}.json',
};

let newInstance = i18n.createInstance({
  appendNamespaceToMissingKey: true,
  parseMissingKeyHandler: (key) => {
    // check if this is a user defined missing or an missing pre configured key
    // the "user" namespace is appended to the missing key
    // return empty for better ui
    if (key.includes('user')) {
      return '';
    }
    return key.replace('configurator:', '');
  },
  detection: {
    order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    lookupQuerystring: 'lng',
  },
  ns: ['user', 'configurator'],
  fallbackNS: 'configurator',
  defaultNS: 'user',
  backend: backendOptions,
  fallbackLng: Language.DE,
  interpolation: {
    escapeValue: false,
  },
  debug: true,
  load: 'languageOnly',
  react: {
    bindI18nStore: 'added',
    useSuspense: false,
    bindI18n: 'languageChanged',
    wait: false,
  },
});

newInstance
  .use(BackendLoader)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init();

/**
 * Updates the i18n ressource so this can be rendered inside the editor
 */
export const updateRessource = (key: string, value: string) => {
  newInstance.addResource('de', 'user', key, value, { silent: false });
  newInstance.addResource('en', 'user', key, value, { silent: false });
};

export const overwriteRessource = (ressource: any) => {
  if (ressource.de) {
    newInstance.addResources('de', 'user', ressource.de);
  }
  newInstance.addResources('en', 'user', ressource.de);
};

export default newInstance;
