/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-03-12
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import * as firebase from 'firebase/app';
import 'firebase/auth';

// Data models
import { RequestStatus } from 'models/common';
import { LoginData, LoginError } from 'models/login';
import {
  LoginActionType,
  LoginDataAction,
  LoginStatusAction,
  LoginDataResetAction,
} from 'models/login/actions';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { ApplicationAction } from 'models/store';
import { getSessionToken } from 'utils/requestHandler';

export const updateLoginData = (data: LoginData): LoginDataAction => ({
  data,
  type: LoginActionType.DATA,
});

export const resetLoginData = (): LoginDataResetAction => ({
  type: LoginActionType.DATA_RESET,
});

export const updateLoginStatus = (
  status: RequestStatus,
  error: LoginError = LoginError.NONE,
): LoginStatusAction => ({
  error,
  status,
  type: LoginActionType.LOGIN_STATUS,
});

export const login = (data: LoginData): ThunkAction<Promise<void>, {}, {}, ApplicationAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, ApplicationAction>) => {
    dispatch(updateLoginStatus(RequestStatus.LOADING, LoginError.NONE));
    try {
      if (data.jwtToken) {
        const sessionToken = await getSessionToken(data.jwtToken);
        if (sessionToken) {
          firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
          await firebase.auth().signInWithCustomToken(sessionToken);
          dispatch(updateLoginStatus(RequestStatus.SUCCESS));
        } else {
          dispatch(updateLoginStatus(RequestStatus.ERROR, LoginError.INVALID_TOKEN));
        }
      } else {
        dispatch(updateLoginStatus(RequestStatus.ERROR, LoginError.INVALID_TOKEN));
      }
    } catch (error) {
      dispatch(updateLoginStatus(RequestStatus.ERROR, error.code));
    } finally {
      setTimeout(() => {
        dispatch(resetLoginData());
        dispatch(updateLoginStatus(RequestStatus.IDLE));
      }, 3000);
    }
  };
};
