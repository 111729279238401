/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-04-16
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { FC } from 'react';

import { useTranslation } from 'react-i18next';

// Presentational

// Own Components

// Data Models

// Assets
import Meadow from 'assets/img/jaybox_empty.svg';

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
  },
});

const NoBoxes: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container justify="center" alignItems="flex-start" className={classes.root}>
      <Grid item xs={12} style={{ height: '70%' }}>
        <img src={Meadow} alt="no jaybox" style={{ height: '100%', width: '100%' }} />
      </Grid>
      <Grid
        item
        container
        xs={12}
        justify="center"
        style={{ maxWidth: '40rem', paddingTop: '5rem' }}
      >
        <Typography color="textPrimary" variant="h2" align="center">
          {t('jayboxDetail.noJaybox')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NoBoxes;
