/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-02-26
 * @author Tim Lange <tl@systl.de>
 */

// models
import {
  SetCurrentNavigationAction,
  NavigationActionType,
  SetMobileModeAction,
} from 'models/navigation/actions';

// jaybox
import { CurrentNavigation } from 'models/navigation';

export const setCurrentNavigation = (
  navigation: CurrentNavigation,
): SetCurrentNavigationAction => ({
  navigation: navigation,
  type: NavigationActionType.SET_CURRENT_NAVIGATION,
});

export const setMobileMode = (mobileMode: boolean): SetMobileModeAction => ({
  mobileMode,
  type: NavigationActionType.SET_MOBILE_MODE_ACTION,
});
