/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-02-10
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { XYCoord } from 'dnd-core';

// Own Components
import Button from 'jaybox/dist/components/elements/button/Button';
import Radio from 'jaybox/dist/components/elements/radio/Radio';
import InputField from 'jaybox/dist/components/elements/inputField/InputField';
import Slider from 'jaybox/dist/components/elements/slider/Slider';
import Checkbox from 'jaybox/dist/components/elements/checkbox/Checkbox';
import Label from 'jaybox/dist/components/elements/label/Label';

// Models
import { Props } from './propTypes';
import { HTMLType } from 'jaybox/dist/models/HTMLElements';
import ClickArea from 'components/hoc/ClickAreaContainer';

export enum ItemTypes {
  CARD = 'card',
}

// Styles
const useStyles = makeStyles<Theme, Props>({
  container: {
    height: '100%',
    padding: '1rem 2rem',
  },
  itemSpacing: {
    paddingBottom: '8px',
    paddingRight: (props) => (props.meta.layout === 'horizontal' ? '8px' : 0),
  },
});

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const Card: React.FC<Props> = (props) => {
  const { id, index, moveCard, element, stepUUID, meta } = props;
  const classes = useStyles(props);

  const ref = React.useRef<HTMLDivElement>(null);
  useDrop({
    accept: ItemTypes.CARD,
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  useDrag({
    item: { type: ItemTypes.CARD, id, index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const getComponent = () => {
    switch (element.primary.type) {
      case HTMLType.OPTION:
        if (element.secondary && element.secondary.type === HTMLType.SELECT) {
          return (
            <Grid
              item
              md={meta.layout === 'vertical' ? 12 : 3}
              xs={meta.layout === 'vertical' ? 12 : 6}
              ref={ref}
              className={classes.itemSpacing}
            >
              <ClickArea ownElement={element}>
                <Button
                  setSelectElement={() => {}}
                  optionEntity={element.primary}
                  selectEntity={element.secondary}
                  stepUUID={stepUUID}
                  meta={meta}
                />
              </ClickArea>
            </Grid>
          );
        }
        return <div />;
      case HTMLType.RADIOINPUT:
        if (element.secondary && element.secondary.type === HTMLType.RADIOGROUP) {
          return (
            <Grid
              item
              md={meta.layout === 'vertical' ? 12 : 3}
              xs={meta.layout === 'vertical' ? 12 : 6}
              ref={ref}
              className={classes.itemSpacing}
            >
              <ClickArea ownElement={element}>
                <Radio
                  setRadioElement={() => {}}
                  radioEntity={element.primary}
                  radiogroupEntity={element.secondary}
                  stepUUID={stepUUID}
                  meta={meta}
                />
              </ClickArea>
            </Grid>
          );
        }
        return <div />;
      case HTMLType.TEXTAREA:
      case HTMLType.NUMBER:
      case HTMLType.EMAIL:
      case HTMLType.TEXT:
        if (element.secondary && element.secondary.type === HTMLType.FORM) {
          return (
            <Grid item xs={12} ref={ref} className={classes.itemSpacing}>
              <ClickArea ownElement={element}>
                <InputField
                  setFormInputElement={() => {}}
                  inputEntity={element.primary}
                  formEntity={element.secondary}
                  stepUUID={stepUUID}
                />
              </ClickArea>
            </Grid>
          );
        }
        return <div />;
      case HTMLType.CHECKBOX:
        if (element.secondary && element.secondary.type === HTMLType.FORM) {
          return (
            <Grid item xs={12} ref={ref} className={classes.itemSpacing}>
              <ClickArea ownElement={element}>
                <Checkbox
                  setFormInputElement={() => {}}
                  inputEntity={element.primary}
                  formEntity={element.secondary}
                  stepUUID={stepUUID}
                  meta={meta}
                />
              </ClickArea>
            </Grid>
          );
        }
        return <div />;
      case HTMLType.LABEL:
        if (element.primary && element.primary.type === HTMLType.LABEL) {
          return (
            <Grid item xs={12} ref={ref} className={classes.itemSpacing}>
              <ClickArea ownElement={element}>
                <Label meta={meta} labelEntity={element.primary} stepUUID={stepUUID} />
              </ClickArea>
            </Grid>
          );
        }
        return <div />;
      case HTMLType.SLIDER:
        return (
          <Grid
            item
            md={meta.layout === 'vertical' ? 12 : 6}
            xs={12}
            ref={ref}
            className={classes.itemSpacing}
          >
            <ClickArea ownElement={element}>
              <Slider
                setSliderElement={() => {}}
                sliderEntity={element.primary}
                stepUUID={stepUUID}
              />
            </ClickArea>
          </Grid>
        );
      default:
        return <div />;
    }
  };

  return getComponent();
};

export default Card;
