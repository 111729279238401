/**
 * AttributeBar container
 *
 * @copyright 2020 Emden Consulting GmbH
 * @created 2019-12-13
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { namespacedAction } from 'redux-subspace';

// Presentational component
import AttributeBar from './AttributeBar';

// Action creator
import { deleteElement, setMaxSteps } from 'jaybox/dist/store/stepdata/actions';

// Data models
import { StateProps } from './propTypes';
import { ApplicationState, ApplicationAction } from 'models/store';
import { DispatchProps } from './propTypes';

const mapStateToProps: MapStateToProps<StateProps, {}, ApplicationState> = (state) => ({
  selectedElement: state.configuration.selectedElement,
  currentStepUUID: state.jaybox.stepData.currentStepUUID,
  steps: state.jaybox.stepData.steps,
  maxSteps: state.jaybox.stepData.maxSteps,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = (
  dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>,
) => ({
  deleteElement: (uuid) => {
    dispatch(namespacedAction('@@jaybox')(deleteElement(uuid)));
  },
  setMaxSteps: (maxSteps: number) => {
    dispatch(namespacedAction('@@jaybox')(setMaxSteps(maxSteps)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AttributeBar);
